
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/services/common.service';
import { NewsService } from 'src/services/news.service';
import { NotificationService } from 'src/services/notification.service';
import { PageService } from 'src/services/page.service';
import { StorageService } from 'src/services/storage.service';
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  // bannertitle = "News"
  listNews: boolean = false;
  pageData: any;
  pagetitle: any;
  template: any;
  bannerDetailsTwo: any;
  pageDataTwo: any;
  checkSection: boolean = false;
  bannerDetails: any;
  biblequotes: any;
  newslist: any = [];
  newslistCopy: any = [];
  province: string;
  news = [];
  count = 0;
  data: any;
  totalPages: any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  @Output() newEvent = new EventEmitter;
  checkRes: boolean = false;
  constructor(private commonservice: CommonService, private notify: NotificationService, private pageservice: PageService, public newsService: NewsService, public storageService: StorageService, private router: Router) {

    this.pageservice.getPage('60db5d18c5c4955342c96281').subscribe(res => {
      this.pageData = res.result.templateData;
      this.pagetitle = res.result.title;
      // this.bannerDetails = [{bibleQuotes:this.pageData.bannerquote,verses:"EZE 33:11",file:"azar"}]
      this.bannerDetails = [{ bibleQuotes: this.pageData.bannerquote, verses: this.pageData.bannerversus, file: this.pageData.bannerimage ? this.pageData.bannerimage : '../../../assets/images/event-bg.jpg' }]

      this.checkSection = true;
    })
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res => {
      this.pageDataTwo = res.result.templateData;
      this.bannerDetailsTwo = { slidertitle1: this.pageDataTwo.riseupcanada, slidertitle2: this.pageDataTwo.prayforournation, sliders: this.pageDataTwo.banner }
      this.sectionDetails = { title: this.pageData.section2title, description: this.pageData.section2description, audio: this.pageData.section2audio, pdf: this.pageData.section2pdf }
      this.checkSection = true;
    })


  }

  loadMore() {
    this.count = this.count + 1;
    if (this.count < this.totalPages) {
      this.newsService.getPage(this.province, this.count).subscribe((data: any) => {
        data['result'].list.map(o => { this.newslist.push(o) })
        this.checkRes = true;
        this.listNews = this.count + 1 < this.totalPages ? true : false;
      });
    }
    else {
      this.listNews = false;
    }
  }

  getData(event: any) {
    // if (event.province! = null) {
    //   this.province = event.province;
    // }
    this.newsService.getFilteredData(event.province, event.city, event.pincode).subscribe((Res) => {
      this.data=Res['result'].list;
      this.newsService.getmsg(this.data);
      this.checkRes = true;
      this.listNews = this.count + 1 < this.totalPages ? true : false;
    });
  }
  getNews(data) {
    this.listNews = data;
  }

  newspage = [
    {
      title: "News",
      lefttitle: "Trending News",
      description: "",
      slug: "",
      image: "",
      htmlContent: "",
      template: "",
      template_field: [{
        "banner": [
          {
            "bibleQuotes": "So I sought for a man among them who would make a wall, and stand in the gap before Me on behalf of the land (Canada), that I should not destroy it; but I found no one.",
            "verses": "EZE 22:30",
            "file": "https://ruc-frontend-webapp-dev-knjyght5ka-uc.a.run.app/prayer-partner-bg.jpg",
          },

        ],

      }]
    }
  ]

  getNewses() {
    this.province = localStorage.getItem('selectedRegionName') == null ? null : localStorage.getItem('selectedRegionName').includes('Qu') ? "Quebec" : localStorage.getItem('selectedRegionName').replace(/"/g, "").replace(/ /g, "_");
    this.newsService.getNews(this.province).subscribe((data: any) => {
      this.newslist = data['result'].list;
      this.totalPages = data['result'].info.totalPages;
    }, (err) => {
      this.notify.showError(err)
    });
  }

  ngOnInit(): void {
    this.storageService.watchStorage().subscribe(value => {
      this.getNewses();
    });
  }


}
