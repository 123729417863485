<div class="row mar-zero" *ngFor="let bodyDetail of bodyDetails; index as i;">
    <div class="col s12 m7 l7 pad-l-zero">
        <div class="">
            <div class="static-bible-quote" id="biblecontent">{{bodyDetail.texthead}}</div>
            <div class="static-bible-quote-num" id="biblequote">{{bodyDetail.quotesnum}}</div>
        </div>
        <p class="txt-14 mar-t-zero clr-888" id="content1">
            {{bodyDetail.description}}
        </p>
    </div>
    <div class="col s12 m5 l5">
        <img class="responsive-img" id="aboutimage" src="{{bodyDetail.thumbnail}}">
    </div>
</div>