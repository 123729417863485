<div class="row mar-zero" *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
            <!-- <app-logo-layout></app-logo-layout> -->
        </div>
        <div class="static-topright">
            <app-innerbanner [bannerDetails]="bannerDetails" [title]="pagetitle"></app-innerbanner>
        </div>
    </div>
</div>
<div class="container-outer" *ngIf="checkSection">
    <!--Reach Us-->
    <div class="static-section-panel border-none">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">{{pageData.section1title}}</span>
                        <!-- <span class="static-section-ltitle">{{contactuspage[0]['title']}}</span> -->
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="static-section-rpanel-card">
            <div class="row mar-zero">
                <div class="col s12 m6 l6 pad-zero">
                    <div class="contact-loc-block">
                        <div class="contact-maploc center-align">
                            <!-- <img src="../../../assets/images/024.jpg" class="responsive-img" alt=""> -->
                            <iframe frameborder="0" scrolling="no" marginheight="0" width="100%" height="210px"
                                marginwidth="0"
                                src="https://maps.google.com/maps?q=1100%20bellamy%20road%20n&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near"
                                title="Rise Up Canada" aria-label="Rise Up Canada"></iframe>
                        </div>
                        <div class="row mar-zero">
                            <div class="col s12 m6 l6 pad-zero pos-relative">
                                <div class="cont-map-icon"><img src="../../../assets/images/cont-map-icon.png"></div>
                                <div class="cont-details-block">
                                    <p class="ruc-house-tle">{{pageData.addressName}}
                                    <p class="txt-14 clr-888">
                                        {{pageData.address}}
                                    </p>
                                    <div class="cont-social-block">
                                        <p class="cont-socialtle">Follow us on</p>
                                        <app-followus></app-followus>
                                    </div>
                                </div>
                            </div>
                            <div class="col s12 m6 l6 pad-zero">
                                <div class="row mar-zero">
                                    <div class="col s12 pad-zero pos-relative">
                                        <div class="cont-mail-icon"><img
                                                src="../../../assets/images/cont-mail-icon.png"></div>
                                        <div class="cont-details-block">
                                            <span class="cont-mailtle"><a class="cont-mailtle"
                                                    href="mailto:{{pageData.email}}">{{pageData.email}}</a></span>
                                        </div>
                                    </div>
                                    <div class="col s12 pad-zero">
                                        <div class="cont-phone-panel">
                                            <div class="cont-mail-icon"><img
                                                    src="../../../assets/images/cont-phone-icon.png"></div>
                                            <div class="cont-details-block">
                                                <span class="cont-phonetle">{{pageData.phone}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col s12 m6 l6 pad-zero">
                    <div class="contact-loc-block center-align">
                        <div class="contact-loc-block-img">
                            <img src="{{pageData.section1image}}" class="responsive-img" alt="">
                        </div>
                        <p class="static-more-action-tle">{{pageData.section1subtitle}}</p>
                        <p class="txt-14 mar-t-zero clr-888">{{pageData.section1description}}
                        </p>
                        <!--p><a class="waves-effect btn-lg">Submit</a></p-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Reach Us-->

    <!--Share Feedback-->
    <div class="feedback-section-panel">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">{{pageData.section2title}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="static-section-rpanel-card">
            <div class="row mar-zero">
                <div class="col s12 m6 l6 pad-zero">
                    <div class="feedback-fld-block ">
                        <form [formGroup]="contactForm">
                            <div class="row mar-zero">
                                <div class="col s12 pad-zero">
                                    <input type="text" class="contfeedback-fld" id="name" formControlName="name"
                                         placeholder="Name*"
                                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                    <div *ngIf="submitted && f.name.errors" class="alert alert-danger">
                                        <div class="validation-errors" *ngIf="f.name.errors.required">
                                            *Name is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col s12 pad-zero">
                                    <input type="text" class="contfeedback-fld" id="email" formControlName="email"
                                         placeholder="Email*"
                                         [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                                        <div class="validation-errors" *ngIf="f.email.errors.required">
                                            *Email is required
                                        </div>
                                        <div class="validation-errors" *ngIf="contactForm.get('email').errors.pattern">
                                            Email is not valid
                                          </div>
                                    </div>
                                </div>
                                <div class="col s12 pad-zero">
                                    <input type="text" class="contfeedback-fld" id="mobile" formControlName="mobile"
                                         placeholder="Mobile*"
                                         [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                                    <div *ngIf="submitted && f.mobile.errors" class="alert alert-danger">
                                        <div class="validation-errors" *ngIf="f.mobile.errors.required">
                                            *Mobile Number is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col s12 pad-zero">
                                    <textarea class="contfeedback-fld" id="comments" formControlName="comments" required
                                        placeholder="Comments"
                                        [ngClass]="{ 'is-invalid': submitted && f.comments.errors }"></textarea>
                                    <div *ngIf="submitted && f.comments.errors" class="alert alert-danger">
                                        <div class="validation-errors" *ngIf="f.comments.errors.required">
                                            *Comment is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col s12 pad-zero">
                                    <div class="spacer"></div>
                                    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                                    </ngx-recaptcha2>
                                </div>
                                <div class="col s12 pad-zero left-align cont-btn">
                                    <button class="waves-effect btn-sm" 
                                        (click)="savechanges()">{{pageData.formbutton}}</button>
                                    <!-- <a class="waves-effect btn-sm" (ngSubmit)="savechanges()">{{pageData.formbutton}}</a> -->
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                <div class="col s12 m6 l6 pad-zero">
                    <div class="contact-app-block center-align" style="background: url({{pageData.section2image}}) 30px 146px no-repeat;">
                        <p class="download-app-title">{{pageData.section2subtitle}}</p>
                        <div class="appstore-panel">
                            <a  class="mar-zero"><img src="{{pageData.appstore}}"></a><br>
                            <a  class="mar-zero"><img src="{{pageData.playstore}}"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Share Feedback-->
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>