import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/services/common.service';
import { LeaderService } from 'src/services/leader.service';
import { NotificationService } from 'src/services/notification.service';
import { PageService } from 'src/services/page.service';
import { StorageService } from 'src/services/storage.service';
@Component({
  selector: 'app-prayforleaders',
  templateUrl: './prayforleaders.component.html',
  styleUrls: ['./prayforleaders.component.css']
})
export class PrayforleadersComponent implements OnInit {
  bannertitle = "Pray for leaders";
  listLeader: boolean = false;
  pageData: any;
  pagetitle: any;
  template: any;
  checkSection: boolean = false;
  bannerDetails: any;
  biblequotes: any;
  leaderList: any = [];
  leader = [];
  count = 0;
  counter: number = 0;
  totalPages: any;
  storeProvince: any;
  bannerDetailsTwo: any;
  pageDataTwo:any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  @Output() newEvent = new EventEmitter;
  constructor(private commonservice: CommonService, private pageservice: PageService, public storageService: StorageService,
    public leaderService: LeaderService, private notify: NotificationService) {

    this.pageservice.getPage('60db7100e2787a013a4551f0').subscribe(res => {
      this.pageData = res.result.templateData;
      this.pagetitle = res.result.title;

      this.bannerDetails = [{ bibleQuotes: this.pageData.bannerquote, verses: this.pageData.bannerversus, file: this.pageData.bannerimage ? this.pageData.bannerimage : '../../../assets/images/about-bg.jpg' }]


      this.checkSection = true;
    }, (err) => {
      this.notify.showError(err)
    });
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res =>{
      this.pageDataTwo=res.result.templateData;
      this.bannerDetailsTwo = {slidertitle1:this.pageDataTwo.riseupcanada,slidertitle2:this.pageDataTwo.prayforournation,sliders:this.pageDataTwo.banner}
      this.sectionDetails = {title:this.pageData.section2title,description:this.pageData.section2description,audio:this.pageData.section2audio,pdf:this.pageData.section2pdf}
      this.checkSection=true;
    }, (err) => {
      this.notify.showError(err)
    });
  }
  getLeaders(data) {
    this.listLeader = data;
  }

  provinceleaders = [
    {
      title: "Pray for leaders",
      description: "",
      slug: "",
      image: "",
      htmlContent: "",
      template: "",
      template_field: [{
        "banner": [
          {
            "bibleQuotes": "So I sought for a man among them who would make a wall, and stand in the gap before Me on behalf of the land (Canada), that I should not destroy it; but I found no one.",
            "verses": "EZE 22:30",
            "file": "https://ruc-frontend-webapp-dev-knjyght5ka-uc.a.run.app/about-bg.jpg",
          },

        ],

      }]
    }
  ]

  getData(event: any) {
    this.storeProvince = event.city;
    this.leaderService.getAllLeader(this.storeProvince).subscribe((res) => {
      var x = [];
      x = res.result.list;
      this.leaderList = [];
      x.map(o => { this.leaderList.push(o) })
    })

  }

  loadMore() {
    this.count = this.count + 1;
    if (this.totalPages > this.count) {
      
      var y = localStorage.getItem('selectedRegionName');
      var e = y.replace(/"/g, '');
      var z = e.replace(' ', '_');
      this.leaderService.getPage(z,this.count).subscribe((data: any) => {
        var x =data['result'].list;
        x.map(o=>{this.leaderList.push(o)})
        this.listLeader = this.totalPages > this.count + 1 ? true : false;


      });
    }
    else {

      this.listLeader = false;

    }
  }
  getLeader(i) {

    this.leaderService.getAllLeader(i).subscribe((data: any) => {
      var x = [];
      x = data['result'].list;
      x.map(o => { this.leaderList.push(o) })
      this.totalPages = data.result.info.totalPages;
      this.listLeader = this.totalPages > 1 ? true : false;
    }, (err) => {
      this.notify.showError(err)
    });
  }
  ngOnInit(): void {
    this.storeProvince = localStorage.getItem('selectedRegionName');

    // this.storeProvince='British_Columbia';
    this.storageService.watchStorage().subscribe(value => {
      var x, y, z;
      y = localStorage.getItem('selectedRegionName');
      if (y) {
        if (y.includes("Qu")) {
          y = "Quebec";
        }
        x = y.replace(/"/g, "");
        z = x.replace(/ /g, "_");
      }
      else {
        z = null;
      }
      this.getLeader(z);
    });
  }

}
