<div class="row mar-zero trending_container">
    <div class="col s12 m6 l3 pad-zero hoverable" *ngFor="let List of trendingPrayerList;let i = index" >
        <div class="pray-trendcol card-sdw-btm">
            <div class="praytrend-image">
                <img src="{{List.thumbnail}}" class="responsive-img" alt="Trending_tThumbnail">
            </div>
            <p class="mar-zero txt-13 clr-777">{{List.desc | sliceDescription}} 
            </p>
            <div class="mt-15 btn-amen">
                <button type="button" class="waves-effect btn-lg"  [disabled]="List.disabled" [ngClass]="List.disabled ? 'disable':'enable'" (click)="addAmen(List,i)">
                    <div class="row">
                        <span>Amen</span>
                        <!-- <a  [disabled]="id===i"  *ngIf="!isLoad">Amen</a> -->
                        <div *ngIf="isLoad && id===i " class="loaderSpin">

                            <div id="loading">

                            </div>
                        </div>
                    </div>
                </button>
                <p class="total-count">{{List.amenCount}} AMENS</p>
            </div>
        </div>
    </div>
 
</div>