import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/services/page.service';
import { PrayerresourceService } from 'src/services/prayerresource.service';

@Component({
  selector: 'app-pdfsdetails',
  templateUrl: './pdfsdetails.component.html',
  styleUrls: ['./pdfsdetails.component.css']
})
export class PdfsdetailsComponent implements OnInit {
  bannertitle = "Pdfs"
  bannerDetailsTwo: any;
  pageDataTwo:any;
  pageData: any;
  pagetitle: any;
  template: any;
  pdfUrl:string="Pdf"
    bannerDetails:any;
    pdfList:any;
    pageCount:number=0;
    totalPages: any;
    listPdf:boolean=false;
    checkRes:boolean=false;
  checkSection: boolean = false;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  constructor( private pageservice: PageService,private resourceService:PrayerresourceService ) {
    this.pageservice.getPage('61af168f0790a51eebeb8c23').subscribe(res => {
      this.pageData = res.result.templateData;
      this.pagetitle=res.result.templateData.pdfsectiontitle;
      this.bannerDetails = [{ bibleQuotes: this.pageData.bannerquote, verses: this.pageData.bannerversus, file: this.pageData.bannerimage ? this.pageData.bannerimage : '../../../assets/images/about-bg.jpg' }]
      this.checkSection = true;
    })
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res =>{
      this.pageDataTwo=res.result.templateData;
      this.bannerDetailsTwo = {slidertitle1:this.pageDataTwo.riseupcanada,slidertitle2:this.pageDataTwo.prayforournation,sliders:this.pageDataTwo.banner}
      this.sectionDetails = {title:this.pageDataTwo.section2title,description:this.pageDataTwo.section2description,audio:this.pageDataTwo.section2audio,pdf:this.pageDataTwo.section2pdf}
      this.checkSection=true;
    })
   }
  pdfsdetailpage = [
    {
      title: "Pdfs",
      description: "",
      slug: "",
      image: "",
      htmlContent: "",
      template: "",
      template_field: [{
        "banner": [
          {
            "bibleQuotes": "So I sought for a man among them who would make a wall, and stand in the gap before Me on behalf of the land (Canada), that I should not destroy it; but I found no one.",
            "verses": "EZE 22:30",
            "file": "../../../assets/images/about-bg.jpg",
          },
         
        ],
       
      }]
    }
  ]
  getAllPdf(){
    this.resourceService.getAllResources(this.pdfUrl,this.pageCount).subscribe((data: any) => {
      this.checkRes = true;
      this.pdfList =data.result.resource;
      this.totalPages = data['result'].info.totalPages;
      // this.totalItems = data['result'].info.totalItems;
    });
  }
  loadMore() {
    this.pageCount= this.pageCount + 1;
    if (this.totalPages > this.pageCount) {
      this.resourceService.getAllResources(this.pdfUrl,this.pageCount).subscribe((data: any) => {
        var list = data.result.resource;
        list.map((o) => {
          this.pdfList.push(o);
        });
        this.listPdf = this.totalPages > this.pageCount + 1 ? true : false;
        this.checkRes = true;
      });
    } else {
      this.listPdf = false;
    }
  }
  ngOnInit(): void {
    this.getAllPdf();
  }

}
