<!-- <app-prayfornation *ngIf="checkSection" [banner]="bannerDetails"></app-prayfornation>
<app-footerprayerpoints *ngIf="checkSection" [details]="sectionDetails"></app-footerprayerpoints> -->
<!--Footer-->
<div class="container-outer" *ngIf="checkSection">
    <div class="footer-bg">
        <div class="row mar-zero">
            <div class="col s12 pad-zero">
                <div class="footer-top">
                    <div class="row mar-zero">
                        <div class="col s12 m3 l3 pad-zero center-align">
                            <div class="footer-logo">
                                <img src="../../../assets/images/logo-footer.jpg" class="responsive-img" alt=""
                                    title="">
                            </div>
                        </div>
                        <div class="col s12 m6 l6 pad-zero center-align">
                            <div class="ruc-newsletter">
                            <form [formGroup]="newsForm">
                                <div class="row mar-zero">
                                    <div class="col s12 m4 l4">
                                        <p class="newsletter-txt">{{pageData.newsletter}}</p>
                                    </div>
                                    <div class="col s12 m7 l7">
                                        <div class="newsletter-fld-panel">
                                            <input type="text" class="newsletter-field" id="inbox_email"
                                            formControlName="email"  placeholder="Enter your email"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                                                    <div class="validation-errors" *ngIf="f.email.errors.required">
                                                        *Email is required
                                                    </div>
                                                    <div  class="validation-errors" *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                    
                                                </div>
                                            <div class="newsletter-btn-block"><button class="newsletter-btn"
                                                    (click)="sendInbox()"><i class="mdi mdi-login"></i></button></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            </div>
                        </div>
                        <div class="col s12 m3 l3 pad-zero center-align">
                            <div class="ft-social-media">
                                <app-followus></app-followus>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col s12 pad-zero center-align">
                <div class="footer-bottom">
                    <div class="container">
                        <ul class="sitemaps">
                            <li><a href="" routerLink="">{{pageData.homenavbar}}</a></li>
                            <li><a href="" routerLink="/aboutus">{{pageData.aboutnavbar}}</a></li>
                            <li><a href="" routerLink="/province">{{pageData.provincenavbar}}</a></li>
                            <li><a href="" routerLink="/prayerpartners">{{pageData.partnersnavbar}}</a></li>
                            <li><a href="" routerLink="/prayerresource">{{pageData.resourcesnavbar}}</a></li>
                            <li><a href="" routerLink="/events">{{pageData.eventsnavbar}}</a></li>
                            <li><a href="" routerLink="/news">News</a></li>
                            <li><a href="" routerLink="/contactus">{{pageData.contactnavbar}}</a></li>
                        </ul>
                        <div class="copyright-txt">Copyright {{currentyear}}. Rise up canada</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-scroll-to-top></app-scroll-to-top>
<!--Footer-->