<div id="map"></div>
<!-- <div id="province-explore-panel" class="pro-expo-arrow" *ngIf="show">
                    <span class="pro-exp-close exp-pro"><i class="mdi mdi-close"></i></span>
                    <div class="row mar-zero">
                        <div class="col s12 pad-zero center-align">
                            <img id="provinceImage" src="" alt="" title="">
                        </div>
                        <div class="col s12 pad-zero center-align">
                            <div class="expo-pro-innerttl">
                                <span class="expo-pro-innerttl-1" id="provinceLabel">Province of</span><br>
                                <span id="provinceName" class="expo-pro-innerttl-2"></span>
                            </div>
                        </div>
                        <div class="col s12 pad-zero center-align test-hiddden">
                            <div id="testimonialSlider" class="">Trending Issues</div>
                        </div>
                        <div class="col s12 pad-zero center-align explore-btns-wrap">
                            <p class="mar-b-zero" id="explore_your"><a class="waves-effect expo-pro-btn" (click)="exploreProvince()">Explore your province</a></p>
                            <div class="spacer-sm"></div>
                            <p class="mar-b-zero"><a class="waves-effect expo-pro-btn" (click)="loadAP()">Vital
                                    Statistics</a></p>
                            <div class="spacer-sm"></div>
                            <p class="mar-t-zero" id="registerRUC"><a class="waves-effect expo-pro-btn" (click)="loadMenu()">Become a prayer partner</a></p>
                        </div>
                    </div>
                    </div>  -->