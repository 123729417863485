<div class="row mar-zero news_container">
    <div *ngIf="newslist.length===0" class="data-notfount">
        <h2 style="text-align: center;">No Records Found</h2>
    </div>
    <div class="col s12 m6 l3 pad-zero hoverable" *ngFor="let list of newslist">
        <div class="pray-trendcol card-sdw-btm">
            <div class="praytrend-image">
                <img src="{{list.thumbnail}}" class="responsive-img" alt="News_Thumbnail">
            </div>
            <span class="adbanner-txt-1"><b>{{list.title | slices}}</b> </span>
            <p class="mar-zero txt-13 clr-777" [innerHTML]="list.desc| sliceDescription">{{list.desc| sliceDescription}} </p>
            <div class="more-news">
                <p class="adbanner-contact">
                    <span class="adbanner-txt-2">{{list.createdDate| date}}</span>
                </p>
                <app-primarybutton buttonName="Read More" (click)="link(list.id)" routerLink="/newsdetails"></app-primarybutton>
            </div>
        </div>
    </div>
</div>
