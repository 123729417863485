import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EventsListService } from 'src/services/events.service';
import { GeoService } from 'src/services/geo.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/services/common.service';
import { CanadaPostalCode } from 'src/services/post-canade.service';
declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-selectdropdown',
  templateUrl: './selectdropdown.component.html',
  styleUrls: ['./selectdropdown.component.css']
})
export class SelectdropdownComponent implements OnInit {
  opts: any;
  eventList: any = [];
  eventListCopy: any = [];
  displayList: any;
  createAddressForm: FormGroup;
  province: any = [];
  city: any = [];
  provinceCopy: any = [];
  disableBtn:string=''
  filterName : string;
  search: string = '';
  selectId: string = '';
  autoCompletArr: any = [];
  filterData = { province: null, city: null, pincode: null }
  @Output() filterDropdown = new EventEmitter;
  openCountryList:boolean=false;

  constructor(public eventsListService: EventsListService, private canadaPostService: CanadaPostalCode, public geoService: GeoService, private formBuilder: FormBuilder, private commonservice: CommonService) { }
  provinceId: string = '';
  provinceName: string = '';
  //  get province details

  reset(){
   this.cityname='';
   this.provincename =''
  this.createAddressForm.reset();
  }
  onSubmit(pin) {
    this.filterData.pincode = (pin.value !== '' ? pin.value : null);
    this.filterData.province= this.createAddressForm.get('province')?.value.replace(/"/g, "").replace(/ /g, "_"),
    this.filterData.city=this.createAddressForm.get('city')?.value?this.createAddressForm.get('city')?.value:null,
    this.filterDropdown.emit(this.filterData);
  }
  
  autoComplete($event) {
    this.search = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
    this.selectId = '';
    this.autoCompleteService();
  }
  
  showList(){
    this.openCountryList=true;
  }

  autoCompleteService() {
    this.canadaPostService.autoComplete(this.search, this.selectId).subscribe(response => {
      this.autoCompletArr = [];
      this.autoCompletArr = response.Items;
    });
  }
  getRetriveAddress(){
    this.canadaPostService.retriveAddress(this.selectId).subscribe(response => {
      var item = response.Items[0];
       this.createAddressForm.patchValue({
        city: item.City.replace(/"/g, "").replace(/ /g, "_"),
        province: item.ProvinceName === "Québec"? item.ProvinceName.replace("Québec","Quebec"): item.ProvinceName.replace(/"/g, "").replace(/ /g, "_") ,
        pincode: item.PostalCode.replace(/"/g, "").replace(/ /g, "-")
      });
      this.autoCompletArr = [];
    });
  }

  selectAddress(item) {
    this.openCountryList=true;
    this.selectId = item.Id;
    if (item.Next == 'Find') {
      this.autoCompleteService();
    } else {
      this.getRetriveAddress();
    }
  }
 

  ngOnInit(): void {
    this.myselectbox();
    this.opts = {
      barBackground: '#1d9dcf',
      gridBackground: '#EFF1F5',
      barBorderRadius: '10',
      barWidth: '6',
      barMargin: '1px 0',
      gridWidth: '6',
      alwaysVisible: true
    };
    this.createAddressForm = this.formBuilder.group({

      pincode: [''],

      province: [''],
      city: [''],

    });
    $(".cselect").each(function () {

      var $input = $(this).find("input");
      var $dropDown = $(this).find("ul");

      $(this).on("click", function () {
        $dropDown.slideToggle();

        if (this) {
          $(".cselect").addClass('arrow-active');
        } else {
          $(this).removeClass('arrow-active');
        }
      });

      $dropDown.on("click", "li", function () {
        $input.val($(this).text());
      });

    });

  }
  myselectbox() {
    /*Dropdown Menu*/
    $('.dropdown').click(function () {
      $(this).attr('tabindex', 1).focus();
      $(this).toggleClass('active');
      $(this).find('.dropdown-menu').slideToggle(500);
    });
    $('.dropdown').focusout(function () {
      $(this).removeClass('active');
      $(this).find('.dropdown-menu').slideUp(500);
    });
    $('.dropdown .dropdown-menu li').click(function () {
      $(this).parents('.dropdown').find('span').text($(this).text());
      $(this).parents('.dropdown').find('input').attr('value', $(this).attr('id'));
    });
    /*End Dropdown Menu*/
  }
  provincename: string = null;
  selectProvince(p_name) {
    this.provincename = p_name;
    // this.cityname='';
  }
  cityname: string;
  selectCity(c_name) {
    this.cityname = c_name;
  }



}
