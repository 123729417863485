<div class="row mar-zero">
    <div *ngIf="prayerList.length===0" class="data-notfount">
        <h2 style="text-align: center;">No Records Found</h2>
    </div>
    <div class="col s12 m6 l3 pad-zero hoverable" *ngFor="let List of prayerList; let i = index ">
        <div class="prayers-trendcol-card card-sdw-btm">
            <div class="praytrend-image sensitive-list">
                <img src="{{List.thumbnail}}" class="responsive-img pticard-imght" alt="">
                <div class="pin-location" *ngIf="List.latitude && List.longitude !== null">
                    <img (click)="showMap(List.latitude,List.longitude)" src="../../../assets/images/location-pin.png" class="responsive-img" alt="">
                </div>
            </div>
            <span class="adbanner-txt-1"><b>{{List.title | slices}}</b> </span>
            <p class="mar-zero txt-13 clr-777 se-dec">{{List.desc | sliceDescription}}</p>
            <div class="pp-btn">
                <button class="newsletter-btn" [disabled]="List.disabled" [ngClass]="List.disabled ? 'disable':'enable'" (click)="addAmen(List,i)">
                    <div class="row">
                        <span>Amen</span>
                        <!-- <a  [disabled]="id===i"  *ngIf="!isLoad">Amen</a> -->
                        <div *ngIf="isLoad && id===i " class="loaderSpin">

                            <div id="loading">

                            </div>
                        </div>
                    </div>

                </button>

                <p class="total-count">{{List.amenCount}} Amens</p>
            </div>
        </div>
    </div>
</div>
