<!--Pray Testimonial-->
<div class="container-outer" *ngIf="checkSection">

    <div class="pray-testi-bg" style="background: #c42213 url({{pageData.bannerimage}}) no-repeat;background-size: cover;padding: 50px 0;text-align: center;">
        <div class="row mar-zero" *ngIf="checkSection">
            <div class="col s12 pad-zero pray_naction">
                <div class="container">
                    <div class="pray-testi-title" id="pray-testi-title">
                        {{bannerDetails.slidertitle1}} <br/>{{bannerDetails.slidertitle2}}
                    </div>
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">

                        <div ngxSlickItem *ngFor="let banner of bannerDetails.sliders">
                            <div class='pray-testi-content'>{{banner.biblequotes}}</div>
                            <div class='bible-num-count'>{{banner.bibeversus}}</div>
                        </div>

                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="currentPageName !== 'Home' && currentPageName !== 'Province'" class="footer-prayer-point">
<app-footerprayerpoints *ngIf="checkSection" [details]="sectionDetails"></app-footerprayerpoints>
</div>
<!--Pray Testimonial-->

