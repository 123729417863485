<div class="left-tpanel">
    <app-headerlogo></app-headerlogo>
    <!-- <a href="" style="z-index: 4" routerLink="/ ">
        <div class="logo" style="z-index: 9">
            <img src="../../../assets/images/logo.jpg" alt="Rise Up Canada" title="Rise Up Canada">
        </div>
    </a> -->
    <div class="province-box">
        <div class="row mar-zero">
            <div class="col s12 pad-zero">
                <div class="explore-province" id="loadMap">
                    <div class="left">
                        <span class="explore-province-txt-top">Explore Your<br>
                            Provinces</span>
                    </div>
                    <div class="right"> </div>
                    <!--map-->
                    <div class="ruc-left-map" id="canadamap">
                        <div class="mapWrapper" id="mapWidthDiv">
                            <!-- <div id="canada">
                         </div> -->
                            <app-sidebarmap></app-sidebarmap>
                        </div>
                    </div>
                    <!--map-->
                </div>
                <div class="explore-province" id="loadProvince">
                    <div class="col s12 pad-zero center-align">
                        <img id="province_image" src="" onclick="loadProvinceByImage()" class="responsive-img exp-pro" alt="" title="">
                    </div>
                    <!-- <div class="col s12 pad-zero center-align">
                        <div class="static-left-innerttl">
                            <span class="static-left-innerttl-1" id="pray_for">Pray for<br>province
                                of</span><br>
                            <span class="static-left-innerttl-2" id="province_name"></span><br>
                            <span class="static-left-innerttl-3" id="city"></span>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>