import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  sendssg = new Subject<any>();
    
  constructor(private http: HttpClient) { }
    getNews(ProvinceName) {
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });
    return this.http.get<any>(`${environment.apiUrl}/news?province=${ProvinceName}`, { headers: headers });
  }
  getFilteredData(ProvinceName,cityName,postalCode) {
      
    
    let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    });
  
    return this.http.get<any>(`${environment.apiUrl}/news-filter?province=${ProvinceName}&city=${cityName}&pincode=${postalCode}`,{ headers: headers });
}

  getPage(ProvinceName,value) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
  });
    
    return this.http.get(`${environment.apiUrl}/news?province=${ProvinceName}?page=` + `${value}`);

  }
  getSingleNews(id: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
  
    });
    return this.http.get<any>(`${environment.apiUrl}/news/` + id, { headers: headers });
    // { headers: headers, });
  }
  public getmsg(res:any) {
    this.sendssg.next(res);
  }
}