<div class="row mar-zero" *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <app-innerbanner [bannerDetails]="bannerDetails" [title]="pagetitle"></app-innerbanner>
        </div>
    </div>
</div>
<div class="container-outer" *ngIf="checkSection">
    <div class="static-section-panel bg-transparent">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">{{pageData.section1title}}</span><br />
                        <span id="prayers-section-ltitle-sub3"
                            class="prayers-section-ltitle-sub">{{pageData.section1subtitle}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
                <div class="event-ldmr-panel">
                    <div class="prayers-lbox-count-list">
                        <p id="socprayers_count" class="pray-count-num">{{totalPrayer}}</p>
                        <p class="mar-zero pray-count-type">
                            <span class="pray-count-nam">{{pageData.prayercountname}}</span><br>
                            <span class="pray-count-pttxt">{{pageData.prayercounttext}}</span>
                        </p>
                    </div>
                    <button *ngIf="isLoad" (click)="loadMore()" type="button"
                        class="waves-effect btn-lg">{{pageData.loadmore}} </button>
                </div>
            </div>
        </div>
        <div class="static-section-rpanel-card add-block-box bg-white pb-0">
            <div class="col s12 pad-zero">
                <div class="personal-wall pad-left">
                    <div class="personal-heading">
                        <span class="static-section-ltitle">{{pageData.sectiontitle}}</span>
                    </div>
                    <div class="">
                        <div class="row mar-zero">

                            <form [formGroup]="createForm">
                                <div class="col s12 m6 l6 pad-zero pr-5">
                                    <div class="col s12 pad-zero">
                                        <input type="text" class="pers-prayer-fld" id="name" placeholder="Name"
                                            formControlName="name">
                                        <!-- <div *ngIf="createForm.controls['name'].invalid && (createForm.controls['name'].dirty || createForm.controls['name'].touched)"
                                            class="alert alert-danger">
                                            <div class="validation-errors"
                                                *ngIf="createForm.controls['name'].errors.required">
                                                *Name is required.
                                            </div>
                                        </div> -->
                                        <div class="alert alert-danger" *ngIf="submitted && f.name.errors"
                                            class="invalid-feedback">
                                            <div class="validation-errors" *ngIf="f.name.errors.required">
                                                *Name is required</div>
                                        </div>
                                    </div>
                                    <!-- <div class="col s12 pad-zero">
                                        <div class="dropdown" (click)="myselectbox01();">
                                            <div class="select">
                                                <span>Choose Your Province</span> -->
                                    <!-- <span>{{cityname}}</span> -->
                                    <!-- </div>
                                            <input type="hidden" name="gender" >
                                            <ul class="dropdown-menu"> -->
                                    <!-- <li (click)="getPostal(list)" id="{{list.name}}"
                                                    (click)="selectCity(list.name)" *ngFor="let list of city">
                                                    {{list.name}}
                                                </li> -->
                                    <!-- <li>JONESsssss</li>
                                            </ul>
                                        </div>
                                    </div> -->
                                    <div class="col s12 pad-zero">

                                        <select class="select2 form-control" style="width: 100%;display: block;"
                                            formControlName="prayerCat" (change)="getValue($event)"
                                            placeholder="Category">
                                            <option value="" hidden selected>Select a Category</option>
                                            <option [value]="list.id" *ngFor="let list of category">
                                                {{ list.title}}
                                            </option>
                                        </select>
                                        <!-- <div *ngIf="createForm.controls['prayerCat'].invalid && (createForm.controls['prayerCat'].dirty || createForm.controls['prayerCat'].touched)"
                                            class="alert alert-danger">
                                            <div class="validation-errors"
                                                *ngIf="createForm.controls['prayerCat'].errors.required">
                                                *Category is required
                                            </div>
                                        </div> -->
                                        <div class="alert alert-danger" *ngIf="submitted && f.prayerCat.errors"
                                            class="invalid-feedback">
                                            <div class="validation-errors" *ngIf="f.prayerCat.errors.required">
                                                *category is required</div>
                                        </div>
                                    </div>
                                    <div class="col s12 pad-zero mt-5 ">

                                        <select *ngIf="showSubCatagory" class="select2 form-control"
                                            placeholder="Category" style="width: 100%;display: block;"
                                            formControlName="prayerSubcat" (change)="getPrayers($event)">
                                            <option value="" hidden selected>Select a Sub Category</option>
                                            <option [value]="list.id" *ngFor="let list of subcategory">
                                                {{list.title}}
                                            </option>
                                        </select>
                                        <div class="alert alert-danger" *ngIf="submitted && f.prayerSubcat.errors"
                                            class="invalid-feedback">
                                            <div class="validation-errors" *ngIf="f.prayerSubcat.errors.required"> *sub
                                                category is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col s12 m6 l6 pad-zero pl-5 ">
                                    <div class="col s12 pad-zero">
                                        <textarea class="pers-prayer-fld" id="prayer_content" formControlName="prayer"
                                        #myInput [maxlength]="maxChars" placeholder="Add your prayer"
                                            [ngClass]="{ 'is-invalid': submitted && f.prayer.errors }"></textarea>
                                            <br />
                                            <label class="characterCount">
                                                Characters Remaining: {{myInput.value.length}} /{{maxChars}}</label>
                                        <!-- <div *ngIf="createForm.controls['prayer'].invalid && (createForm.controls['prayer'].dirty || createForm.controls['prayer'].touched)"
                                            class="alert alert-danger">
                                            <div class="validation-errors"
                                                *ngIf="createForm.controls['prayer'].errors.required">
                                                *Prayer is required
                                            </div>
                                        </div> -->

                                        <div class="alert alert-danger" *ngIf="submitted && f.prayer.errors"
                                            class="invalid-feedback">
                                            <div class="validation-errors" *ngIf="f.prayer.errors.required"> *Prayer
                                                is required</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col s12 pad-zero center-align mt-15">
                                <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                                </ngx-recaptcha2>
                                </div> -->

                                <div class="col s12 pad-zero center-align mt-15">
                                    <button class="waves-effect btn-sm"
                                        (click)="submitPrayer()">{{pageData.submitbutton}}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col s12 m6 l6 pad-zero" id="personalPrayerList">
                    <app-personalprayerlist [item]="howToPrayList" [approvedList]="approvedList"
                        [prayerPdfUrl]="prayerPdfUrl">
                    </app-personalprayerlist>
                </div>
                <div class="cen-btn">
                    <a class="waves-effect btn-lg" routerLink="/province">BACK TO PROVINCE</a>
                </div>
            </div>
        </div>
    </div>

</div>
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>
<!-- <app-chooseprovince></app-chooseprovince> -->