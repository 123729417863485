import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { HomepageComponent } from './Templates/homepage/homepage.component';
import { AboutComponent } from './Templates/about/about.component';
import { ProvinceComponent } from './Templates/province/province.component';
import { PrayerpartnersComponent } from './Templates/Partners/prayerpartners/prayerpartners.component';
import { PartnerbenefitsComponent } from './Templates/Partners/partnerbenefits/partnerbenefits.component';
// import { RegistrationComponent } from './Templates/Partners/registration/registration.component';
import { WhyprayComponent } from './Templates/Resources/whypray/whypray.component';
import { HowtoprayComponent } from './Templates/Resources/howtopray/howtopray.component';
import { PrayerresourceComponent } from './Templates/Resources/prayerresource/prayerresource.component';
import { PrayerpointsceComponent } from './Templates/Resources/prayerpointsce/prayerpointsce.component';
import { EventsComponent } from './Templates/events/events.component';
import { ContactusComponent } from './Templates/contactus/contactus.component';
import { PrayforleadersComponent } from './Templates/prayforleaders/prayforleaders.component';
import { SocialprayerComponent } from './Templates/socialprayer/socialprayer.component';
import { NewsComponent } from './Templates/news/news.component';
import { IndividualComponent } from './Templates/regester/individual/individual.component';
import { GroupComponent } from './Templates/regester/group/group.component';
import { PrayerwarriorComponent } from './Templates/regester/prayerwarrior/prayerwarrior.component';
import { EventsdetailsComponent } from './Templates/events/eventsdetails/eventsdetails.component';
import { NewsdetailsComponent } from './Templates/news/newsdetails/newsdetails.component';
import { ChristianleadersComponent } from './Templates/christianleaders/christianleaders.component';
import { VideodetailsComponent } from './Templates/videodetails/videodetails.component';
import { AudiosdetailsComponent } from './Templates/audiosdetails/audiosdetails.component';
import { BooksdetailsComponent } from './Templates/booksdetails/booksdetails.component';
import { PdfsdetailsComponent } from './Templates/pdfsdetails/pdfsdetails.component';
import { SensitiveprayerComponent } from './Templates/sensitiveprayer/sensitiveprayer.component';
import { PersonalprayerComponent } from './Templates/personalprayer/personalprayer.component';
import { PartnerorganizationComponent } from './Templates/Partners/partnerorganization/partnerorganization.component';
import { ChangepasswordComponent } from './Templates/changepassword/changepassword.component';
import { AuthGuard } from 'src/helper/auth.guard';
import {KneelingarmyComponent} from 'src/app/Templates/regester/kneelingarmy/kneelingarmy.component';
const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload',
  anchorScrolling: 'enabled'
};
const routes: Routes = [
  { path: '', component: HomepageComponent, pathMatch: 'full', data : {title:'RiseUp Canada'}},
  { path: 'aboutus', component: AboutComponent, pathMatch: 'full', data : {title:'About Us - RiseUp Canada'}},
  { path: 'province', component: ProvinceComponent, pathMatch: 'full', data:{title:'Province - RiseUp Canada'}},
  { path: 'prayerpartners', component: PrayerpartnersComponent, pathMatch: 'full', data:{title:'Prayer Partners - RiseUp Canada'}},
  { path: 'partnerbenefits', component: PartnerbenefitsComponent, pathMatch: 'full', data:{title:'Partner Benefits - RiseUp Canada'} },
  { path: 'partnerorganization', component: PartnerorganizationComponent, pathMatch: 'full',data:{title:'Partner Organization - RiseUp Canada'}},
  // { path: 'registration', component: RegistrationComponent, pathMatch: 'full' },
  { path: 'events', component: EventsComponent, pathMatch: 'full',data:{title:'Events - RiseUp Canada'}},
  { path: 'eventsdetails/:id', component: EventsdetailsComponent, pathMatch: 'full',data:{title:'Event - RiseUp Canada'}},
  { path: 'whypray', component: WhyprayComponent, pathMatch: 'full', data:{title:'Why Pray - RiseUp Canada'}},
  { path: 'howtopray', component: HowtoprayComponent, pathMatch: 'full',data:{title:'How To Pray - RiseUp Canada'}},
  { path: 'prayerresource', component: PrayerresourceComponent, pathMatch: 'full',data:{title:'Prayer Resources - RiseUp Canada'}},
  { path: 'prayerresource/:id', component: PrayerresourceComponent, pathMatch: 'full',data:{title:'Prayer Resources - RiseUp Canada'}},
  { path: 'prayerpoints', component: PrayerpointsceComponent, pathMatch: 'full',data:{title:'Prayer Points - RiseUp Canada'}},
  { path: 'contactus', component: ContactusComponent, pathMatch: 'full', data:{title:'Contact Us - RiseUp Canada'}},
  { path: 'prayforleaders', component: PrayforleadersComponent, pathMatch: 'full', data:{title:'Pray For Leaders - RiseUp Canada'}},
  { path: 'socialprayer', component: SocialprayerComponent, pathMatch: 'full', data:{title:'Social Prayer - RiseUp Canada'}},
  { path: 'news', component: NewsComponent, pathMatch: 'full', data:{title:'News - RiseUp Canada'}},
  { path: 'newsdetails/:id', component: NewsdetailsComponent, pathMatch: 'full', data:{title:'News - RiseUp Canada'}},
  { path: 'individual', component: IndividualComponent, pathMatch: 'full', data:{title:'Individual Registertion - RiseUp Canada'}},
  { path: 'group', component: GroupComponent, pathMatch: 'full', data:{title:'Group Registertion - RiseUp Canada'}},
  { path: 'prayerwarrior', component: PrayerwarriorComponent, pathMatch: 'full', data:{title:'Prayer Warrior - RiseUp Canada'}},
  { path: 'kneelingarmy', component: KneelingarmyComponent, pathMatch: 'full', data:{title:'Kneeling Army - RiseUp Canada'}},
  { path: 'christianleaders', component: ChristianleadersComponent, pathMatch: 'full', data:{title:'Christian Leaders - RiseUp Canada'}},
  { path: 'videos', component: VideodetailsComponent, pathMatch: 'full', data:{title:'Videos - RiseUp Canada'}},
  { path: 'audios', component: AudiosdetailsComponent, pathMatch: 'full', data:{title:'Audios - RiseUp Canada'}},
  { path: 'books', component: BooksdetailsComponent, pathMatch: 'full', data:{title:'Books - RiseUp Canada'}},
  { path: 'pdfs', component: PdfsdetailsComponent, pathMatch: 'full', data:{title:'PDF - RiseUp Canada'}},
  { path: 'Strongholds', component: SensitiveprayerComponent, pathMatch: 'full', canActivate: [AuthGuard], data:{title:'Pray For Strongholds - RiseUp Canada'} },
  { path: 'personalprayer', component: PersonalprayerComponent, pathMatch: 'full', data:{title:'Personal Prayer - RiseUp Canada'}},
  { path: 'changepassword', component: ChangepasswordComponent, pathMatch: 'full', canActivate: [AuthGuard], data:{title:'Change Password - RiseUp Canada'} }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
