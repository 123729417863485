<div class="row mar-zero"  *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <!-- <app-headerlogo></app-headerlogo> -->
            <!-- <div class="row mar-zero">
                <div class="spacer-sm"></div>
                <div class="col s12 pad-zero center-align">
                    <img src="" id="ppprovince_image" alt="" title="">
                </div>
                <div class="col s12 pad-zero center-align">
                    <div class="static-left-innerttl">
                        <span class="static-left-innerttl-1" id="ppprovince_label">Pray for<br>province of</span><br>
                        <span class="static-left-innerttl-2" id="ppprovince_name"></span><br>
                        <span class="static-left-innerttl-3" id="ppcity">Sherbrooke</span>
                    </div>
                </div>
            </div> -->
            <!-- <app-provincemap></app-provincemap> -->
            <!-- <app-logo-layout></app-logo-layout> -->
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <app-innerbanner [bannerDetails]="bannerDetails" [title]="pagetitle"></app-innerbanner>
        </div>
    </div>
</div>


<div class="container-outer" *ngIf="checkSection">
    <!--About Riseup Nation-->
    <div class="static-section-panel">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">{{pageData.section1title}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="static-section-rpanel-card add-block-box mobile-left">
            <div class="floating_image">
                <img class="responsive-img" id="aboutimage" src="{{pageData.section1image}}">
            </div>
            <div class="pad-l-zero">
                <div class="">
                    <div class="static-bible-quote" id="biblecontent">{{pageData.section1biblequote}}</div>
                    <div class="static-bible-quote-num" id="biblequote">{{pageData.section1bibleversus}}</div>
                </div>
                <div class="" id="content2">
                    <p class="txt-14 clr-888" [innerHTML]="description">{{description}}</p>
                </div>
            </div>
            <app-primarybutton *ngIf="!isLogin" buttonName={{pageData.section1button}} routerLink="/individual"></app-primarybutton>
            <!-- <a class="waves-effect btn-lg" onclick="loadMenu('registration')">Become a Pray Partner</a> -->
        </div>
    </div>
    <!--About Riseup Nation-->

    <!--How we support our Nation-->
    <div class="static-section-panel">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">{{pageData.section1title}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="static-section-rpanel-card">
            <div class="row mar-zero">
                <div class="col s12 m6 l6 pad-zero">
                    <div class="pray-trendcol ht-auto">
                        <div class="praytrend-image">
                            <img src="{{pageData.section2image1}}" class="responsive-img" alt="">
                        </div>
                        <p class="static-more-action-tle">{{pageData.section2subtitle1}}</p>
                        <p class="txt-14 mar-t-zero clr-888 slicecontent">{{pageData.section2description1}} </p>
                        <p><a class="waves-effect btn-lg" href=""
                            routerLink="/partnerbenefits">{{pageData.section2button1}}</a></p>
                    </div>
                </div>
                <div class="col s12 m6 l6 pad-zero">
                    <div class="pray-trendcol ht-auto">
                        <div class="praytrend-image">
                            <img src="{{pageData.section2image2}}" class="responsive-img" alt="">
                        </div>
                        <p class="static-more-action-tle">{{pageData.section2subtitle2}}</p>
                        <p class="txt-14 mar-t-zero clr-888 slicecontent">{{pageData.section2description2}} </p>
                        <p><a class="waves-effect btn-lg" href=""
                            routerLink="/prayerwarrior">{{pageData.section2button2}}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--How we support our Nation-->
</div>
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>