import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationService } from 'src/services/notification.service';
import { PageService } from 'src/services/page.service';
import { SensitivePrayerService } from 'src/services/sensitiveprayer.service';
import { StorageService } from 'src/services/storage.service';

@Component({
  selector: 'app-sensitiveprayer',
  templateUrl: './sensitiveprayer.component.html',
  styleUrls: ['./sensitiveprayer.component.css']
})
export class SensitiveprayerComponent implements OnInit {
  bannertitle = "Sensitive Prayer"
  pageData:any;
  pagetitle:any;
  template:any;
  listPrayer:boolean=false;
  checkSection:boolean=false;
  bannerDetails: any;
  biblequotes: any;
  prayerList: any = [];
  data:any;
  prayer=[];
  filteredSensitiveCount:any;
  count=0;
  totalPages: any;
  province:any;
  sensitiveCount:any=[];
  bannerDetailsTwo: any;
  pageDataTwo:any;
  dataList: Array<any> = [];
  isCount:boolean=false;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  constructor(private pageservice:PageService,private prayerService: SensitivePrayerService,public storageService:StorageService,private notify: NotificationService) {
    this.pageservice.getPage('60e4057a1b31b83d765bbc1a').subscribe(res =>{
      this.pageData=res.result.templateData;
      this.pagetitle=res.result.title;
      // this.bannerDetails = [{bibleQuotes:this.pageData.bannerquote,verses:"EZE 33:11",file:"azar"}]
      this.bannerDetails = [{bibleQuotes:this.pageData.bannerquote,verses:this.pageData.bannerversus,file:this.pageData.bannerimage?this.pageData.bannerimage:'../../../assets/images/about-bg.jpg'}]
      this.checkSection=true;
    },(err) => {
      this.notify.showError(err)
    });
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res =>{
      this.pageDataTwo=res.result.templateData;
      this.bannerDetailsTwo = {slidertitle1:this.pageData.riseupcanada,slidertitle2:this.pageData.prayforournation,sliders:this.pageData.banner}
      this.sectionDetails = {title:this.pageData.section2title,description:this.pageData.section2description,audio:this.pageData.section2audio,pdf:this.pageData.section2pdf}
      this.checkSection=true;
    },(err) => {
      this.notify.showError(err)
    });
    

   }
  sensitivepage = [
    {
      title: "Sensitive Prayer",
      description: "",
      slug: "",
      image: "",
      htmlContent: "",
      template: "",
      template_field: [{
        "banner": [
          {
            "bibleQuotes": "So I sought for a man among them who would make a wall, and stand in the gap before Me on behalf of the land (Canada), that I should not destroy it; but I found no one.",
            "verses": "EZE 22:30",
            "file": "https://ruc-frontend-webapp-dev-knjyght5ka-uc.a.run.app/about-bg.jpg",
          },
         
        ],
       
      }]
    }
  ]
  
  getData(event: any) {
    if (event.province != null) {
      this.province = event.province;
    }
    this.prayerService.getFilteredData(event.province,event.city,event.pincode,event.category).subscribe((Res) => {
      this.isCount=true;
      this.filteredSensitiveCount=Res.result.info.totalItems;
      this.data=Res['result'].list;
      this.prayerService.getmsg(this.data);

    });
  }
  getPrayer() {
    var y=localStorage.getItem('selectedRegionName');
    var p = y.replace(/"/g, "")
    this.province = p.replace(/ /g, "_");
  
    this.prayerService.getAllPrayer(this.province).subscribe((data: any) => {
      var x = [];
      x = data['result'].list;
      x.map(o=>{this.prayerList.push(o)})
      this.totalPages = data.result.info.totalPages;
      this.listPrayer= this.totalPages > 1 ? true:false;
      this.sensitiveCount= data.result.info.totalItems;
      
      
    },(err) => {
      this.notify.showError(err)
    });
  }
  loadMore() {
    
    this.count=this.count+1;
    if(this.count<this.totalPages){
      var y=localStorage.getItem('selectedRegionName');
    var p =y.replace(/"/g,"");
    var z =p.replace(" ","_");
  this.prayerService.getPage(z,this.count).subscribe((data: any) => {
    var x = data['result'].list;
    // this.prayerList=[];
    x.map(o=>{this.prayerList.push(o)})
    this.listPrayer = this.count + 1 < this.totalPages?true:false;  
  

 });
    }
    else{

      this.listPrayer=false;  
      
    }
  }
  

  ngOnInit(): void {
    this.storageService.watchStorage().subscribe(value => {
      this.getPrayer();
    });
    
  }

}
