import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';
import { PrayerresourceService } from 'src/services/prayerresource.service';
declare var jQuery:any;
declare var $: any;
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {
  @Input() videoList:any
  noOfVideos:any;
  videoUrl:string="Videos"
  pageCount:number=0;
  showpopup: boolean = false;
  data: any;
  urlSafe: SafeResourceUrl;
  constructor(private resourceService:PrayerresourceService,public sanitizer: DomSanitizer) { }

  videolist = [
    {
      'thumbnail': "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ36k5Wl9S3A-hdU_fqrM3onAxONGTV5HpUfZkJySFwn9TxHRo78GTZNLBZglDsro9oOd4&usqp=CAU",
      'title': "CELINE DION",
      'ptype': "Prayer",
      'date': "Thu Jul 14 2016"
    },
    {
      'thumbnail': "http://www.miraclefamily.org/wp-content/uploads/2018/05/R14.jpg",
      'title': "CELINE DION",
      'ptype': "Prayer",
      'date': "Thu Jul 14 2016"
    },
    {
      'thumbnail': "http://www.mclaurinbaptist.org/wp-content/uploads/2018/06/Canada_flag-7.jpg",
      'title': "CELINE DION",
      'ptype': "Prayer",
      'date': "Thu Jul 14 2016"
    },
    {
      'thumbnail': "https://pbs.twimg.com/profile_images/940217113972588544/lsunsrNV.jpg",
      'title': "CELINE DION",
      'ptype': "Prayer",
      'date': "Thu Jul 14 2016"
    },
    {
      'thumbnail': "http://www.mclaurinbaptist.org/wp-content/uploads/2018/06/Canada_flag-7.jpg",
      'title': "CELINE DION",
      'ptype': "Prayer",
      'date': "Thu Jul 14 2016"
    },
    {
      'thumbnail': "https://pbs.twimg.com/profile_images/940217113972588544/lsunsrNV.jpg",
      'title': "CELINE DION",
      'ptype': "Prayer",
      'date': "Thu Jul 14 2016"
    },
    {
      'thumbnail': "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ36k5Wl9S3A-hdU_fqrM3onAxONGTV5HpUfZkJySFwn9TxHRo78GTZNLBZglDsro9oOd4&usqp=CAU",
      'title': "CELINE DION",
      'ptype': "Prayer",
      'date': "Thu Jul 14 2016"
    },
    {
      'thumbnail': "http://www.miraclefamily.org/wp-content/uploads/2018/05/R14.jpg",
      'title': "CELINE DION",
      'ptype': "Prayer",
      'date': "Thu Jul 14 2016"
    }
  ]
  
  ngOnInit(): void {
    // this.loadM odal();
    // this.loadModal();
    // $('.modal-trigger').leanModal({
    //   dismissible: true, // Modal can be dismissed by clicking outside of the modal
    //   opacity: .5, // Opacity of modal background
    //   in_duration: 300, // Transition in duration
    //   out_duration: 200, // Transition out duration
    //   starting_top: '4%', // Starting top style attribute
    //   ending_top: '10%', // Ending top style attribute
    //   ready: function () { //alert('Ready');
    //   }, // Callback for Modal open
    //   complete: function () { //alert('Closed');
    //   } // Callback for Modal close
    // });




  }
  ngAfterView() {

  }

OpenVideoPopup(mediaUrl){
  this.showpopup=true;
  this.urlSafe= this.sanitizer.sanitize(SecurityContext.URL, mediaUrl.replace('watch?v=','embed/'));

}
closePopup(){
  this.showpopup=false;
}
}
