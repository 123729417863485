import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrayerPointsService {

  constructor(private http: HttpClient) { }
  createPrayerList(data: any) {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });
    let body = data;
    return this.http.post<any>(`${environment.apiUrl}/prayerpoint`, body,
      {
        headers: httpHeaders,
        observe: 'response',
      });
  }
   // Get Single Items
   getPrayer(id: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',

    });
    return this.http.get<any>(`${environment.apiUrl}/prayerpoint/` + id, { headers: headers });
  
  }

  // Get All Prayers Lists
  getPrayerList(currentPage: number=-1) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });
    return this.http.get<any>(`${environment.apiUrl}/prayerpoint?page=` + `${currentPage}`, { headers: headers });
  }
  
}
