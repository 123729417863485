import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/services/storage.service';
declare var $: any;
@Component({
  selector: 'app-provincemap',
  templateUrl: './provincemap.component.html',
  styleUrls: ['./provincemap.component.css']
})
export class ProvincemapComponent implements OnInit {
  city_name: any;
  province_code: any;
  division_name: any;
  constructor(public storageService:StorageService) { }

  ngOnInit(): void {
    this.storageService.watchStorage().subscribe(value => {
     this.province_code = JSON.parse(localStorage.getItem('selectedRegion'));
     this.division_name = JSON.parse(localStorage.getItem('selectedRegionName'));
    });

  }

}
