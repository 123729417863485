import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';



@Injectable({ providedIn: 'root' })
export class GeoService {
    constructor(private http: HttpClient) { }

    getProvince() {
        return this.http.get<any[]>(`${environment.apiUrl}/geo/` + '6043bc059bfa2c1b29ae3497', {
            observe: 'response',
        });
    }

    getCity(id: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/geo/` + id, {
            observe: 'response',
        });
    }
}