<div *ngIf="socialprayerList.length===0" class="data-notfount">
    <h2 style="text-align: center;">No Records Found</h2>
</div>
<div class="col s12 m6 l3 pad-zero hoverable" *ngFor="let List of socialprayerList; let i = index">
    <div class="social_container">
        <div class="prayers-trendcol-card">
            <div class="prayers-trend-image">
                <img src="{{List.thumbnail}}" class="responsive-img" alt="Social_Thumbnail">
            </div>
            <p class="pticard-cont-ht txt-13 clr-777">
                {{List.desc}}
            </p>
            <div class="mt-15 btn-amen">
                <button type="button" class="waves-effect btn-lg" [disabled]="List.disabled" [ngClass]="List.disabled ? 'disable':'enable'" (click)="addAmen(List,i)">
                    <div class="row">
                        <span>Amen</span>
                        <!-- <a  [disabled]="id===i"  *ngIf="!isLoad">Amen</a> -->
                        <div *ngIf="isLoad && id===i " class="loaderSpin">

                            <div id="loading">

                            </div>
                        </div>
                    </div>
                </button>
                <p class="total-count">{{List.amenCount}}  AMENS</p>
            </div>
        </div>
    </div>

</div>