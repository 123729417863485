import { Component, OnInit } from '@angular/core';
declare var $: any
@Component({
  selector: 'app-loginmodule',
  templateUrl: './loginmodule.component.html',
  styleUrls: ['./loginmodule.component.css']
})
export class LoginmoduleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // Hide menu once we know its width

  }

}
