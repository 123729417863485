import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PrayerresourceService {

  constructor(private http: HttpClient) { }
getAllResources(urlType:string,pageCount:number) {
  let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
  });
  return this.http.get<any>(`${environment.apiUrl}/resource?type=${urlType}&page=` + `${pageCount}`, { headers: headers });
}
}
