import { ViewportScroller } from '@angular/common';

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/services/storage.service';
import { EventsListService } from 'src/services/events.service';
import { PageService } from 'src/services/page.service';
import { SensitivePrayerService } from 'src/services/sensitiveprayer.service';
import { SocialPrayerService } from 'src/services/socialprayer.service';
import { ChristianLeaderService } from 'src/services/christianleader.service';
import { PersonalprayerService } from 'src/services/personalprayer.service';
import { AuthenticationService } from 'src/services/authentication.service';
import { NotificationService } from 'src/services/notification.service';
import { NewsService } from 'src/services/news.service';
import { PrayerresourceService } from 'src/services/prayerresource.service';


declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomepageComponent implements OnInit {
  prayerList: any = [];
  socialPrayerCount: any = [];
  sensitivePrayerCount: any = [];
  fragment: any;
  pageData: any;
  template: any;
  pagetitle: any;
  checkSections: boolean = false;
  bannerDetails: any;
  biblequotes: any;
  description: any;
  checkRes: boolean = false;
  socialprayerList: any = [];
  output: any;
  eventList: any = [];
  eventListCopy: any = [];
  events = [];
  count = 0;
  totalPages: any;
  leaderList: any = [];
  newslist:any=[];
  totalPrayer: any = [];
  show: boolean = false;
  displayList:boolean=false;
  division_name: any;
  province: any;
  province_code: any;
  socialList: any = [];
  trendingPrayerList:any=[];
  slides: any;
  bannerDetailsTwo: any;
  pageDataTwo: any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  viewportScroller: any;
  isLogin: boolean = false;
  status:string='approved';
  currentPage: number = 0;
  videoUrl:string="Videos";
  pageCount:number=0;
  audioUrl:string="Audio"
  pdfUrl:string="Pdf"
  bookUrl:string="Books"
  videoList:any;
  pdfList:any;
  audioLists:any;
  bookList:any;
  totalVideos:string;
  totalPdf:string;
  totalAudio:string;
  totalBooks:string;
  provincePdfUrl:any;
  pdfData:any;

  currentPageName:string="Home";

  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private authenticationService: AuthenticationService, 
    private pageservice: PageService, 
    private prayerService: SocialPrayerService, 
    private prayerservice: SensitivePrayerService, 
    public eventsList: EventsListService, 
    public storageService: StorageService, 
    public christianleader: ChristianLeaderService, 
    public prayerPoint: PersonalprayerService, 
    private notify: NotificationService, 
    private newsService: NewsService, 
    private resourceService:PrayerresourceService

    ) {
    if (this.authenticationService.currentUserValue) {
      this.isLogin = true;
    }
    this.pageservice.getPage("61924175a8dcec12b4893af5").subscribe(res => {
      this.pdfData = res.result.templateData;
      this.checkSections = true;
    }, (err) => {
      this.notify.showError(err)
    })
    this.pageservice.getPage('60d45860814b4e7bcecfe901').subscribe(res => {
      this.pageData = res.result.templateData;
      this.pagetitle = res.result.title;
      this.description = res.result.description;
      this.bannerDetails = [{ bibleQuotes: this.pageData.bannerquote, verses: this.pageData.bannerversus, title: this.pageData.bannertitle, btntext: this.pageData.buttonname, bannerimage: this.pageData.bannerimage }]
      this.biblequotes = [{ texthead: this.pageData.bannerquote2, quotesnum: this.pageData.bannerversus2, description: this.pageData.section1description, thumbnail: '' }]

      this.checkSections = true;
    })
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res => {
      this.pageDataTwo = res.result.templateData;
      this.bannerDetailsTwo = { slidertitle1: this.pageDataTwo.riseupcanada, slidertitle2: this.pageDataTwo.prayforournation, sliders: this.pageDataTwo.banner }
      this.sectionDetails = { title: this.pageDataTwo?.section2title, description: this.pageDataTwo?.section2description, audio: this.pageData?.section2audio, pdf: this.pageData?.section2pdf }
      this.checkSections = true;
    })

    this.currentPageName;
  }

  getPrayer(province) {
    this.prayerService.getAllPrayer(province).subscribe((data: any) => {
      this.socialPrayerCount = data.result.info.totalItems;
    }, (err) => {
      this.notify.showError(err)
    });
  }

  getPrayers(province) {
    this.prayerservice.getAllPrayer(province).subscribe((data: any) => {
      this.sensitivePrayerCount = data.result.info.totalItems;
    }, (err) => {
      this.notify.showError(err)
    });
  }

  getEvents(province) {
    this.checkRes = false;
    this.eventsList.getEvents(province).subscribe((data: any) => {
      this.eventList = [];
      this.eventList = data['result'].list.splice(0, 4);
      this.checkRes = true;
      this.totalPages = data['result'].info.totalPages;
    }, (err) => {
      this.notify.showError(err)
    });
  }

  getTrendingData() {
    var z =null;
    this.prayerService.getTrendingPrayer(z).subscribe((data: any) => {
      this.trendingPrayerList = data['result'].list;
      this.displayList=true;
    }, (err) => {
      this.notify.showError(err)
    });
  }

  getChristianLeader() {

    this.christianleader.getAllChristianLeader().subscribe((data: any) => {
      this.leaderList = [];
      this.leaderList = data['result'].list;
    }, (err) => {
      this.notify.showError(err)
    });
  }
  getList(status,currentPage) {
    this.prayerPoint.getApprovedData(status,currentPage).subscribe((data: any) => {
      this.totalPrayer = data.result.info.totalItems;
    }, (err) => {
      this.notify.showError(err)
    });
  }
  getNews(province){
    this.newsService.getNews(province).subscribe((data: any) => {
    this.newslist= data['result'].list;
    
    });
  }
  getSocialPrayer(province) {
    this.prayerService.getAllPrayer(province).subscribe((data: any) => {
      this.socialList = [];
      this.socialList = data['result'].list;
    }, (err) => {
      this.notify.showError(err)
    });
  }
  getAllVideos() {
    this.resourceService.getAllResources(this.videoUrl,this.pageCount).subscribe((data: any) => {
      this.totalVideos = data['result'].info.totalItems;
    }, (err) => {
      this.notify.showError(err)
    });
  }
  getAllAudio(){
    this.resourceService.getAllResources(this.audioUrl,this.pageCount).subscribe((data: any) => {
      this.totalAudio = data['result'].info.totalItems;

    }, (err) => {
      this.notify.showError(err)
    });
  }
  getAllPdf(){
    this.resourceService.getAllResources(this.pdfUrl,this.pageCount).subscribe((data: any) => {
      this.totalPdf = data['result'].info.totalItems;
    }, (err) => {
      this.notify.showError(err)
    });
  }
  getAllBooks(){
    this.resourceService.getAllResources(this.bookUrl,this.pageCount).subscribe((data: any) => {
      this.totalBooks = data['result'].info.totalItems;
    }, (err) => {
      this.notify.showError(err)
    });
  }
  link(id: any) {
    this.router.navigate(['/newsdetails', id])
  }
  loadAP(){
    this.provincePdfUrl= Object.keys(this.pdfData).includes(this.province)?this.getKeyByValue(this.pdfData,this.province): 'https://riseupcanada.com/wp-content/uploads/2019/05/Church-Cults.pdf';
    window.open(this.provincePdfUrl);
  }
  getKeyByValue(object, value) {
    return Object.values(object).find(key => object[value] === key);
  }
  ngOnInit() {

    $(this).on('click', '.pro-exp-close ', function () {
      $('.pro-expo-arrow ').remove();
    });

    localStorage.setItem('show', '0');
    this.storageService.watchStorage().subscribe(value => {
      this.show = localStorage.getItem('show') == '1' ? true : false;
      this.province_code = JSON.parse(localStorage.getItem('selectedRegion'));
      this.division_name = JSON.parse(localStorage.getItem('selectedRegionName'));
      this.province = localStorage.getItem('selectedRegionName') == null ? null : localStorage.getItem('selectedRegionName').includes('Qu') ? "Quebec" : localStorage.getItem('selectedRegionName').replace(/"/g, "").replace(/ /g, "_");
      this.getEvents(this.province);
      this.getChristianLeader();
      this.getPrayer(this.province);
      this.getPrayers(this.province);
      this.getTrendingData();
      this.getSocialPrayer(this.province);
      this.getNews(this.province);
    });

    this.getList(this.status,this.currentPage);
    this.getAllVideos();
   this.getAllAudio();
   this.getAllBooks();
   this.getAllPdf();
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        document.querySelector('#' + fragment).scrollIntoView();
      }
    });

  }

  show_mapSlideout() {
    this.show = false;
    setTimeout(function () {
      $('.tr-issues').show().bxSlider({
        auto: true,
        mode: 'fade',
        autoHover: true,
        infiniteLoop: true,
        speed: 800,
        pause: 3000
      });
    }, 2000);
  }

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    autoplay: true,
    autoplaySpeed: 1500,
    dots: true,
  };
}



