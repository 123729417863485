import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public storage: BehaviorSubject<any>;
  constructor() { 
    this.storage = new BehaviorSubject<any>(JSON.stringify(localStorage.getItem('selectedRegionName')));
     var that = this;
    var originalSetItem = localStorage.setItem;
    localStorage.setItem = function(key, value) {
      originalSetItem.apply(this, arguments);
 if(key == "selectedRegionName"){
  that.storage.next(value);
 }
    };
}
watchStorage(): Observable<any> {
  return this.storage.asObservable();
}

}
