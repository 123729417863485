<div *ngIf="checkSection">
    <div class="row mar-zero">
        <div class="col s12 pad-zero">
            <div class="static-topleft">
                <app-headerlogo></app-headerlogo>
                <app-provincemap></app-provincemap>
                <!-- <app-logo-layout></app-logo-layout> -->
            </div>
            <div class="static-topright">
                <app-innerbanner [bannerDetails]="bannerDetails" [title]="pagetitle"></app-innerbanner>
            </div>
        </div>
    </div>
    <div class="container-outer" *ngIf="checkSection">
        <!--About Riseup Nation-->
        <div class="static-section-panel">
            <div class="static-section-lpanel">
                <div class="row mar-zero">
                    <div class="col s12 pad-zero">
                        <div class="left">
                            <span class="static-section-ltitle">{{pageData.section1title}}</span>
                        </div>
                        <div class="right">
                            <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="static-section-rpanel">
                <div class="floating_image">
                    <img class="responsive-img" id="aboutimage" src="{{pageData.section1image}}">
                </div>
                <div class="pad-l-zero">
                    <div class="">
                        <div class="static-bible-quote" id="biblecontent">{{pageData.bannerquote2}}</div>
                        <div class="static-bible-quote-num" id="biblequote">{{pageData.bannerversus2}}</div>
                    </div>
                    <div class="" id="content2">
                        <p class="txt-14 clr-888" [innerHTML]="description">{{description}}</p>
                    </div>
                </div>

                <!--a class="waves-effect btn-lg" onclick="loadMenu('registration')">Become a Pray Partner</a-->
            </div>
        </div>
        <!--About Riseup Nation-->

        <!--How we support our Nation-->
        <div class="static-section-panel">
            <div class="static-section-lpanel">
                <div class="row mar-zero">
                    <div class="col s12 pad-zero">
                        <div class="left">
                            <span class="static-section-ltitle">{{pageData.section2title}}</span>
                        </div>
                        <div class="right">
                            <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="static-section-rpanel-card add-block-box p-0">
                <div class="row mar-zero about-list">
                    <div class="col s12 m6 l3 pad-zero hoverable" routerLink="/province">
                        <div class="pray-trendcol h-auto">
                            <div class="praytrend-image">
                                <img src="{{pageData.section2image1}}" class="responsive-img" alt="">
                            </div>
                            <p class="mar-b-zero pray-nation-tle">{{pageData.section2image1title}}</p>
                        </div>
                    </div>
                    <div class="col s12 m6 l3 pad-zero hoverable" routerLink="/individual">
                        <div class="pray-trendcol">
                            <div class="praytrend-image">
                                <img src="{{pageData.section2image2}}" class="responsive-img" alt="">
                            </div>
                            <p class="mar-b-zero pray-nation-tle">{{pageData.section2image2title}}</p>
                        </div>
                    </div>
                    <div class="col s12 m6 l3 pad-zero hoverable" routerLink="/prayerresource">
                        <div class="pray-trendcol">
                            <div class="praytrend-image">
                                <img src="{{pageData.section2image3}}" class="responsive-img" alt="">
                            </div>
                            <p class="mar-b-zero pray-nation-tle">{{pageData.section2image3title}}</p>
                        </div>
                    </div>
                    <div class="col s12 m6 l3 pad-zero hoverable" routerLink="/events">
                        <div class="pray-trendcol">
                            <div class="praytrend-image">
                                <img src="{{pageData.section2image4}}" class="responsive-img" alt="">
                            </div>
                            <p class="mar-b-zero pray-nation-tle">{{pageData.section2image4title}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--How we support our Nation-->
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>
<!-- <app-footerprayerpoints *ngIf="checkSection" [details]="sectionDetails"></app-footerprayerpoints> -->
