<div class="row mar-zero"  *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <app-headerlogo></app-headerlogo>
            <!-- <div class="row mar-zero">
             <div class="spacer-sm"></div>
             <div class="col s12 pad-zero center-align">
                <img src="" id="pbprovince_image" alt="" title="">
             </div>
             <div class="col s12 pad-zero center-align">
                <div class="static-left-innerttl">
                   <span class="static-left-innerttl-1" id="pbprovince_label">Pray for<br>province of</span><br>
                   <span class="static-left-innerttl-2" id="pbprovince_name"></span><br>
                   <span class="static-left-innerttl-3" id="pbcity">Sherbrooke</span>
                </div>
             </div>
          </div> -->
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <app-innerbanner [bannerDetails]="bannerDetails" [title]="pagetitle"></app-innerbanner>
        </div>
    </div>
</div>
<div class="container-outer"  *ngIf="checkSection">
    <!--About Riseup Nation-->
    <div class="static-section-panel">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">{{pageData.section1title}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="static-section-rpanel-card add-block-box mobile-left">
            <div class="row mar-zero">
                <div class="floating_image">
                    <img class="responsive-img" id="aboutimage" src="{{pageData.section1image}}">
                </div>
                <div class="pad-l-zero">
                    <div class="">
                        <div class="static-bible-quote" id="biblecontent">
                            {{pageData.section1biblequote}}
                        </div>
                        <div class="static-bible-quote-num" id="biblequote">
                            {{pageData.section1bibleversus}}
                        </div>
                    </div>
                    <p class="txt-14 mar-t-zero clr-888" id="content1" [innerHTML]="description">
                        {{description}}
                    </p>
                </div>
              
            </div>
            <!-- <div class="row mar-zero">
                <div class="col s12 pad-zero" id="content2">
                    <div class="txt-14 clr-888">
                        <p>Once you are registered as a prayer partner, we will work with you and will pray for your personal prayer needs.</p>
                    </div>
                    <div class="txt-14 clr-888">
                        <p>When you enroll in our warfare prayer partner program, you will receive sensitive information to pray for the issues in your areas.</p>
                    </div>
                    <div class="txt-14 clr-888">
                        <p>We will send you free resources for your personal development when you provide us with your address or email address. </p>
                    </div>
                    <div class="txt-14 clr-888">
                        <p>You will be given preferred seating at meeting organized by Rise Up Canada in your province.</p>
                    </div>
                    <div class="txt-14 clr-888">
                        <p>You will be informed any special meetings, concerts and other events that takes place in your areas. Also, lucky winners will be given free tickets to attend any paid seminars or events. </p>
                    </div>
                    <div class="txt-14 clr-888">
                        <p>Once a year we will be having a draw among the prayer partners and the lucky winner will receive air ticket to travel to a destination in South America.</p>
                    </div>
                    <div class="txt-14 clr-888">
                        <p>Summer and winter, we will be having a draw among the prayer partners and the lucky winner will receive a fully paid ticket to attend any seminars conducted in USA or Canada. </p>
                    </div>

                </div>
            </div> -->
   
            <app-primarybutton *ngIf="!isLogin"  buttonName=" {{pageData.section1button}}" routerLink="/individual"></app-primarybutton>
       
            <!-- <a class="waves-effect btn-lg" onclick="loadMenu('registration')">Become a Pray Partner</a> -->
        </div>
    </div>
</div>
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>
<!--About Riseup Nation-->