import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PartnerorgService {

  constructor(private http: HttpClient) { }
  getAllLists(currentPage: number) {
   
    let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    });
    return this.http.get<any>(`${environment.apiUrl}/partnering-approved-list?page=` + `${currentPage}`, { headers: headers });
 }
 addPartner(data:any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });
    let body = data;
    return this.http.post<any>(`${environment.apiUrl}/partnering-org-create`,body,
      {
        headers: headers,
        observe: 'response',
      });
  }
 
}
