import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdfs',
  templateUrl: './pdfs.component.html',
  styleUrls: ['./pdfs.component.css']
})
export class PdfsComponent implements OnInit {
  @Input() pdfList:any
  data: any;
  checkSection: boolean = false;
  constructor() { }
  provinceevents = [
    {
      'pdfUrl': "",
      'title':"",
    }
  ]
  
  ngOnInit(): void {

  }

}
