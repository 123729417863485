import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/services/common.service';
import { NotificationService } from 'src/services/notification.service';

declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-christianleader',
  templateUrl: './christianleader.component.html',
  styleUrls: ['./christianleader.component.css']
})
export class ChristianleaderComponent implements OnInit {
  id: any;
  isLoad: boolean = false;
  disabled: boolean = true;
  @Input() leaderList: any = [];
  hostipAddress: string = '';
  datas: object;
  constructor(private amenService: CommonService, public notify: NotificationService) {


  }


  addAmen(data, i) {
    var uniqueData = JSON.parse(localStorage.getItem('uuid'));
    this.id = i;

    var pushId;

    if (localStorage.getItem('session') === null) {
      pushId = [];
    } else {

      pushId = JSON.parse(localStorage.getItem('session'));
    }
    pushId.push(data.id);
    localStorage.setItem('session', JSON.stringify(pushId));


    if (uniqueData === null) {
      this.datas = {
        'postRef': data.id,
      }
    }
    else {
      this.datas = {
        'postRef': data.id,
        'uuid': uniqueData
      }
    }


    this.isLoad = true;

    this.amenService.addAmen(this.datas).subscribe((response: any) => {
      if (response.status == 200) {
        this.isLoad = false;
        this.hostipAddress = response.body.hostIp;
        data.disabled = true;
        localStorage.setItem('uuid', JSON.stringify(this.hostipAddress));
        data.amenCount++;
        this.notify.showSuccess('Amen Count Added')
      }
    }, (err) => {
      this.isLoad = false;
      this.notify.showError(err.error.message)
    })

  }

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 4,
    autoplay: true,
    autoplaySpeed: 1500,
    dots: true,
    // infinite:false,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToScroll: 3,
          arrows: false,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 660,
        settings: {
          arrows: false,
          slidesToScroll: 2,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToScroll: 1,
          slidesToShow: 1
        }
      }
    ]
  };
  ngOnInit(): void {
    var idStore = JSON.parse(localStorage.getItem('session'));
    this.leaderList.forEach((item) => {
      if (idStore.indexOf(item.id) > -1) {

        item.disabled = true
      }
      else {
        item.disabled = false

      }
    })
  }
  // ngAfterViewInit() {
  //   setTimeout(function () {
  //     jQuery(".owl-carousel").owlCarousel({
  //       items: 4,
  //       singleItem: true,
  //       itemsScaleUp: true,
  //       slideSpeed: 500,
  //       autoPlay: 5000,
  //       stopOnHover: true,
  //       autoplay: true,
  //       nav: false,
  //       dots: true,
  //       responsiveClass: true,
  //       // afterInit: setWidth(),
  //       responsive: {
  //         575: {
  //           items: 1,
  //         },
  //         1140: {
  //           items: 4,
  //         },
  //         2000: {
  //           items: 4,
  //         }
  //       }
  //     });

  //     this.owlElement.refresh()
  //     $('.owl-carousel').trigger('refresh.owl.carousel');
  //     var selector = $('.owl-carousel');
  //     $('.owl-cus-next').click(function () {
  //       selector.trigger('next.owl.carousel');
  //     });

  //     $('.owl-cus-prev').click(function () {
  //       selector.trigger('prev.owl.carousel');
  //     });
  //   }, 10);

  // }
  // leadersSlider() {


  // }
}
