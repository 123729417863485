import { Component, Input, OnInit } from '@angular/core';
import { PageService } from 'src/services/page.service';
import { PrayerresourceService } from 'src/services/prayerresource.service';

@Component({
  selector: 'app-videodetails',
  templateUrl: './videodetails.component.html',
  styleUrls: ['./videodetails.component.css']
})
export class VideodetailsComponent implements OnInit {
  bannertitle = "Videos";
  bannerDetailsTwo: any;
  pageDataTwo:any;
  pageData:any;
  pagetitle:any;
  template:any;
  checkSection:boolean=false;
  pageCount:number=0;
  bannerDetails: any;
  biblequotes: any;
  checkRes:boolean=false;
  listVideos:boolean=false;
  videoUrl:string="Videos"
  noOfVideos:any;
  totalPages: any;
  videoList:any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  constructor(private pageservice:PageService,private resourceService:PrayerresourceService) {
    this.pageservice.getPage('61af13cbb1c59e2f8422195b').subscribe(res => {
      this.pageData = res.result.templateData;
      this.pagetitle=res.result.title;
      this.bannerDetails = [{ bibleQuotes: this.pageData.bannerquote, verses: this.pageData.bannerversus, file: this.pageData.bannerimage ? this.pageData.bannerimage : '../../../assets/images/about-bg.jpg' }]
      this.checkSection = true;
    })
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res =>{
      this.pageDataTwo=res.result.templateData;
      this.bannerDetailsTwo = {slidertitle1:this.pageDataTwo.riseupcanada,slidertitle2:this.pageDataTwo.prayforournation,sliders:this.pageDataTwo.banner}
      this.sectionDetails = {title:this.pageDataTwo.section2title,description:this.pageDataTwo.section2description,audio:this.pageDataTwo.section2audio,pdf:this.pageDataTwo.section2pdf}
      this.checkSection=true;
    })
   }
  videodetailpage = [
    {
      title: "Videos",
      description: "",
      slug: "",
      image: "",
      htmlContent: "",
      template: "",
      template_field: [{
        "banner": [
          {
            "bibleQuotes": "So I sought for a man among them who would make a wall, and stand in the gap before Me on behalf of the land (Canada), that I should not destroy it; but I found no one.",
            "verses": "EZE 22:30",
            "file": "../../../assets/images/about-bg.jpg",
          },
         
        ],
       
      }]
    }
  ]
  getAllVideos() {
    this.resourceService.getAllResources(this.videoUrl,this.pageCount).subscribe((data: any) => {
      this.checkRes = true;
      this.videoList =data.result.resource;
      this.totalPages = data['result'].info.totalPages;
      // this.totalItems = data['result'].info.totalItems;
    });
  }
  loadMore() {
    this.pageCount= this.pageCount + 1;
    if (this.totalPages > this.pageCount) {
      this.resourceService.getAllResources(this.videoUrl,this.pageCount).subscribe((data: any) => {
        var list = data.result.resource;
        list.map((o) => {
          this.videoList.push(o);
        });
        this.listVideos = this.totalPages > this.pageCount + 1 ? true : false;
        this.checkRes = true;
      });
    } else {
      this.listVideos = false;
    }
  }
  ngOnInit(): void {
    this.getAllVideos();
  }

}
