import { Component, OnInit } from '@angular/core';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-sidebarmap',
  templateUrl: './sidebarmap.component.html',
  styleUrls: ['./sidebarmap.component.css']
})
export class SidebarmapComponent implements OnInit {

  constructor(private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document) {
   }

  ngOnInit(){
    const s = this.renderer2.createElement('script');
   s.type = 'text/javascript';
   s.src = '../assets/canadamap/map_method.js';
   s.text = ``;
   this.renderer2.appendChild(this._document.body, s);
  }

}
