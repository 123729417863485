
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'striphtml'
})

export class StripHtmlPipe implements PipeTransform {
    transform(value: string): any {
         var text = value.replace(/<.*?>/g, ''); // replace tags

         var sanitisedData = text.replace(/[^\w\s]/gi, '')
         return sanitisedData;
    }
}
