<div class="row mar-zero" *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <app-headerlogo></app-headerlogo>
            <!-- <div class="row mar-zero">
                <div class="spacer-sm"></div>
                <div class="col s12 pad-zero center-align">
                    <img src="assets\images\province\{{province_code +'.png'}}" alt="" title="" id="prayers_province_image">
                </div>
                <div class="col s12 pad-zero center-align">
                    <div class="static-left-innerttl">
                        <span class="static-left-innerttl-4" id="prayers_province_mlabel">province of</span><br>
                        <span class="static-left-innerttl-5" id="prayers_province_mname">{{state_name}}</span><br>
                        <span class="static-left-innerttl-3" id="prayers_province_city"></span>
                    </div>
                </div>
            </div> -->
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright" *ngIf="checkSection">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="prayers-panel" id="prayers-panel" style="background: #653935 url({{pageData?.bannerimage}}) center no-repeat;background-size: cover;">
                        <div class="prayers-panel-content">
                            <div class="prayres-cont-lht">
                                <div class="prayers-cont-1" id="prayers_province_label">{{pageData?.bannertitle}}</div>
                                <div class="prayers-cont-2" id="prayers_province_name">{{division_name}}</div>
                            </div>
                        </div>
                        <div class="prayers-slide-title">
                            <app-breakingnews [newslist]="newslistCopy"></app-breakingnews>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="prayers-block-panel p-0" *ngIf="checkSection">
    <div class="prayers-block-topleft">
        <div class="row mar-zero">
            <div class="col s12 pad-zero center-align">
                <span class="prayer-lp-arw"><i class="mdi mdi-arrow-down"></i></span>
            </div>
            <div class="col s12 pad-zero center-align">
                <p class="prayers-block-contop">
                </p>
            </div>
            <div class="col s12 pad-zero center-align">
                <div class="prayer-proexpo-box waves-effect" (click)="loadAP()">
                    <span class="proexpobox-tle1">{{pageData?.abouttitle}}</span><br>
                    <!-- <span class="proexpobox-tle2" id="proexpobox-tle2">province</span> -->
                </div>
            </div>
            <div class="col s12 pad-zero center-align">
                <div class="prayer-proexpo-box waves-effect modal-trigger" id="trigger-source" routerLink="/">
                    <span class="proexpobox-tle1">Change Province </span><br>
                    <!-- <span class="proexpobox-tle2" id="proexpobox-tle2">province</span> -->
                </div>
            </div>
        </div>
    </div>
    <div class="prayers-block-topright short-newsdec" id="province_news">
        <div class="row mar-zero">
            <app-newslist *ngIf="checkRes" [newslist]="newslistCopy"></app-newslist>

        </div>
    </div>
</div>

<!---Prayer Point-->
<div class="prayer-point-container prayer-point-width-adjust">
    <app-footerprayerpoints *ngIf="currentPageName === 'Province'" [details]="sectionDetails"></app-footerprayerpoints>
</div>

  <!--sensitive prayers-->
<div class="prayers-section-panel" id="sprayer_list" *ngIf="isLogin">
    <div class="static-section-lpanel">
        <div class="row mar-zero">
            <div class="col s12 pad-zero">
                <div class="left">
                    <span class="prayers-section-ltitle">{{pageData?.section2title}}</span><br>
                    <span class="prayers-section-ltitle-sub2" id="prayers-section-ltitle-sub2">{{pageData?.inyourprovince}}</span>
                </div>
                <div class="right">
                    <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                </div>
            </div>
        </div>
        <div class="event-ldmr-panel utility-position-initial">
            <div class="prayers-lbox-count-list">
                <p class="pray-count-num" id="senprayers_count">{{sensitivePrayerCount}}</p>
                <p class="mar-zero pray-count-type st_text">
                    <span class="pray-count-nam">{{pageData?.sensitivetitle}}</span><br>
                    <!-- <span class="pray-count-pttxt">{{pageData.prayerpointstitle}}</span> -->
                </p>
            </div>
            <a class="waves-effect btn-lg" routerLink="/Strongholds">{{pageData?.section3button}}</a>
        </div>
    </div>
    <div class="prayers-section-rpanel" id="sensitive_players">
        <app-sensitivelist *ngIf="checkRes" [prayerList]="prayerList">
        </app-sensitivelist>
    </div>
</div>
 <!--sensitive prayers-->
<div class="container-outer" *ngIf="checkSection">
    <!--social prayers-->
    <div class="prayers-section-panel">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="prayers-section-ltitle">{{pageData?.section1title}}</span><br>
                        <span class="prayers-section-ltitle-sub2" id="prayers-section-ltitle-sub3">{{pageData?.inyourprovince}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <div class="event-ldmr-panel">
                <div class="prayers-lbox-count-list">
                    <p class="pray-count-num" id="socprayers_count">{{socialPrayerCount}}</p>
                    <p class="mar-zero pray-count-type">
                        <span class="pray-count-nam">{{pageData?.socialtitle}}</span><br>
                        <span class="pray-count-pttxt">{{pageData?.prayerpointstitle}}</span>
                    </p>
                </div>
                <app-primarybutton buttonName="{{pageData?.section3button}}" routerLink="/socialprayer">
                </app-primarybutton>
                <!-- <a class="waves-effect btn-lg" href="#social-prayer-viewmore" onclick="loadMenu('social-prayer-viewmore')">View prayer wall</a> -->
            </div>
        </div>
        <div class="prayers-section-rpanel" id="social_players">
            <div class="row mar-zero">
                <app-socialprayerlist *ngIf="checkRes" [socialprayerList]="socialprayerList"></app-socialprayerlist>
            </div>
        </div>
    </div>
    <!--social prayers end-->
    <!--Sensitive prayer banner-->
    <div class="sensitive-prayer-wallbanner" id="sprayer_wall" style="background: url({{pageData?.senstiveprayerimage}}) no-repeat;
    background-size: cover;" *ngIf="!isLogin">
        <div class="row mar-zero">
            <div class="col s12 m8 offset-l3 center-margin l6 center-align">
                <div class="sensitive-prayer-block">
                    <p class="sensitive-pray-lht">
                        <span class="sensitive-pray-tle1">{{pageData?.senstiveprayertitle}}</span><br>
                        <span class="sensitive-pray-tle2" id="sensitive-pray-tle2">{{pageData?.inyourprovince}}</span>
                    </p>
                    <p class="sensitive-pray-cont-lht">{{pageData?.senstiveprayerdescription}}</p>
                    <p><a class="senst-pray-btn waves-effect waves-light" href="" [routerLink]='[isLogin ? "/strongholds" : "/prayerwarrior"]'>{{isLogin ? 'View Sensitive
                            Prayers':pageData?.senstiveprayerbutton}}</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!--Sensitive prayer banner-->
    <!--Personal prayer-->
    <!-- <div class="prayers-section-panel">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="prayers-section-ltitle">{{pageData.section3title}}</span><br>
                        <span class="prayers-section-ltitle-sub" id="prayers-section-ltitle-sub1">{{pageData.inyourprovince}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <div class="event-ldmr-panel">
                <div class="prayers-lbox-count-list">
                    <p class="pray-count-num" id="pprayers_count">{{personalPrayerCount}}</p>
                    <p class="mar-zero pray-count-type">
                        <span class="pray-count-nam">{{pageData.personaltitle}}</span><br>
                        <span class="pray-count-pttxt">{{pageData.prayerpointstitle}}</span>
                    </p>
                </div>
                <app-primarybutton buttonName="{{pageData.section3button}}" routerLink="/personalprayer">
                </app-primarybutton>
            </div>
        </div>
        <div class="prayers-section-rpanel">
            <div class="row mar-zero">
                <div class="col s12 m6 l6 pad-zero">
                    <form [formGroup]="provinceForm">
                        <div class="row mar-zero">
                            <div class="col s12 pad-zero">
                                <div class="pers-prayers-lcard">
                                    <div class="pers-pray-formbox">
                                        <div class="row mar-zero">
                                            <div class="col s12 pad-zero">
                                                <input type="text" class="pers-prayer-fld" id="name" placeholder="Name" formControlName="name">
                                                <div class="alert alert-danger" *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                    <div class="validation-errors" *ngIf="f.name.errors.required">
                                                        *Name is required</div>
                                                </div>
                                            </div>
                                            <div class="col s12 pad-zero p-prayer-form">
                                                <select id="Category" class="dropdownName" formControlName="prayerCat" (change)="getValue($event)">
                                                    <option value="" hidden selected>Select a Category</option>
                                                    <optgroup>
                                                        <option style="color: #fff;" [value]="list.id"
                                                            *ngFor="let list of category">
                                                            {{ list.title}}
                                                        </option>
                                                    </optgroup>

                                                </select>
                                                <div class="alert alert-danger" *ngIf="submitted && f.prayerCat.errors" class="invalid-feedback">
                                                    <div class="validation-errors" *ngIf="f.prayerCat.errors.required">
                                                        *Category is required</div>
                                                </div>
                                            </div>

                                            <div class="col s12 pad-zero mt-top">
                                                <select *ngIf="showSubCatagory" id="subcategory" class="dropdownName" formControlName="prayerSubcat">
                                                    <option value="" hidden selected>Select a Sub Category</option>
                                                    <optgroup>
                                                        <option style="color: #fff;" [value]="list.id"
                                                            *ngFor="let list of subcategory">
                                                            {{list.title}}
                                                        </option>
                                                    </optgroup>
                                                </select>
                                                <div class="alert alert-danger" *ngIf="submitted && f.prayerSubcat.errors" class="invalid-feedback">
                                                    <div class="validation-errors" *ngIf="f.prayerSubcat.errors.required">
                                                        *sub Category is required</div>
                                                </div>
                                            </div>
                                            <div class="col s12 pad-zero  mt-top">
                                                <textarea maxlength="50" class="pers-prayer-fld" id="prayer_content" #myInput [maxlength]="maxChars" formControlName="prayer" placeholder="Add your prayer"></textarea>
                                                <br />
                                                <label class="characterCount">
                                                    Characters Remaining: {{myInput.value.length}} /{{maxChars}}</label>
                                                <div class="alert alert-danger" *ngIf="submitted && f.prayer.errors" class="invalid-feedback">
                                                    <div class="validation-errors" *ngIf="f.prayer.errors.required">
                                                        *Prayer is required</div>
                                                </div>
                                            </div>
                                            <div class="col s12 pad-zero left-align mt-15">
                                                <button class="waves-effect btn-sm" (click)="submitPrayer()">{{pageData.formbutton}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col s12 m6 l6 pad-zero p-prayer border-last pp-prayer">
                    <app-personalprayerlist [approvedList]="approvedList" [prayerPdfUrl]="prayerPdfUrl">
                    </app-personalprayerlist>
                </div>
            </div>
        </div>
    </div> -->
    <!--Personal prayer-->
</div>
<!--Pray for lead-->
<div class="prayer-leader-panel" *ngIf="checkSection">
    <div class="pray-lead-overlay"></div>
    <div class="container-outer">
        <div class="pray-lead-title">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="prayer-leader-panel-txt clr-white">{{pageData?.section4title}}</span><br>
                        <span class="prayer-leader-panel-txt-sub" id="prayer-leader-panel-txt-sub">{{pageData?.inyourprovince}}</span>
                    </div>
                    <div class="right">
                        <span class="province-arw clr-white"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <div class="event-ldmr-panel">
                <!-- <app-primarybutton buttonName="View prayer leaders" routerLink="/prayforleaders"></app-primarybutton> -->
                <a class="waves-effect pray-lead-btn" href="" routerLink="/prayforleaders">{{pageData?.section4button}}</a>
            </div>
        </div>
        <div class="pray-lead-content" id="province_leaders">
            <div class="row mar-zero">
                <app-leadersprofile [leaderList]="leaderList"></app-leadersprofile>
            </div>
        </div>
    </div>
</div>
<!--Pray for lead-->
<div class="container-outer" id="post_a_prayer" *ngIf="checkSection">
    <!--prayer upcoming events-->
    <div class="prayers-section-panel">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="prayers-section-ltitle">{{pageData?.section5title}}</span><br>
                        <span class="prayers-section-ltitle-sub" id="prayers-section-ltitle-sub">{{pageData?.inyourprovince}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <div class="event-ldmr-panel">
                <a class="waves-effect btn-lg event-btnbtm" href="" routerLink="/events">{{pageData?.section5button}}</a>
            </div>
        </div>
        <div class="prayers-section-rpanel" id="prayerEvents">
            <div class="row mar-zero">
                <app-eventlist *ngIf="checkRes" [eventList]="eventListCopy"></app-eventlist>
                <!--   <div class="col s12 m6 l3 pad-zero hoverable">
                    <div class="pray-trendcol card-sdw-btm">
                        <div class="praytrend-image">
                            <img src="../../../assets/images/event-ad-banner.jpg" class="responsive-img" alt="">
                        </div>
                        <p class="mar-zero txt-13 clr-777">Seminars, Special Events, Public Events anything could be
                            advertised. We can inform about these meetings in our news letter.
                        </p>
                        <p class="adbanner-contact">
                            <span class="adbanner-txt-1">For further details contact</span><br>
                            <span class="adbanner-txt-2">admin@riseupcanada.ca</span>
                        </p>
                    </div>
                </div>
                <div class="col s12 m6 l3 pad-zero hoverable">
                    <div class="pray-trendcol card-sdw-btm">
                        <div class="praytrend-image">
                            <img src="../../../assets/images/event-ad-banner.jpg" class="responsive-img" alt="">
                        </div>
                        <p class="mar-zero txt-13 clr-777">Seminars, Special Events, Public Events anything could be
                            advertised. We can inform about these meetings in our news letter.
                        </p>
                        <p class="adbanner-contact">
                            <span class="adbanner-txt-1">For further details contact</span><br>
                            <span class="adbanner-txt-2">admin@riseupcanada.ca</span>
                        </p>
                    </div>
                </div>
                <div class="col s12 m6 l3 pad-zero hoverable">
                    <div class="pray-trendcol card-sdw-btm">
                        <div class="praytrend-image">
                            <img src="../../../assets/images/event-ad-banner.jpg" class="responsive-img" alt="">
                        </div>
                        <p class="mar-zero txt-13 clr-777">Seminars, Special Events, Public Events anything could be
                            advertised. We can inform about these meetings in our news letter.
                        </p>
                        <p class="adbanner-contact">
                            <span class="adbanner-txt-1">For further details contact</span><br>
                            <span class="adbanner-txt-2">admin@riseupcanada.ca</span>
                        </p>
                    </div>
                </div>
                <div class="col s12 m6 l3 pad-zero hoverable">
                    <div class="pray-trendcol card-sdw-btm">
                        <div class="praytrend-image">
                            <img src="../../../assets/images/event-ad-banner.jpg" class="responsive-img" alt="">
                        </div>
                        <p class="mar-zero txt-13 clr-777">Seminars, Special Events, Public Events anything could be
                            advertised. We can inform about these meetings in our news letter.
                        </p>
                        <p class="adbanner-contact">
                            <span class="adbanner-txt-1">For further details contact</span><br>
                            <span class="adbanner-txt-2">admin@riseupcanada.ca</span>
                        </p>
                    </div>
                </div>-->
            </div>
        </div>
    </div>
    <!--prayer upcoming events-->
</div>
<!-- <app-chooseprovince></app-chooseprovince> -->
<app-prayfornation *ngIf="checkSection" [currentPageName]="currentPageName" [banner]="bannerDetailsTwo"></app-prayfornation>
