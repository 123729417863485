import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Button } from 'selenium-webdriver';
import { PageService } from 'src/services/page.service';
import { PrayerPointsService } from 'src/services/prayerpoints.service';
declare var $: any;
@Component({
  selector: 'app-prayerselectbox',
  templateUrl: './prayerselectbox.component.html',
  styleUrls: ['./prayerselectbox.component.css']
})
export class PrayerselectboxComponent implements OnInit {
  @Input() details: any;
  prayerlist: any = [];
  Province: any;
  category: any;
  button:any;
  data: any;
  audiourl: string = '';
  id: any;
  x: any;
  pdfurl: string = '';
  isDisabled:boolean=false;
  visible:boolean=false;
  pageData: any;
  pagetitle: any;
  bannerDetails: any;
  biblequotes: any;
  checkSection: boolean = false;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  constructor(private prayerpointsService: PrayerPointsService, private http: HttpClient, private pageservice: PageService) {
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res => {
      this.pageData = res.result.templateData;
      this.checkSection = true;
    })
   }
 
  ngOnInit(): void {
    this.footmyselectbox();
    this.getPrayer();
  }
  
  getValue(list) {
  
    this.id = list;
    this.category = this.x.filter((o: any) => { return this.id === o.parent ? ({ ...o }) : '' });

  }
  getPrayer() {
    
    this.prayerpointsService.getPrayerList().subscribe((data: any) => {
      this.visible=true;
      this.data =data['result'].list;
      this.x = data['result'].list;
      var a = data['result'].list.map(o => o.id)
      this.Province = this.x.filter(o => o.parent === '');
    });
  }

  getPrayers(list) {
    if (list) {
      this.audiourl = list.audio_url;
      this.pdfurl = list.pdf_url;
    }
  }
  viewAudio() {
    window.open(this.audiourl);
  }

  viewPdf() {
    window.open(this.pdfurl);
  }

  footmyselectbox() {
    /*Dropdown Menu*/
    $('.dropdown-01').click(function () {
      $(this).attr('tabindex', 1).focus();
      $(this).toggleClass('active');
      $(this).find('.dropdown-menu').slideToggle(500);
    });
    $('.dropdown-01').focusout(function () {
      $(this).removeClass('active');
      $(this).find('.dropdown-menu').slideUp(500);
    });
    $('.dropdown-01 .dropdown-menu li').click(function () {
      $(this).parents('.dropdown-01').find('span').text($(this).text());
      $(this).parents('.dropdown-01').find('input').attr('value', $(this).attr('id'));
    });
  }

  category_name: string = null;
  category_get(c_name) {
    this.category_name = c_name;
    // this.subcategory_name='';
  }

  subcategory_name: string;
  subcategory_get(sc_name) {
    this.subcategory_name = sc_name;
  }

}
