import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotificationService } from 'src/services/notification.service';
import { CanadaPostalCode } from 'src/services/post-canade.service';
import { SensitivePrayerService } from 'src/services/sensitiveprayer.service';
import { StorageService } from 'src/services/storage.service';
declare var $:any;

@Component({
  selector: 'app-strongholdsfilter',
  templateUrl: './strongholdsfilter.component.html',
  styleUrls: ['./strongholdsfilter.component.css']
})
export class StrongholdsfilterComponent implements OnInit {
  prayerList: any = [];
  createForm: FormGroup;
  search: string = '';
  selectId: string = '';
  autoCompletArr: any = [];
  selectedProvince:string='';
  disableBtn:string=''
  filterData = { province: null, city: null, pincode: null,category:null }
  @Output() filterSensitveData = new EventEmitter;
  openCountryList:boolean=false;
  constructor(private prayerService: SensitivePrayerService,private canadaPostService: CanadaPostalCode,public storageService:StorageService,private notify: NotificationService,private formBuilder: FormBuilder) {
    this.createForm = this.formBuilder.group({

      pincode: [''],

      province: [''],
      city: [''],
      category:['']

    });
   }
  getPrayer() {
    this.prayerService.getAllCategory().subscribe((data: any) => {
      this.prayerList = data['result']; 
    },(err) => {
      this.notify.showError(err)
    });
  }

  onSubmit(pin) {
    this.filterData.pincode = (pin.value !== '' ? pin.value : null);
    this.filterData.province=this.createForm.get('province')?.value.replace(/"/g, "").replace(/ /g, "_"),
    this.filterData.city=this.createForm.get('city')?.value?this.createForm.get('city')?.value.replace(/"/g, "").replace(/ /g, "_"):null,
    this.filterData.category=this.category
    this.filterSensitveData.emit(this.filterData);
  }
  
  autoComplete($event) {
    this.search = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
    this.selectId = '';
    this.autoCompleteService();
  }
  
  showList(){
    this.openCountryList=true;
  }

  autoCompleteService() {
    this.canadaPostService.autoComplete(this.search, this.selectId).subscribe(response => {
      this.autoCompletArr = [];
      this.autoCompletArr = response.Items;
    });
  }
  getRetriveAddress(){
    this.canadaPostService.retriveAddress(this.selectId).subscribe(response => {
      var item = response.Items[0];
       this.createForm.patchValue({
        city: item.City,
        province: item.ProvinceName === "Québec"? item.ProvinceName.replace("Québec","Quebec"): item.ProvinceName.replace(/"/g, "").replace(/ /g, "_") ,
        pincode: item.PostalCode.replace(/"/g, "").replace(/ /g, "-")
      });
      this.autoCompletArr = [];
    });
  }

  selectAddress(item) {
    this.openCountryList=true;
    this.selectId = item.Id;
    if (item.Next == 'Find') {
      this.autoCompleteService();
    } else {
      this.getRetriveAddress();
    }
  }
 
  ngOnInit(): void {
    this.myselectbox();
    this.getPrayer();
  }
  myselectbox() {
    /*Dropdown Menu*/
    $('.dropdown').click(function () {
      $(this).attr('tabindex', 1).focus();
      $(this).toggleClass('active');
      $(this).find('.dropdown-menu').slideToggle(500);
    });
    $('.dropdown').focusout(function () {
      $(this).removeClass('active');
      $(this).find('.dropdown-menu').slideUp(500);
    });
    $('.dropdown .dropdown-menu li').click(function () {
      $(this).parents('.dropdown .select').find('span').text($(this).text());
      $(this).parents('.dropdown .select').find('input').attr('value', $(this).attr('id'));
    });
    /*End Dropdown Menu*/
   
  }
  category: string = null;
  selectCategory(c_name) {
      this.category = c_name;
      // this.cityname='';
    } 
}
