import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/services/page.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  bannertitle = "About Us";
  pageData: any;
  pageDataTwo: any;
  template: any;
  pagetitle: any;
  checkSection: boolean = false;
  bannerDetails: any;
  bannerDetailsTwo: any
  biblequotes: any;
  description: any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  constructor(private pageservice: PageService) {
    this.pageservice.getPage('60d367c66860960941ed9148').subscribe(res => {
      this.pageData = res.result.templateData;
      this.pagetitle = res.result.title;
      this.description = res.result.description;
      this.bannerDetails = [{ bibleQuotes: this.pageData.bannerquote, verses: this.pageData.bannerversus, file: this.pageData.bannerimage ? this.pageData.bannerimage : '../../../assets/images/about-bg.jpg' }]
      this.biblequotes = [{ texthead: this.pageData.bannerquote2, quotesnum: this.pageData.bannerversus2, description: this.pageData.section1description, thumbnail: 'https://ruc-frontend-webapp-dev-knjyght5ka-uc.a.run.app/assets/images/about-us.jpg' }]
      this.checkSection = true;
    })
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res => {
      this.pageDataTwo = res.result.templateData;
      this.bannerDetailsTwo = { slidertitle1: this.pageDataTwo.riseupcanada, slidertitle2: this.pageDataTwo.prayforournation, sliders: this.pageDataTwo.banner }
      this.sectionDetails = { title: this.pageDataTwo.section2title, description: this.pageDataTwo.section2description, audio: this.pageDataTwo.section2audio, pdf: this.pageDataTwo.section2pdf }
      this.checkSection = true;
    })

  }

  texthead: any;
  ngOnInit(): void {


  }

}
