import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationService } from 'src/services/notification.service';
import { PageService } from 'src/services/page.service';
import { SocialPrayerService } from 'src/services/socialprayer.service';
import { StorageService } from 'src/services/storage.service';

@Component({
  selector: 'app-socialprayer',
  templateUrl: './socialprayer.component.html',
  styleUrls: ['./socialprayer.component.css']
})
export class SocialprayerComponent implements OnInit {
  bannertitle = "Social Prayer";
  pageData: any;
  pagetitle: any;
  template: any;
  listPrayer: boolean = false;
  checkSection: boolean = false;
  bannerDetails: any;
  biblequotes: any;
  socialPrayerCount: any = [];
  socialprayerList: any = [];
  trendingPrayerList:any =[];
  filteredSocialCount:any;
  isCount:boolean=false;
  prayer = [];
  count = 0;
  data: any;
  totalPages: any;
  @Output() newEvent = new EventEmitter;
  bannerDetailsTwo: any;
  pageDataTwo: any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  constructor(private pageservice: PageService, public storageService: StorageService, private prayerService: SocialPrayerService, private notify: NotificationService) {
    this.pageservice.getPage('60e3fe2d269f7e56e1f393f0').subscribe(res => {
      this.pageData = res.result.templateData;
      this.pagetitle = res.result.title;
      // this.bannerDetails = [{bibleQuotes:this.pageData.bannerquote,verses:"EZE 33:11",file:"azar"}]
      this.bannerDetails = [{ bibleQuotes: this.pageData.bannerquote, verses: this.pageData.bannerversus, file: this.pageData.bannerimage ? this.pageData.bannerimage : '../../../assets/images/about-bg.jpg' }]
      this.checkSection = true;
    },(err) => {
      this.notify.showError(err)
    });
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res => {
      this.pageDataTwo = res.result.templateData;
      this.bannerDetailsTwo = { slidertitle1: this.pageDataTwo.riseupcanada, slidertitle2: this.pageDataTwo.prayforournation, sliders: this.pageDataTwo.banner }
      this.sectionDetails = { title: this.pageData.section2title, description: this.pageData.section2description, audio: this.pageData.section2audio, pdf: this.pageData.section2pdf }
      this.checkSection = true;
    },(err) => {
      this.notify.showError(err)
    });

  }

  loadMore() {
    this.count = this.count + 1;
    if (this.count < this.totalPages) {
      var y = localStorage.getItem('selectedRegionName');
      var p = y.replace(/"/g, "");
      var z = p.replace(" ", "_");
      this.prayerService.getPage(z, this.count).subscribe((data: any) => {
        var x = data['result'].list;
        x.map(o => { this.socialprayerList.push(o) })
        this.listPrayer = this.totalPages > this.count + 1 ? true : false;


      });
    }
    else {

      this.listPrayer = false;

    }
  }
  getData(event: any) {

    this.prayerService.getFilteredData(event.province, event.city, event.pincode).subscribe((Res) => {
      this.isCount=true;
      this.filteredSocialCount=Res.result.info.totalItems;
      this.socialprayerList = Res['result'].list;
      this.prayerService.getmsg(this.socialprayerList);
    });
  }

  getPrayer() {
   
    var y = localStorage.getItem('selectedRegionName');
    var p = y.replace(/"/g, "")
    var z = p.replace(/ /g, "_");
    
    this.prayerService.getAllPrayer(z).subscribe((data: any) => {

      this.socialprayerList = data['result'].list;
      this.socialPrayerCount = data.result.info.totalItems;
      // x.map(o => { this.socialprayerList.push(o) })
      this.totalPages = data.result.info.totalPages;
      this.listPrayer = this.totalPages > 1 ? true : false;
    },(err) => {
      this.notify.showError(err)
    });
  }
  getTrendingPrayer() {
   var z =null;
 
    this.prayerService.getTrendingPrayer(z).subscribe((data: any) => {
      this.trendingPrayerList = data['result'].list;
    },(err) => {
      this.notify.showError(err)
    });
  }
  ngOnInit(): void {
    this.getTrendingPrayer();
    this.storageService.watchStorage().subscribe(value => {
      this.getPrayer();
    
    });
  }

}
