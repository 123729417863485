import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/services/page.service';

@Component({
  selector: 'app-prayerpointsce',
  templateUrl: './prayerpointsce.component.html',
  styleUrls: ['./prayerpointsce.component.css']
})
export class PrayerpointsceComponent implements OnInit {
  bannertitle = "Prayer Points"
  pageData:any;
  template:any;
  pagetitle:any;
  checkSection:boolean=false;
  bannerDetails: any;
  biblequotes: any;
  bannerDetailsTwo: any;
  pageDataTwo:any;
  description: any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  constructor(private pageservice:PageService) {    
    this.pageservice.getPage('60d1e54ab9e57b5357ac1f6b').subscribe(res =>{
    this.pageData=res.result.templateData;
    this.pagetitle=res.result.title;
    this.description=res.result.description;
    this.bannerDetails = [{bibleQuotes:this.pageData.bannerquote,verses:this.pageData.bannerversus,file:this.pageData.bannerimage?this.pageData.bannerimage:'../../../assets/images/whypray-bg.jpg'}]
    this.biblequotes = [{texthead:this.pageData.section1biblequote,quotesnum:this.pageData.section1bibleversus,description:this.pageData.section1description,thumbnail:this.pageData.section1image?this.pageData.section1image:'../../../../assets/images/how-to-pray.jpg'}]
    this.checkSection=true;
  })
  this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res =>{
    this.pageDataTwo=res.result.templateData;
    this.bannerDetailsTwo = {slidertitle1:this.pageData.riseupcanada,slidertitle2:this.pageData.prayforournation,sliders:this.pageData.banner}
    this.sectionDetails = {title:this.pageData.section2title,description:this.pageData.section2description,audio:this.pageData.section2audio,pdf:this.pageData.section2pdf}
    this.checkSection=true;
  })
 }
  prayerpointspage = [
    {
      title: "Prayer Points",
      lefttitle: "Download Prayer Points",
      description: "",
      slug: "",
      image: "",
      htmlContent: "",
      template: "",
      template_field: [{
        "banner": [
          {
            "bibleQuotes": "So I sought for a man among them who would make a wall, and stand in the gap before Me on behalf of the land (Canada), that I should not destroy it; but I found no one.",
            "verses": "EZE 22:30",
            "file": "https://ruc-frontend-webapp-dev-knjyght5ka-uc.a.run.app/whypray-bg.jpg",
          },
         
        ],
       
        "contentofbody": [
          {
            "texthead":"“In every place of worship, I want men to pray with holy hands lifted up to God, free from anger and controversy.“",
            "quotesnum": "1 Timothy 2:8",
            "description":"God loves to talk to us through prayer. There are many expectations of God discussed in the bible about prayer. Bible says we need to pray according to God’s will, with a sincere heart, with humility, with an attitude of thanksgiving, with a forgiving spirit, with perseverance/persistence, boldly expecting to receive and in the Name of Jesus Christ.",
            "thumbnail":"https://ruc-frontend-webapp-dev-knjyght5ka-uc.a.run.app/assets/images/how-to-pray.jpg"
          }
        ]
      }]
    }
  ]
  ngOnInit(): void {
  }

}
