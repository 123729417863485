import { Component, Input,  OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { NewsService } from 'src/services/news.service';
@Component({
  selector: 'app-newslist',
  templateUrl: './newslist.component.html',
  styleUrls: ['./newslist.component.css']
})
export class NewslistComponent implements OnInit {
  @Input() newslist: any=[];

  constructor(private router: Router,public newsService: NewsService) {
    this.newsService.sendssg.subscribe((res:any) => {
      this.newslist = res;
    })
  }

  ngOnInit(): void {

  }
  link(id: any) {
    this.router.navigate(['/newsdetails', id])
  }
}
