import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PersonalprayerService {

  constructor(private http: HttpClient) { }
  getApprovedData(status: string = 'approved',currentPage: number) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });
    return this.http.get<any>(`${environment.apiUrl}/personalprayer?status=${status}&page=` + `${currentPage}`, { headers: headers });
  }
  createPrayer(data: any) {

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });
    let body = data;
    return this.http.post<any>(`${environment.apiUrl}/create-personalprayer`, body,
      {
        headers: httpHeaders,
        observe: 'response',
      });
  }
  // getSearch(value) {

  //   let headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Access-Control-Allow-Origin': '*',
  //   });

  //   return this.http.get(`${environment.apiUrl}/approved-list?page=${value}`);

  // }
}
