
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SensitivePrayerService {
    sendssg = new Subject<any>();
    constructor(private http: HttpClient) { }
    // Get All News Lists
    getAllPrayer(provinceName) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        });
        return this.http.get<any>(`${environment.apiUrl}/sensitiveprayer?province=${provinceName}`, { headers: headers });
    }
    getAllCategory() {
   
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        });
        return this.http.get<any>(`${environment.apiUrl}/dropdown-category?page=`, { headers: headers });
     }
    getPage(provinceName,value) {
        let headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
      });
        
        return this.http.get(`${environment.apiUrl}/sensitiveprayer?province=${provinceName}&page=` + `${value}`);
    
      }
      getFilteredData(provinceName,cityName,postalCode,category) {
      
    
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        });
        if(category){
            return this.http.get<any>(`${environment.apiUrl}/sensitiveprayer-filter?province=${provinceName}&city=${cityName}&pincode=${postalCode}&category=${category}`,{ headers: headers });
        }
        else if(!category){
            return this.http.get<any>(`${environment.apiUrl}/sensitiveprayer-filter?province=${provinceName}&city=${cityName}&pincode=${postalCode}`,{ headers: headers });
        }
      
        
    }
    public getmsg(res:any) {
        
        this.sendssg.next(res);
      }
    
   
}
