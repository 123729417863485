import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from 'src/services/authentication.service';
import { NotificationService } from 'src/services/notification.service';
import { PageService } from 'src/services/page.service';

@Component({
  selector: 'app-partnerbenefits',
  templateUrl: './partnerbenefits.component.html',
  styleUrls: ['./partnerbenefits.component.css']
})
export class PartnerbenefitsComponent implements OnInit {
  // @Input() buttonLable: string;60d19cbe22adfd49d4d73c37
  // bannertitle = "Prayer Benefits"
  pageData:any;
  template:any;
  pagetitle:any;
  checkSection:boolean=false;
  bannerDetails: any;
  biblequotes: any;
  description: any;
  bannerDetailsTwo: any;
  pageDataTwo:any;
  isLogin: boolean = false;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  constructor(private pageservice:PageService,private authenticationService: AuthenticationService,private notify: NotificationService) {
    if (this.authenticationService.currentUserValue) {
      this.isLogin = true;
    }
    this.pageservice.getPage('60d32328d6a8760869a598ab').subscribe(res =>{
  
      this.pageData=res.result.templateData;
      this.pagetitle=res.result.title;
      this.description=res.result.description;
      this.bannerDetails = [{bibleQuotes:this.pageData.bannerquote,verses:this.pageData.bannerversus,file:this.pageData.bannerimage?this.pageData.bannerimage:'../../../assets/images/prayer-partner-bg.jpg'}]
      // this.biblequotes = [{texthead:this.pageData.section1biblequote,quotesnum:this.pageData.section1bibleversus,description:this.pageData.section1description,thumbnail:''}]
      this.checkSection=true;
    }, (err) => {
      this.notify.showError(err)
    });
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res =>{
      this.pageDataTwo=res.result.templateData;
      this.bannerDetailsTwo = {slidertitle1:this.pageDataTwo.riseupcanada,slidertitle2:this.pageDataTwo.prayforournation,sliders:this.pageDataTwo.banner}
      this.sectionDetails = {title:this.pageData.section2title,description:this.pageData.section2description,audio:this.pageData.section2audio,pdf:this.pageData.section2pdf}
      this.checkSection=true;
    }, (err) => {
      this.notify.showError(err)
    });
    
   }
  partnerbenefits = [
    {
      title: "Partner Benefits",
      description: "",
      slug: "",
      image: "",
      htmlContent: "",
      template: "",
      template_field: [{
        "banner": [
          {
            "bibleQuotes": "So I sought for a man among them who would make a wall, and stand in the gap before Me on behalf of the land (Canada), that I should not destroy it; but I found no one.",
            "verses": "EZE 22:30",
            "file": "https://ruc-frontend-webapp-dev-knjyght5ka-uc.a.run.app/prayer-partner-bg.jpg",
          },
         
        ],
        "contentofbody": [
          {
            "texthead":"Therefore I exhort first of all that supplications, prayers, intercessions,and giving of thanks be made for all men, 2 for kings and all who are in authority, that we may lead a quiet and peaceable life in all godliness and reverence. 3 For this is good and acceptable in the sight of God our Savior.",
            "quotesnum": "(1 Tim 2:1-3)",
            "description":"Jesus intercedes for us in heaven and the bible calls us to be transformed into His image. A mature believer of Christ will be a praying Christian. You may be that person that God is calling to stand in the gap and intercede for the nation. God will honor you and put a hedge around you to protect you and your family as you are fulfilling God’s desire. God delights in the prayers of the upright and righteous. (Pro 15:8,29). Even them I will bring to My holy mountain, and make them joyful in My house of prayer. For My house, shall be called a house of prayer for all nations.” (Isa 56:7).",
            "thumbnail":"https://ruc-frontend-webapp-dev-knjyght5ka-uc.a.run.app/assets/images/prayer-guide-bg.jpg"
          }
        ]
      }]
    }
  ]
  ngOnInit(): void {
  }

}
