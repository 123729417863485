<!-- Prayer Points -->
<div class="container-outer">
    <div class="prayer-footer">
        <div class="upcoming-events-title">
           <div class="row mar-zero">
              <div class="col s12 pad-zero">
                 <div class="left">
                    <span class="explore-province-txt clr-white">{{details?.title}}</span>
                 </div>
                 <div class="right">
                    <span class="province-arw clr-white"><i class="mdi mdi-arrow-right"></i></span>
                 </div>
              </div>
           </div>
        </div>
        <div class="upcoming-events-content foo-p-point">
            <p class="descriptionslice">{{details?.description}}</p>
           <app-prayerselectbox [details]="details"></app-prayerselectbox>
        </div>
     </div>
</div>

 <!--Prayer Points Form-->