<div class="strong_filters">
    <div class="province-wiseevent">
        <div class="container-outer">

            <form [formGroup]="createForm">
                <div class="curent-province row ">

                    <div class="col s12 m6 l3">

                        <div class="selectBtn">
                            <input formControlName="pincode" #pin  type="text" class="postalcode"  (click)="showList()"
                            (keyup)="autoComplete($event)" autocomplete="off" placeholder="postal Code"
                            class="postalcode form-control frm  m-2  " id="userIdFirstWay" list="dynmicUserIds" />
                            <ul class="list-group" id="ulautocomplete" *ngIf="openCountryList">
                                <li *ngFor="let item of autoCompletArr" class="list-group-item"
                                    (click)="selectAddress(item)"> <a class="card-link  handcursor">{{item.Next == "Find" ?
                                        item.Description : item.Text}}</a></li>
                            </ul>
                        </div>

                    </div>
                    <div class="col s12 m6 l3">

                        <div class="selectBtn">
                            <input formControlName="city" type="text"   class="postalcode" placeholder="City" />
                        </div>

                    </div>
                    <div class="col s12 m6 l3">
                        <div class="selectBtn">
                            <input formControlName="province"   type="text" class="postalcode" placeholder="Province" />
                        </div>
                    </div>
                    <div class="col s12 m6 l3">
                        <div class="dropdown">
                            <div class="select">
                                <span *ngIf="!category">Category</span>
                                <span *ngIf="category">{{category}}</span> 
                            </div>
                            <input type="hidden" name="gender" formControlName="category">
                            <ul class="dropdown-menu" >
                                   
                                <li (click)="selectCategory(list.category)" *ngFor="let list of prayerList">{{list.category}}
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div class="col s12 m6 l3">
                        <div class="data-added submitResetBtn">
                            <button    class="newsletter-btn" (click)="onSubmit(pin)">submit</button>

                        </div>



                    </div>
                    <!-- <div class="col s12 m6 l3">
                  <div class="data-added">
                      <a class="newsletter-btn"(click)="onReset()">Reset</a>
                  </div>
              </div> -->
                </div>


            </form>
        </div>
    </div>

</div>
