import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LeaderService {

    constructor(private http: HttpClient) { }
    getAllLeader(provinceName) {
      
        
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        });
        return this.http.get<any>(`${environment.apiUrl}/leader?province=${provinceName}`, { headers: headers });
    }

    getFilteredData(leaderName) {
      
        
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        });
        return this.http.get<any>(`${environment.apiUrl}/province-leader?province=${leaderName}`, { headers: headers });
    }
    
    getPage(provinceName,value) {
        let headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
      });
        
        return this.http.get(`${environment.apiUrl}/leader?province=${provinceName}&page=` + `${value}`);
    
      }

      getLeadersPage(value,province) {
        let headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
      });
        
        return this.http.get(`${environment.apiUrl}/leader?page=${value}&province=${province}`, { headers: headers });

        // `${environment.apiUrl}/province-event?province=${x}&city=${y}&pincode=${z}`
      }
   
}