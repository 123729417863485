<div *ngIf="checkSections">

    <div class="page-top ">

        <div class="layout text-center table">
            <div class="">
                <div class="left-panel">
                    <app-logo-layout></app-logo-layout>
                    <div class="left-bpanel hide-on-med-and-down">
                        <div class="pray-for-pm waves-effect" routerLink="/prayforleaders">
                            <div class="prayforpm-block">
                                <span class="prayforpm-txt">PRAY FOR THE RIGHTEOUS LEADERS TO LEAD OUR COUNTRY</span>
                            </div>
                        </div>
                    </div>
                    <div id="province-explore-panel" class="pro-expo-arrow" *ngIf="show">
                        <span class="pro-exp-close exp-pro" (click)="show_mapSlideout()"><i
                                class="mdi mdi-close"></i></span>
                        <div class="row mar-zero">
                            <div class="col s12 pad-zero center-align">
                                <img id="provinceImage" src="assets\images\province\{{province_code +'.png'}}" alt="" title="">
                            </div>
                            <div class="col s12 pad-zero center-align">
                                <div class="expo-pro-innerttl">
                                    <span class="expo-pro-innerttl-1" id="provinceLabel">Province of</span><br>
                                    <span id="provinceName" class="expo-pro-innerttl-2">{{division_name}}</span>
                                </div>
                            </div>
                            <!-- <div class="col s12 pad-zero center-align test-hiddden">
                                <div id="testimonialSlider" class="">Trending Issues</div>
                            </div> -->
                        </div>
                        <div class="row mar-zer">
                            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)">
                                <div ngxSlickItem *ngFor="let List of newslist" class="slide col s12 pad-zero items">
                                    <div class="pray-trendcol box-shadow-none pad-zero ht-auto">
                                        <div class="praytrend-image">
                                            <img src="{{List?.thumbnail}}" class="pticard-imght responsive-img" alt="">
                                        </div>
                                        <p class="explore-province-slider-content utility-margin-bottom-small txt-13 clr-777 line-ht-16" >
                                            {{List?.desc | striphtml }} </p>
                                        <div class="utility-margin-bottom-small" >
                                            <app-primarybutton buttonName="Read More" (click)="link(List.id)"></app-primarybutton>
                                        </div>
                                        <!-- <div class="mt-15 btn-amen">
                                            <app-primarybutton buttonName="Amen"></app-primarybutton>
                                            <p class="total-count">20 AMENS</p>
                                        </div> -->
                                    </div>
                                </div>
                            </ngx-slick-carousel>
                            <!-- <ul class="tr-issues">
                                <li class="col s12 pad-zero items" *ngFor="let List of socialList| keyvalue">
                                    <div class="pray-trendcol box-shadow-none pad-zero ht-auto">
                                        <div class="praytrend-image">
                                            <img src="{{List.value.thumbnail}}" class="pticard-imght responsive-img"
                                                alt="">
                                        </div>
                                        <p class="pticard-mapexpocard-cont-ht mar-zero txt-13 clr-777 line-ht-16">
                                            {{List.value.desc | sliceDescription}} </p>
                                        <div class="mt-15 btn-amen">
                                            <app-primarybutton buttonName="Amen"></app-primarybutton>
                                            <p class="total-count">20 AMENS</p>
                                        </div>
                                    </div>
                                </li>
                            </ul> -->
                        </div>

                        <div class="col s12 pad-zero center-align explore-btns-wrap">
                            <p class="mar-b-zero" id="explore_your"><a class="waves-effect expo-pro-btn" routerLink="/province">Explore your province</a></p>
                            <div class="spacer-sm"></div>
                            <p class="mar-b-zero"><button class="waves-effect expo-pro-btn" (click)="loadAP()">Vital
                                    Statistics</button></p>
                            <div class="spacer-sm"></div>
                            <p class="mar-t-zero" id="registerRUC"><a class="waves-effect expo-pro-btn" routerLink="/individual">Become a prayer partner</a></p>
                        </div>

                    </div>
                </div>
                <div class="hp-banner">
                    <app-homebanner [homesliders]="pageData?.banner"></app-homebanner>
                </div>
            </div>
        </div>
    </div>
    <div class="container-outer">
        <!--Pray for trending issues-->
        <div class="pray-trendissues wow fadeInLeft animated animated animated">
            <div class="pray-trend-title mobile-pad">
                <div class="row mar-zero">
                    <div class="col s12 pad-zero">
                        <div class="left">
                            <span class="explore-province-txt clr-white">{{pageData?.section2title}}</span>
                        </div>
                        <div class="right">
                            <span class="province-arw clr-white"><i class="mdi mdi-arrow-right"></i></span>
                        </div>
                    </div>
                    <div class="col s12 pad-zero hide-on-med-and-down">
                        <p class="clr-white txt-13 box-conspace slicecontent" id="trenddingWallLeft">{{pageData?.section2description}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="pray-trend-content">
                <div class="pti-row mar-zero" id="trendingWall">
                    <app-trentingissues *ngIf="displayList" [trendingPrayerList]="trendingPrayerList"></app-trentingissues>
                </div>
            </div>

        </div>

        <!--Prayer Point Section--->
        <div class="prayer-point-container">
            <app-footerprayerpoints  *ngIf="currentPageName === 'Home' || currentPageName === 'Province'" [details]="sectionDetails"></app-footerprayerpoints>
        </div>


        <!--Pray for trending issues-->
        <!--Prayer wall-->
        <div class="prayer-wall hame-wall">
            <div class="prayer-wall-title pb-0">
                <div class="row mar-zero">
                    <div class="col s12 pad-zero">
                        <div class="left">
                            <span class="explore-province-txt clr-red">{{pageData?.section3title}}</span>
                        </div>
                        <div class="right">
                            <span class="province-arw clr-red"><i class="mdi mdi-arrow-right"></i></span>
                        </div>
                    </div>
                    <div class="col s12 pad-zero pos-relative hide-on-med-and-down">
                        <div class="pray-count-list">
                            <p class="pray-count-num" id="socialPrayerCount">{{socialPrayerCount}}</p>
                            <p class="mar-zero pray-count-type">
                                <span class="pray-count-nam">{{pageData?.socialtitle}}</span><br>
                                <span class="pray-count-pttxt">{{pageData?.prayerpoints}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="col s12 pad-zero pos-relative hide-on-med-and-down">
                        <div class="pray-count-list">
                            <p class="pray-count-num" id="personalPrayerCount">{{totalPrayer}}</p>
                            <p class="mar-zero pray-count-type">
                                <span class="pray-count-nam">{{pageData?.personnaltitle}}</span><br>
                                <span class="pray-count-pttxt">{{pageData?.prayerpoints}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="col s12 pad-zero pos-relative hide-on-med-and-down">
                        <div class="pray-count-list-btm">
                            <div>
                                <p class="pray-count-num" id="sensitivePrayerCount">{{isLogin ? sensitivePrayerCount : 0}}</p>
                                <p class="mar-zero pray-count-type">
                                    <span class="pray-count-nam">{{pageData?.sensitivetitle}}</span><br>
                                    <!-- <span class="pray-count-pttxt">{{pageData.prayerpoints}}</span><br> -->
                                    <span class="txt-13 clr-red fw-italic">{{isLogin ? "":pageData?.section3hint }}</span>
                                </p>
                            </div>
                            <div class="spacer-m-dbl"></div>
                            <a class="waves-effect btn-lg" [routerLink]='[isLogin ? "/Strongholds" : "/prayerwarrior"]'>{{isLogin ? 'View PRAY FOR STRONGHOLDS':pageData.section3button}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="prayer-wall-content">
                <div class="row mar-zero">
                    <div class="col s12 m6 l6 pad-zero">
                        <div class="pray-trendcol home-blog ht-auto">
                            <p class="mar-t-zero why-pray-title">{{pageData?.prayer1title}}</p>
                            <div class="praytrend-image">
                                <img src="{{pageData?.prayer1image}}" class="responsive-img" alt="">
                            </div>

                            <p class="txt-14 mar-t-zero clr-888 short_dec">{{pageData?.prayer1description}} </p>
                            <a class="waves-effect btn-lg" routerLink="/socialprayer">{{pageData?.prayer1button}}</a>
                        </div>
                    </div>
                    <div class="col s12 m6 l6 pad-zero">
                        <div class="pray-trendcol home-blog bg-white ht-auto">
                            <p class="mar-t-zero why-pray-title">{{pageData?.prayer2title}}</p>
                            <div class="praytrend-image">
                                <img src="{{pageData?.prayer2image}}" class="responsive-img" alt="">
                            </div>

                            <p class="txt-14 mar-t-zero clr-888 short_dec">{{pageData?.prayer2description}}</p>
                            <a class="waves-effect btn-lg" routerLink="/personalprayer">{{pageData?.prayer2button}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Pray for christian leaders-->
        <div class="prayer-leader-panel">
            <div class="pray-lead-overlay"></div>
            <div class="container-outer">
                <div class="pray-lead-title">
                    <div class="row mar-zero">
                        <div class="col s12 pad-zero">
                            <div class="left">
                                <span class="prayer-leader-panel-txt clr-white">{{pageData?.section4title}}</span><br>
                                <p class="clr-white txt-13 box-conspace pcl slicetext">{{pageData?.section4description}}</p>
                                <!-- <span class="prayer-leader-panel-txt-sub" id="prayer-leader-panel-txt-sub">in your Province</span> -->
                            </div>
                            <div class="right">
                                <span class="province-arw clr-white"><i class="mdi mdi-arrow-right"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pray-lead-content" id="province_leaders">
                    <div class="row mar-zero">
                        <app-christianleader [leaderList]="leaderList"></app-christianleader>
                    </div>
                </div>
            </div>
        </div>
        <!--Pray for christian leaders-->
        <!--Prayer Resources-->
        <div class="prayer-resources">
            <div class="prayer-resources-title">
                <div class="row mar-zero">
                    <div class="col s12 pad-zero">
                        <div class="left">
                            <span class="explore-province-txt clr-red">{{pageData?.section5title}}</span>
                        </div>
                        <div class="right hide-on-med-and-down">
                            <span class="province-arw clr-red"><i class="mdi mdi-arrow-right"></i></span>
                        </div>
                    </div>
                    <div class="col s12 pad-zero hide-on-med-and-down">
                        <p class="pray-res-txt">{{pageData?.section5description}}
                        </p>
                    </div>
                    <div class="col s12 pad-zero">
                        <div class="row mar-zero">
                            <div class="col s6 pad-zero pos-relative">
                                <div class="pray-count-list-2" routerLink="/">
                                    <p class="pray-count-num" id="homeVideosCount">{{totalVideos}}</p>
                                    <p class="mar-zero pray-count-type">
                                        <span class="pray-count-nam">{{pageData?.videos}}</span><br>
                                    </p>
                                </div>
                            </div>
                            <div class="col s6 pad-zero pos-relative">
                                <div class="pray-count-list-2">
                                    <p class="pray-count-num" id="homePDFsCount">{{totalPdf}}</p>
                                    <p class="mar-zero pray-count-type">
                                        <span class="pray-count-nam">{{pageData?.pdf}}</span><br>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row mar-zero">
                            <div class="col s6 pad-zero pos-relative">
                                <div class="pray-count-list-2">
                                    <p class="pray-count-num" id="homeAudiosCount">{{totalAudio}}</p>
                                    <p class="mar-zero pray-count-type">
                                        <span class="pray-count-nam">{{pageData?.audios}}</span><br>
                                    </p>
                                </div>
                            </div>
                            <div class="col s6 pad-zero pos-relative">
                                <div class="pray-count-list-2">
                                    <p class="pray-count-num" id="homeBooksCount">{{totalBooks}}</p>
                                    <p class="mar-zero pray-count-type">
                                        <span class="pray-count-nam">{{pageData?.books}}</span><br>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col s12 pad-zero">
                        <div class="pray-count-list-btmbx ">
                            <p class="mar-zero pray-count-type"></p>
                            <div class="spacer-m-dbl"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="prayer-res-content">
                <div class="row">
                    <div class="col s12 l6 pad-zero">
                        <div class="why-pray-panel bottom-outline">
                            <p class="mar-t-zero why-pray-title">{{pageData?.whypraytitle}}</p>
                            <p class="why-pray-read">{{pageData?.whypraysubtitle}}
                            </p>
                            <p class="txt-14 clr-888" id="homeYPray1" [innerHTML]="description">
                                {{description}}
                            </p>
                            <!-- <p class="txt-14 clr-888" id="homeYPray2">
                            God has given us laws for our blessings and He expects us to follow them but he never forces anyone to follow these laws.
                        </p> -->
                            <div class="spacer-sm"></div>
                            <p><a class="waves-effect btn-lg" routerLink="/whypray">{{pageData?.whypraybutton}}</a></p>
                        </div>
                    </div>
                    <div class="col s12 l6 pad-zero">
                        <div class="howto-pray-panel">
                            <p class="mar-t-zero why-pray-title">{{pageData?.howtopraytitle}}</p>
                            <p class="txt-14 clr-888 mar-t-zero" id="homeH2Pray">{{pageData?.howtopraydesc}}
                            </p>
                            <div class="spacer"></div>
                            <div class="row">
                                <div class="col s12 pad-zero">
                                    <a href="" [routerLink]="['/prayerresource']" fragment="redirectvideo">
                                        <div class="howpray-type waves-effect">
                                            <div class="howpray-icon">
                                                <span><i class="mdi mdi-video"></i></span>
                                            </div>
                                            <div class="howpray-txt">
                                                <p class="howpray-smtxt">{{pageData?.howtopraytitle}}</p>
                                                <p class="howpray-txt-title">{{pageData?.videos}}</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="" [routerLink]="['/prayerresource']" fragment="redirectaudio">
                                        <div class="howpray-type waves-effect">
                                            <div class="howpray-icon">
                                                <span><i class="mdi mdi-headphones-settings"></i></span>
                                            </div>
                                            <div class="howpray-txt">
                                                <p class="howpray-smtxt">{{pageData?.howtopraytitle}}</p>
                                                <p class="howpray-txt-title">{{pageData?.audios}}</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="" [routerLink]="['/prayerresource']" fragment="redirectpdf">
                                        <div class="howpray-type waves-effect">
                                            <div class="howpray-icon">
                                                <span><i class="mdi mdi-file-pdf-box"></i></span>
                                            </div>
                                            <div class="howpray-txt">
                                                <p class="howpray-smtxt">{{pageData?.howtopraytitle}}</p>
                                                <p class="howpray-txt-title">{{pageData?.pdf}}</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="" [routerLink]="['/prayerresource']" fragment="redirectbook">
                                        <div class="howpray-type waves-effect">
                                            <div class="howpray-icon">
                                                <span><i class="mdi mdi-book-multiple"></i></span>
                                            </div>
                                            <div class="howpray-txt">
                                                <p class="howpray-smtxt">{{pageData?.howtopraytitle}}</p>
                                                <p class="howpray-txt-title">{{pageData?.books}}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Prayer Resources-->
        <!--Upcoming Events-->
        <div class="upcoming-events">
            <div class="upcoming-events-title">
                <div class="row mar-zero">
                    <div class="col s12 pad-zero">
                        <div class="left">
                            <span class="explore-province-txt clr-white">{{pageData.section6title}}</span>
                        </div>
                        <div class="right">
                            <span class="province-arw clr-white"><i class="mdi mdi-arrow-right"></i></span>
                        </div>
                    </div>
                    <div class="col s12 pad-zero">
                        <p class="clr-white txt-13 box-conspace slicecontent">{{pageData.section6description}}</p>
                    </div>
                </div>
                <div class="">
                    <!-- <app-primarybutton buttonName="View prayer leaders" routerLink="/prayforleaders"></app-primarybutton> -->
                    <a class="waves-effect pray-lead-btn" href="" routerLink="/events">All Events</a>
                </div>
            </div>
            <div class="upcoming-events-content short-newsdec">
                <app-eventlist *ngIf="checkRes" [eventList]="eventList"></app-eventlist>
            </div>
        </div>
    </div>
    <!--Upcoming Events-->
    <app-chooseprovince></app-chooseprovince>
    <app-prayfornation *ngIf="checkSections" [currentPageName]="currentPageName" [banner]="bannerDetailsTwo"></app-prayfornation>
