import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bannerbiblequote',
  templateUrl: './bannerbiblequote.component.html',
  styleUrls: ['./bannerbiblequote.component.css']
})
export class BannerbiblequoteComponent implements OnInit {
  @Input() biblequotes: any = '';
  @Input() verses: any = ' ';
  constructor() { }

  ngOnInit(): void {
  }

}
