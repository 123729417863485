import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slices'
})
export class SlicePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) { return ''; }
    var words = value.trim().split(/\s/).slice(0,3);
      var joins = words.join(" "); 
    return joins;
  }

}
