<div class="row mar-zero" *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <app-innerbanner [bannerDetails]="bannerDetails" [title]="newsdetails[0]['title']"></app-innerbanner>
        </div>
    </div>
</div>
<div class="container-outer" *ngIf="checkSection">
    <!-- Event Datails Content-->
    <div class="static-section-panel bb-0">
        <div class="static-section-lpanel">
            <!-- <div class="row mar-zero">
             <div class="col s12 pad-zero">
                <div class="left">
                   <span class="static-section-ltitle">Upcoming<br>Events</span>
                </div>
                <div class="right">
                   <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                </div>
             </div>
          </div> -->

        </div>
        <div class="static-section-rpanel-card add-block-box pb-0">
            <div class="event-home">
                <div class="event-header">
                    <div class="event-banner">
                        <img src="{{topImage.thumbnail}}">
                    </div>
                    <div class="event-title">
                        <div class="location">
                            <p class="adbanner-contact">

                                <span class="header-date adbanner-txt-1">{{topImage.date| date}}</span>
                            </p>
                        </div>
                        <h2>{{topImage.title}}</h2>

                    </div>
                </div>
                <div class="event-runtext">
                    <p [innerHTML]="topImage.desc">{{topImage.desc}}</p>
                </div>
                <div class="move-next">
                    <div class="prev">
                        <a class="waves-effect btn-lg" (click)="loadPrev()"><i class="mdi mdi-arrow-left"></i>&nbsp;&nbsp;Prev</a>
                    </div>
                    <div class="next">
                        <a class="waves-effect btn-lg" (click)="loadNext()">Next &nbsp;&nbsp;<i class="mdi mdi-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Event Datails Content End-->
    <!-- Event List Content-->
    <div class="static-section-panel">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">
                            {{pageData.newssubtitle}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <!--<div class="event-ldmr-panel"><a class="waves-effect btn-lg">Load More events</a></div>-->
        </div>
        <div class="static-section-rpanel-card add-block-box pt-0 short-newsdec">
            <div class="row mar-zero news_container">
                <div class="col s12 m6 l3 pad-zero hoverable" *ngFor="let list of displayList | slice:0:4;let i =index">
                    <div class="pray-trendcol card-sdw-btm">
                        <div class="praytrend-image">
                            <img src="{{list.thumbnail}}" class="responsive-img" alt="News_Thumbnail">
                        </div>
                        <span class="adbanner-txt-1"><b>{{list.title | slices}}</b> </span>

                        <p class="mar-zero txt-13 clr-777" [innerHTML]="list.desc| sliceDescription">{{list.desc}} </p>
                        <div class="more-news">
                            <p class="news-showlist">
                                <span class="adbanner-txt-2">{{list.createdDate| date}}</span>
                            </p>
                            <app-primarybutton buttonName="Read More" (click)="link(list.id,i)"></app-primarybutton>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="cen-btn">
            <a class="waves-effect btn-lg" routerLink="/news">{{pageData.allnews}}</a>
        </div>
    </div>
    <!--Event List End-->
</div>
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>
