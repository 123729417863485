<div class="row mar-zero event_container">
    <div *ngIf="eventList.length===0" class="data-notfount">
        <h2 style="text-align: center;">No Records Found</h2>
    </div>
    <div class="col s12 m6 l3 pad-zero hoverable" *ngFor="let List of eventList">

        <div class="pray-trendcol event-height card-sdw-btm" routerLink="/eventsdetails">
            <div class="praytrend-image">
                <img src="{{List.thumbnail}}" class="responsive-img" alt="Event_Thumbnail">
            </div>
            <p class="mar-zero txt-13 clr-777" [innerHTML]="List.desc| sliceDescription">{{List.desc |
                sliceDescription}} </p>
            <div class="event-group">
                <div class="adbanner-contact">
                    <span class="adbanner-txt-2" *ngIf="List.advertisement === false">{{List.date| date}} {{List.endDate ? "-" + (List.endDate | date) : ''}}</span>
                    <h6 class="adbanner-txt-1" *ngIf="List.advertisement === false">{{List.province| replace : '_' : ' '}},&nbsp;{{List.city}}</h6>
                </div>
                <div class="adPosition" *ngIf="List?.advertisement === true">
                    <p>For further details contact</p>
                     <span  class="adbanner-txt-2">{{List.ad_email}}</span>
                </div>
                <app-primarybutton buttonName="details" (click)="link(List.id)"
                    ></app-primarybutton>
            </div>
        </div>

    </div>
</div>