
import { Component, OnInit, Input } from '@angular/core';
import { SensitivePrayerService } from 'src/services/sensitiveprayer.service';
import { CommonService } from 'src/services/common.service';
import { NotificationService } from 'src/services/notification.service';
declare var $: any;
@Component({
  selector: 'app-sensitivelist',
  templateUrl: './sensitivelist.component.html',
  styleUrls: ['./sensitivelist.component.css']
})
export class SensitivelistComponent implements OnInit {
  @Input() prayerList: any = [];
  @Input() data: any;
  url = "";
  postRef: any;
  lat: any;
  long: any;
  id: any;
  count: any;
  isLoad: boolean = false;
  hostipAddress: string = '';
  datas: object;
  constructor(public prayerService: SensitivePrayerService, private amenService: CommonService, public notify: NotificationService) {
    this.prayerService.sendssg.subscribe((res: any) => {
      this.prayerList = res;
    })
  }

  showMap(lat, long) {
    this.url = `https://www.google.com/maps/place/${lat}+${long}`
    window.open(this.url);
  }

  addAmen(data, i) {
    var uniqueData = JSON.parse(localStorage.getItem('uuid'));
    this.id = i;

    var pushId;

    if (localStorage.getItem('session') === null) {
      pushId = [];
    } else {

      pushId = JSON.parse(localStorage.getItem('session'));
    }
    pushId.push(data.id);
    localStorage.setItem('session', JSON.stringify(pushId));

    if (uniqueData === null) {
      this.datas = {
        'postRef': data.id,
      }
    }
    else if (uniqueData !== null) {
      this.datas = {
        'postRef': data.id,
        'uuid': uniqueData
      }
    }


    this.isLoad = true;

    this.amenService.addAmen(this.datas).subscribe(response => {

      if (response.status == 200) {
   
        this.isLoad = false
        this.hostipAddress = response.body.hostIp;
        localStorage.setItem('uuid', JSON.stringify(this.hostipAddress));
        data.amenCount++;
        data.disabled = true;
        this.notify.showSuccess('Amen Count Added')
      }
    }, (err) => {
      this.isLoad = false;
      this.notify.showError(err.error.message)
    })

  }

  ngOnInit(): void {
    var idStore = JSON.parse(localStorage.getItem('session'));
    this.prayerList.forEach((item) => {
      if (idStore.indexOf(item.id) > -1) {

        item.disabled = true
      }
      else {
        item.disabled = false

      }
    })
  }

}
