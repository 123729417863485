import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { PageService } from 'src/services/page.service';
import { GroupregisterService } from 'src/services/groupregister.service';
import { NotificationService } from 'src/services/notification.service';
import * as $ from 'jquery';
import { CanadaPostalCode } from 'src/services/post-canade.service';
declare var $:any;
@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {
  bannertitle = "register a group"
  form: FormGroup;
  subform: FormGroup;
  search: string = '';
  selectId: string = '';
  autoCompletArr: any = [];
  createGroupForm: FormGroup;
  public selectedField: number = 0
  public selId: string;
  pageData: any;
  pagetitle: any;
  bannerDetails: any;
  checkSection: boolean = false;
  description: any;
  submitted: boolean;
  openModal: boolean;
  id: string;
  Id: number;
  groupList: any
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  groupName: any;
  groupId: any
  onSubmit: boolean = false;
  submitForm: boolean = false;
  submittedData: boolean = false
  selected: any;
  dataList: Array<any> = [];
  bannerDetailsTwo: any;
  pageDataTwo: any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  openCountryList:boolean=false;
  constructor(private fb: FormBuilder, private pageservice: PageService, private canadaPostService: CanadaPostalCode, private addPrayer: GroupregisterService, private notify: NotificationService) {
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res => {
      this.pageDataTwo = res.result.templateData;
      this.bannerDetailsTwo = { slidertitle1: this.pageDataTwo.riseupcanada, slidertitle2: this.pageDataTwo.prayforournation, sliders: this.pageDataTwo.banner }
      this.sectionDetails = { title: this.pageData.section2title, description: this.pageData.section2description, audio: this.pageData.section2audio, pdf: this.pageData.section2pdf }
      this.checkSection = true;
    })
    this.createGroupForm = this.fb.group({
      groupName: ['', Validators.required],
      emailLeader: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      postalCode: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      province: ['', Validators.required],
      churchGroup: ['', Validators.required],
    });
    // this.form=this.fb.group({
    //   groupId:['', Validators.required],
    //   firstName:['', Validators.required],
    //   lastName:['', Validators.required],
    //   email:['', Validators.required,Validators.pattern(this.emailPattern)]

    // })

    this.pageservice.getPage("60d41d6ba793750617d184d3").subscribe(res => {
      this.pageData = res.result.templateData;
      this.pagetitle = res.result.title;
      this.description = res.result.description;
      this.checkSection = true;
    })
    this.dataList = [
      { code: 1, name: "Prayer Group" },
      { code: 2, name: "Church Group" },
      { code: 3, name: "Third Choice" }
    ]
  }
 


  autoComplete($event) {
    this.search = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
    this.selectId = '';
    this.autoCompleteService();
  }
  
  showList(){
    this.openCountryList=true;
  }

  autoCompleteService() {
    this.canadaPostService.autoComplete(this.search, this.selectId).subscribe(response => {
      this.autoCompletArr = [];
      this.autoCompletArr = response.Items;
    });
  }
  getRetriveAddress(){
    this.canadaPostService.retriveAddress(this.selectId).subscribe(response => {
      var item = response.Items[0];
       this.createGroupForm.patchValue({
        address: item.Line1,
        city: item.City,
        province: item.ProvinceName,
        postalCode: item.PostalCode
      });
      this.autoCompletArr = [];
    });
  }

  selectAddress(item) {
    this.selectId = item.Id;
    if (item.Next == 'Find') {
      this.autoCompleteService();
    } else {
      this.getRetriveAddress();
    }
  }

  ngOnInit(): void {
    this.groupData();

    $(document).on('click', function (event) {
      if (!$(event.target).closest('#spnauto').length) {
        $('#ulautocomplete').hide();
      }
      else {
        $('#ulautocomplete').show();
      }
    });

    this.form = this.fb.group({
      inviteMembers: this.fb.array([this.createItem()], Validators.required),
      groupId: ['', Validators.required],

    })

    // this.subform = this.fb.group({
    //   subinviteMembers: this.fb.array([this.createSubItem()]),
    //   // groupId:[''],
    // })


  }
  reset(){
    this.createGroupForm.reset();
  }
  get groupIds() {
    return this.form.get('groupId');
  }
  get inviteMembers(): FormArray {
    return this.form.get('inviteMembers') as FormArray;
  }

  createItem() {
    return this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    })
  }

  removeItem(index: number) {
    this.inviteMembers.removeAt(index);
  }

  groupData() {
    this.addPrayer.viewGroupData().subscribe((data) => {
      this.groupList = data.result.list;
    })
  }

  onOptionsSelected() {
  }
  addNext() {
    //this.selectedField = 0;

    (this.form.controls['inviteMembers'] as FormArray).push(this.createItem());
  }
  

  selField(value, index: number) {
    this.selId = "subinviteMembersec_" + index;
    alert(value)
    if (value == "test4") {
      document.getElementById(this.selId).classList.remove("d-none");
    } else {
      document.getElementById(this.selId).classList.add("d-none");
    }
  }

  get f() { return this.createGroupForm.controls; }
  get e() { return this.form.controls; }
  isValidFormSubmitted: boolean | null = null;


  submit() {
    this.isValidFormSubmitted = false;
    this.submittedData = true;
    this.onSubmit = true;
    if (this.form.invalid) {
      // console.error("Error");
      return null;
    }
    if (this.form.valid) {
      let data = {
        'groupId': this.form.get('groupId')?.value,
        'inviteMembers': this.form.get('inviteMembers')?.value
      }

      this.addPrayer.addInviteMember(data).subscribe(data => {
        if (data.status == 200) {
          this.submittedData=false;
          this.onSubmit = false;
          // this.openModal = true;
          this.notify.showSuccess('Registered Succesfully')
          this.form.reset();
        }

      },
        (err) => {
          this.onSubmit = false;
          this.notify.showError(err.error.message)
        });
    }
  }

  // //Methods for Sub-form fields
  // get subformArr() {
  //   return this.subform.get('subinviteMembers') as FormArray;
  // }

  // createSubItem() {
  //   return this.fb.group({
  //     subDashFldName: 'Success',
  //     subDataFldName: '',
  //     firstName:['',Validators.required],
  //     lastName:['',Validators.required],
  //     email:['',Validators.required]
  //   })
  // }

  // removeSubItem(index: number) {
  //   this.subformArr.removeAt(index);
  // }

  // addSubNext(index: number) {
  //   //"subform"+index;
  //   let formName = "subform" + index;
  //   (this.subform.controls['subinviteMembers'] as FormArray).push(this.createSubItem())
  // }

  SaveChanges() {
    this.submitted = true;
    if (this.createGroupForm.valid) {
      let data = {

        'groupName': this.createGroupForm.get('groupName')?.value,
        'emailLeader': this.createGroupForm.get('emailLeader')?.value,
        'postalCode': this.createGroupForm.get('postalCode')?.value,
        'address': this.createGroupForm.get('address')?.value,
        'province': this.createGroupForm.get('province')?.value,
        'city': this.createGroupForm.get('city')?.value,
        'churchGroup': this.createGroupForm.get('churchGroup')?.value,

      }
      // setTimeout(() => {
      //   this.submitted = false;
      // }, 1000);
      this.submitForm = true;
      this.addPrayer.addGroupData(data).subscribe(data => {
        if (data.status == 200) {
          this.submitted=false;
          this.submitForm = false;
          this.createGroupForm.reset();
          this.openModal = true;
          this.notify.showSuccess('Registered Succesfully')
        }

      },
        (err) => {
          console.error("loop");
          this.submitForm = false;
          this.notify.showError(err)
        });
    }
  }
}
