<ul class="bxslider">
    <li *ngFor="let homeslider of homesliders; index as i;">
        <div style="height:100vh;background-size: cover;
        background-position: center"
            [ngStyle]="{'background-image': 'url(' + (homeslider.bannerimage?homeslider.bannerimage:'../../../assets/images/slider.jpg') + ')'}">
        </div>
        <div class="slideimgfxd">
            <div class="slidimgcont">
                <div class="banner_content">
                    <div class="home-bible">
                        <app-bannerbiblequote [biblequotes]="homeslider.bannerquote" [verses]="homeslider.bannerversus">
                        </app-bannerbiblequote>
                    </div>
                    <div class="slider-big-txt">
                        {{homeslider.bannertitle}}
                    </div>
                    <p>
                        <a class="slider-btn-lg waves-effect waves-light"
                            routerLink="/individual">{{homeslider.buttonname}}</a>
                    </p>

                </div>
            </div>
        </div>
    </li>

</ul>
<!-- <div class="home_slider">
    <ul class="owl-carousel owl-theme">
        <li *ngFor="let homeslider of homesliders; index as i;" class="item">
            <div style="height:100vh;background-size: cover;
      background-position: center" [ngStyle]="{'background-image': 'url(' + (homeslider.bannerimage?homeslider.bannerimage:'../../../assets/images/slider.jpg') + ')'}">
            </div>
            <div class="slideimgfxd">
                <div class="slidimgcont">
                    <div class="banner_content">
                        <div class="home-bible">
                            <app-bannerbiblequote [biblequotes]="homeslider.bannerquote" [verses]="homeslider.bannerversus"></app-bannerbiblequote>
                        </div>
                        <div class="slider-big-txt">
                            {{homeslider.bannertitle}}
                        </div>
                        <p>
                            <a class="slider-btn-lg waves-effect waves-light" routerLink="/individual">{{homeslider.buttonname}}</a>
                        </p>

                    </div>
                </div>
            </div>
        </li>

    </ul>
</div> -->