import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/services/notification.service';
import { PageService } from 'src/services/page.service';
import { PartnerorgService } from 'src/services/partneringorg.service';
import { CanadaPostalCode } from 'src/services/post-canade.service';
declare var $:any;
@Component({
  selector: 'app-partnerorganization',
  templateUrl: './partnerorganization.component.html',
  styleUrls: ['./partnerorganization.component.css']
})
export class PartnerorganizationComponent implements OnInit {
  pageData: any;
  pagetitle= "Partnering Organizations";
  bannerDetails: any;
  checkSection: boolean = false;
  description: any;
  createOrganizationForm: FormGroup;
  submitted: boolean;
  loaderSpin: boolean = false;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  orgList: any;
  totalItems: any;
  currentPage: number = 0;
  currentData:number= 0;
  totalPages: any;
  loading: boolean = true;
  constructor(private pageservice:PageService, private partnerservice:PartnerorgService,private notify: NotificationService,private formBuilder: FormBuilder) {
    this.pageservice.getPage("61f7cf75a3731f46ddee1604").subscribe(res => {
      this.pageData = res.result.templateData;
      this.pagetitle = res.result.title;
      this.description = res.result.description;
      this.checkSection = true;
    })
    this.createOrganizationForm = this.formBuilder.group({
      organizationName: ['', Validators.required],
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      mobile: ['', [Validators.required,, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]  
    });
   }
   get f() { return this.createOrganizationForm.controls; }
   getpartnerList() {
    this.partnerservice.getAllLists(this.currentPage == 0 ? this.currentPage : this.currentPage -1).subscribe((data: any) => {
      this.loading = false;
      this.orgList =  data.result.list;
      this.totalPages = data.result.info.totalPages;
      this.totalItems = data.result.info.totalItems;
      this.currentData = data.result.info.currentPage;
    });
  }
  handlePageChange(event: any) {
    this.currentPage = event;
    this.partnerservice.getAllLists(this.currentPage == 0 ? this.currentPage : this.currentPage -1).subscribe((data: any) => {
      this.loading = false;
      this.orgList =  data.result.list;
      this.totalPages = data.result.info.totalPages;
      this.totalItems = data.result.info.totalItems;
      this.currentData = data.result.info.currentPage;
  
    });
  }

  ngOnInit(): void {
   this.getpartnerList();
  }
  
  savechanges() {

    this.submitted = true;
    if (this.createOrganizationForm.valid) {
      let data = {

        'organizationName': this.createOrganizationForm.get('organizationName')?.value,
        'email': this.createOrganizationForm.get('email')?.value,
        'mobile': this.createOrganizationForm.get('mobile')?.value,
        'name': this.createOrganizationForm.get('name')?.value,

      }
      this.loaderSpin = true;
      this.partnerservice.addPartner(data).subscribe(data => {
        if (data.status == 200) {
          this.submitted = false;
          this.loaderSpin = false;
          this.createOrganizationForm.reset();
          this.notify.showSuccess('Registered Succesfully')
        }

      },
        (err) => {
          this.loaderSpin = false;
          this.notify.showError(err.error.message)
        });
    }
  }
}
