import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/services/page.service';
import { PrayerresourceService } from 'src/services/prayerresource.service';

@Component({
  selector: 'app-audiosdetails',
  templateUrl: './audiosdetails.component.html',
  styleUrls: ['./audiosdetails.component.css']
})
export class AudiosdetailsComponent implements OnInit {
  bannertitle = "Audios";
  bannerDetailsTwo: any;
  pageDataTwo:any;
  pageData: any;
  pagetitle: any;
  template: any;
  bannerDetails:any;
  listAudios:boolean=false;
  audioLists:any
  audioUrl:string="Audio"
  pageCount:number=0;
  checkRes:boolean=false;
  checkSection: boolean = false;
  totalPages: any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  constructor(private pageservice: PageService,private resourceService:PrayerresourceService) {
    this.pageservice.getPage('61af14ed0790a51eebeb8c1f').subscribe(res => {
      this.pageData = res.result.templateData;
      this.pagetitle=res.result.title;
      this.bannerDetails = [{ bibleQuotes: this.pageData.bannerquote, verses: this.pageData.bannerversus, file: this.pageData.bannerimage ? this.pageData.bannerimage : '../../../assets/images/about-bg.jpg' }]
      this.checkSection = true;
    })
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res =>{
      this.pageDataTwo=res.result.templateData;
      this.bannerDetailsTwo = {slidertitle1:this.pageDataTwo.riseupcanada,slidertitle2:this.pageDataTwo.prayforournation,sliders:this.pageDataTwo.banner}
      this.sectionDetails = {title:this.pageDataTwo.section2title,description:this.pageDataTwo.section2description,audio:this.pageDataTwo.section2audio,pdf:this.pageDataTwo.section2pdf}
      this.checkSection=true;
    })
   }
  audiosdetail = [
    {
      title: "Audios",
      description: "",
      slug: "",
      image: "",
      htmlContent: "",
      template: "",
      template_field: [{
        "banner": [
          {
            "bibleQuotes": "So I sought for a man among them who would make a wall, and stand in the gap before Me on behalf of the land (Canada), that I should not destroy it; but I found no one.",
            "verses": "EZE 22:30",
            "file": "../../../assets/images/about-bg.jpg",
          },
         
        ],
       
      }]
    }
  ]
  getAllAudio(){
    this.resourceService.getAllResources(this.audioUrl,this.pageCount).subscribe((data: any) => {
      this.checkRes = true;
      this.audioLists =data.result.resource;
      this.totalPages = data['result'].info.totalPages;
      // this.totalItems = data['result'].info.totalItems;
    });
  }
  loadMore() {
    this.pageCount= this.pageCount + 1;
    if (this.totalPages > this.pageCount) {
      this.resourceService.getAllResources(this.audioUrl,this.pageCount).subscribe((data: any) => {
        var list = data.result.resource;
        list.map((o) => {
          this.audioLists.push(o);
        });
        this.listAudios = this.totalPages > this.pageCount + 1 ? true : false;
        this.checkRes = true;
      });
    } else {
      this.listAudios = false;
    }
  }
  ngOnInit(): void {
    this.getAllAudio();
  }

}
