import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) { }
   addAmen(data: any){
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
  });
  let body = data;
  return this.http.post<any>(`${environment.apiUrl}/add-amen`, body,
      {
          headers: httpHeaders,
          observe: 'response',
      });
   }
 

}
