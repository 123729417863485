<div class="">

    <form>
        <div class="curent-province down-pdf row">
            <div class="col s12 m6 l3">
                <!-- <div class="cselect">
                    <input type="text" disabled placeholder="Category">
                    <ul class="select-options slim-scroll">
                       <li (click)=getValue(list.id) *ngFor="let list of Province">{{list.title}}</li>
                    </ul>

                </div> -->
                <div class="dropdown-01">
                    <div class="select">
                        <span *ngIf="!category_name">Category</span>
                        <span *ngIf="category_name">{{category_name}}</span>
                    </div>
                    <input type="hidden" name="gender">
                    <ul class="dropdown-menu">
                        <li (click)=getValue(list.id) *ngFor="let list of Province" (click)="category_get(list.title)">
                            {{list.title}}</li>
                    </ul>
                </div>
            </div>

            <div class="col s12 m6 l3">
                <!-- <div class="cselect">
                    <input class="clickCity" type="text" disabled placeholder="Sub Category">
                    <ul select-options slim-scroll>
                        <li (click)="getPrayers(list)" *ngFor="let list of category">{{list.title}}</li>

                    </ul>


                </div> -->
                <div class="dropdown-01">
                    <div class="select">
                        <span *ngIf="!subcategory_name">Sub Category</span>
                        <span *ngIf="subcategory_name">{{subcategory_name}}</span>
                    </div>
                    <input type="hidden" name="gender">
                    <ul class="dropdown-menu" >
                        <li (click)="getPrayers(list)" *ngFor="let list of category"
                            (click)="subcategory_get(list.title)">{{list.title}}</li>
                    </ul>
                </div>
            </div>

            <div class="col s12 m6 l3">
                <div class="data-added" >
                    <button class="newsletter-btn"   [ngStyle]="pdfurl == ''?{'opacity': '0.6','cursor':'not-allowed'} : {'opacity': '1.0'}" [disabled]="pdfurl == ''" (click)="viewPdf()"><img class="btn-icon"
                            src="../../../assets/images/pdf-download.png">Download PDF</button>
                </div>
            </div>
            <div class="col s12 m6 l3" >
                <div class="data-added">
                    <button class="newsletter-btn"  [ngStyle]="audiourl == ''?{'opacity': '0.6','cursor':'not-allowed'} : {'opacity': '1.0'}"  [disabled]="audiourl == ''"  (click)="viewAudio()"><img class="btn-icon"
                            src="../../../assets/images/audio-btn.png">Listen Audio</button>
                </div>
            </div>
        </div>
    </form>
</div>