import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsService } from 'src/services/news.service';
import { PageService } from 'src/services/page.service';


@Component({
  selector: 'app-newsdetails',
  templateUrl: './newsdetails.component.html',
  styleUrls: ['./newsdetails.component.css']
})
export class NewsdetailsComponent implements OnInit {
  bannertitle = "News"
  id: string;
  count = 0;
  topImage = { city: "", desc: '', thumbnail: '', title: '',date:'' };
  newsList: any = [];

  public getId;
  public date;
  public thumbnail;
  public city;
  public desc;
  public topId;
  displayList: any;
  prevBtn: boolean = false;
  nextBtn: boolean = true;
  pageData: any;
  pagetitle: any;
  bannerDetails:any;
  checkSection: boolean = false;
  bannerDetailsTwo: any;
  pageDataTwo:any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  constructor(private route: ActivatedRoute, private newsService: NewsService,private pageservice:PageService) {
    this.pageservice.getPage('61af2e6a3eb98f491d67642b').subscribe(res => {
      this.pageData = res.result.templateData;
      this.bannerDetails = [{ bibleQuotes: this.pageData.bannerquote, verses: this.pageData.bannerversus, file: this.pageData.bannerimage ? this.pageData.bannerimage : '../../../assets/images/event-bg.jpg' }]
      this.checkSection = true;
    })
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res =>{
      this.pageDataTwo=res.result.templateData;
      this.bannerDetailsTwo = {slidertitle1:this.pageDataTwo.riseupcanada,slidertitle2:this.pageDataTwo.prayforournation,sliders:this.pageDataTwo.banner}
      this.sectionDetails = {title:this.pageDataTwo.section2title,description:this.pageDataTwo.section2description,audio:this.pageDataTwo.section2audio,pdf:this.pageDataTwo.section2pdf}
      this.checkSection=true;
    })
   }

  newsdetails = [
    {
      title: "News",
      description: "",
      slug: "",
      image: "",
      htmlContent: "",
      template: "",
      template_field: [{
        "banner": [
          {
            "bibleQuotes": "So I sought for a man among them who would make a wall, and stand in the gap before Me on behalf of the land (Canada), that I should not destroy it; but I found no one.",
            "verses": "EZE 22:30",
            "file": "../../../assets/images/prayer-partner-bg.jpg",
          },

        ],

        "newsheader": [
          {
            "date": "23 Mar 2021",
            "title": "Lorem ipsum dlor sit amet, consectetur oiuradipiscing elit. Nunc molestie risus.",
            "description": "The claim that God is Omniscient means that he is an all-knowing. God is all-knowing in the sense that he is aware of our past, present, and future. Nothing takes him by surprise. His knowledge is total. So, if he knows everything about us why, should we pray? God created man in His won image; in the image of God He created him; male and female (Gen 1:27). We are in fact created like Him. We are His offspring. We have been allowed to live freed from any forceful interference of Him. One may ask the question, where was God when Adam and Eve sinned? Why did he not come and stop them?",
            "thumbnail": "https://ruc-frontend-webapp-dev-knjyght5ka-uc.a.run.app/assets/images/023.jpg"
          }
        ]
      }]
    }
  ]
  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.getId = this.id;
    this.getNews();

  }
  getNews() {
    // var y=localStorage.getItem('selectedRegionName');
    // var x =y.replace(/"/g,"");
    // var z =x.replace(" ","_");
    this.newsService.getSingleNews(this.getId).subscribe((data: any) => {
      this.topImage = {

        date:data.result.createdDate,
        city: data.result.city,
        thumbnail: data.result.thumbnail,
        desc: data.result.desc,
        title: data.result.title
      }

      this.topId = data.result.id;
      var y = localStorage.getItem('selectedRegionName');
      var p = y.replace(/"/g, "");
      var z = p.replace(" ", "_");
      this.newsService.getNews(z).subscribe((data: any) => {
        this.newsList = data['result'].list;
        this.displayList = this.newsList.filter(data => data.id !== this.topId)
      });
    });

  }

  loadPrev() {
    // this.prevBtn = this.count === 0 ? false : true;
    // this.nextBtn = this.count === 8 ? false : true;
    this.count = this.count - 1;

    this.topImage = this.newsList[this.count];
    window.scrollTo(0, 0);
  }

  loadNext() {
    // this.prevBtn = this.count === 1 ? false : true;
    // this.nextBtn = this.count === 8 ? false : true;
    this.count = this.count + 1;
    this.topImage = this.newsList[this.count];
    window.scrollTo(0, 0);


  }
  link(id, index) {
    this.topImage = this.displayList[index]
    this.displayList = this.newsList.filter(data => data.id !== id);
    window.scrollTo(0, 0);
  }
}

