<div class="row mar-zero" *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <app-innerbanner [bannerDetails]="bannerDetails" [title]="eventsdetails[0]['title']"></app-innerbanner>

        </div>
    </div>
</div>
<div class="container-outer" *ngIf="checkSection">
    <!-- Event Datails Content-->
    <div class="static-section-panel bb-0">
        <div class="static-section-lpanel">
            <!-- <div class="row mar-zero">
            <div class="col s12 pad-zero">
               <div class="left">
                  <span class="static-section-ltitle">Upcoming<br>Events</span>
               </div>
               <div class="right">
                  <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
               </div>
            </div>
         </div> -->

        </div>
        <div class="static-section-rpanel-card add-block-box pb-0">
            <div class="event-home">
                <div class="event-header">
                    <div class="event-banner">
                        <img src="{{topImage.thumbnail}}">
                    </div>
                    <div class="event-title">
                        <div class="location">
                            <p class="adbanner-contact">
                                <ng-container *ngIf="!topImage.endDate && topImage.date">
                                    <span class="adbanner-txt-2" *ngIf="topImage?.advertisement !== true">{{topImage.date | date}}</span><br>
                                </ng-container>
                                <ng-container *ngIf="topImage.endDate && topImage.date">
                                    <span class="adbanner-txt-2" *ngIf="topImage?.advertisement !== true">Event starts - {{topImage.date | date}} to {{topImage.endDate | date}}</span><br>
                                </ng-container>
                                
                                <span class="adbanner-txt-1" *ngIf="topImage?.advertisement !== true">{{topImage.province| replace : '_' : ' '}},&nbsp;{{topImage.city}}</span>
                            </p>
                        </div>
                        <h2>{{topImage.title}}</h2>
                        <div>
                            <p *ngIf="topImage?.advertisement === true">For further details contact</p>
                            <a class="adbanner-txt-2 text-decoration-underline" *ngIf="topImage?.advertisement === true" href="mailto:{{topImage.ad_email}}">{{topImage?.ad_email}}</a>
                        </div>
                        <div class="pdf-btn" *ngIf="topImage.attachment !== null ">
                            <app-primarybutton (click)="showPdf(topImage.attachment)" buttonName="Download PDF"></app-primarybutton>
                        </div>
                    </div>
                </div>
                <div class="event-runtext">
                    <p [innerHTML]="topImage.desc">{{topImage.desc}}</p>
                </div>
                <div class="move-next">
                    <div class="prev">
                        <a class="waves-effect btn-lg"  (click)="loadPrev()"><i class="mdi mdi-arrow-left"></i>&nbsp;&nbsp;Prev</a>
                    </div>
                    <div class="next">
                        <a class="waves-effect btn-lg" (click)="loadNext()">Next &nbsp;&nbsp;<i class="mdi mdi-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Event Datails Content End-->
    <!-- Event List Content-->
    <div class="static-section-panel">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle"> {{pageData.eventsubtitle}}<br>
                            {{pageData.eventsectiontitle}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <!--<div class="event-ldmr-panel"><a class="waves-effect btn-lg">Load More events</a></div>-->
        </div>
        <div class="static-section-rpanel-card add-block-box pt-0 short-newsdec">
            <div class="row mar-zero event_container">
                <div class="col s12 m6 l3 pad-zero hoverable" *ngFor="let List of displayList | slice:0:4;let i =index; ">

                    <div class="pray-trendcol event-height card-sdw-btm">
                        <div class="praytrend-image">
                            <img src="{{List.thumbnail}}" class="responsive-img" alt="Event_Thumbnail">
                        </div>
                        <p class="mar-zero txt-13 clr-777" [innerHTML]="List.desc| sliceDescription">{{List.desc}} </p>
                      <div class="event-listmore">
                        <div class="adbanner-contact" *ngIf="List?.advertisement === false">
                            <span class="adbanner-txt-2">{{List.date| date}} {{List.endDate ? "-" + (List.endDate | date) : ''}}</span><br>
                            <span class="adbanner-txt-1">{{List.province}},&nbsp;{{List.city}}</span>
                        </div>
                        <div class="adPosition pb-2" *ngIf="List?.advertisement === true">
                            <p class="pt-0">For further details contact</p>
                             <span  class="adbanner-txt-2">{{List.ad_email}}</span>
                        </div>
                        <app-primarybutton buttonName="details" (click)="link(List.id,i)"></app-primarybutton>
                      </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="cen-btn">
            <a class="waves-effect btn-lg" routerLink="/events">{{pageData.allevents}}</a>
        </div>
    </div>
    <!--Event List End-->
</div>
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>