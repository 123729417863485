import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PageService } from 'src/services/page.service';
import { StorageService } from 'src/services/storage.service';
import { EventsListService } from 'src/services/events.service';
import { NotificationService } from 'src/services/notification.service';


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
})
export class EventsComponent implements OnInit {
  bannertitle = 'Events';
  pageData: any;
  template: any;
  checkSection: boolean = false;
  bannerDetails: any;
  biblequotes: any;
  pagetitle: any;
  listEvent: boolean = false;
  checkRes: boolean = false;
  province: string = null;
  output: any;
  eventList: any = [];
  events = [];
  count = 0;
  totalPages: any;
  data: any;
  bannerDetailsTwo: any;
  pageDataTwo: any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  constructor(
    private pageservice: PageService,
    public storageService: StorageService,
    public eventsList: EventsListService,
    private notify: NotificationService

  ) {
    this.pageservice.getPage('61922f0d14c4a775aafcdd80').subscribe((res) => {

      this.pageData = res.result.templateData;
      this.pagetitle = res.result.title;

      this.bannerDetails = [
        {
          bibleQuotes: this.pageData.bannerquote,
          verses: this.pageData.bannerversus,
          file: this.pageData.bannerimage ? this.pageData.bannerimage : '../../../assets/images/event-bg.jpg',
        },
      ];


      this.checkSection = true;
    });
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res => {

      this.pageDataTwo = res.result.templateData;
      this.bannerDetailsTwo = { slidertitle1: this.pageDataTwo.riseupcanada, slidertitle2: this.pageDataTwo.prayforournation, sliders: this.pageDataTwo.banner }
      this.sectionDetails = { title: this.pageData.section2title, description: this.pageData.section2description, audio: this.pageData.section2audio, pdf: this.pageData.section2pdf }
      this.checkSection = true;
    })
  }

  getData(event: any) {
    if (event.province != null) {
      this.province = event.province;
    }
    this.eventsList.getFilteredData(this.province, event.city, event.pincode).subscribe((Res) => {
      this.eventList = Res['result'].list;
      this.totalPages = Res['result'].info.totalPages;
      this.listEvent = this.totalPages > 1 ? true : false;
    });
  }

  getEvents(i) {
    this.checkRes = false;
    this.eventsList.getEvents(i).subscribe((data: any) => {
      this.eventList = data['result'].list;
      this.checkRes = true;
      this.totalPages = data['result'].info.totalPages;
      this.listEvent = this.totalPages > 1 ? true : false;
    }, (err) => {
      this.notify.showError(err)
    });
  }

  loadMore() {
    this.count = this.count + 1;
    if (this.totalPages > this.count) {
      this.eventsList.getSearch(this.province, this.count).subscribe((data: any) => {
        var list = data['result'].list;
        list.map((o) => {
          this.eventList.push(o);
        });
        this.listEvent = this.totalPages > this.count + 1 ? true : false;
        this.checkRes = true;
      });
    } else {
      this.listEvent = false;
    }
  }

  ngOnInit(): void {
    this.province = localStorage.getItem('selectedRegionName') == null ? null : localStorage.getItem('selectedRegionName').includes('Qu') ? "Quebec" : localStorage.getItem('selectedRegionName').replace(/"/g, "").replace(/ /g, "_");
    this.getEvents(this.province);
  }
}
