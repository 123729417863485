<!--Menu-->
<div class="main-menu" *ngIf="checkSection">
    <div class="nav-wrapper-top navbar-fixed-top pos-relative">
        <div class="right hide-on-med-and-down">
            <div class="pos-relative left">
                <a href="javascript:void(0);" class="share-icon dropdown-button" data-activates="share-submenu"><i
                        class="mdi mdi-share-variant"></i></a>
            </div>
            <ng-container *ngIf="!isLogin">
                <a class="top-login-icon log_open waves-effect waves-light" id="loginicon" (click)="toggleShow()"><i
                        class="mdi mdi-account-outline"></i></a>
            </ng-container>
            <ng-container *ngIf="isLogin">
                <a class="top-login-icon waves-effect waves-light dropdown-button" (click)="showLogout()" data-activates="Logout-submenu" id="logouticon"><span id="loginusername" class="login-title"></span><i class="mdi mdi-logout"></i>
                    <div id="Logout-submenu" class="dropdown-content Logout-submenu-drop"  *ngIf="logOutShow">
                        <ul class="submenu-drop-list">
                            <li><a href="javascript:void(0)" routerLink="/changepassword">Change Password</a></li>
                <li><a href="javascript:void(0)" (click)="logout()">Logout</a></li>

                </ul>
        </div>
        </a>

        </ng-container>
        <div class="dropDownList Logout-submenu-drop" *ngIf="logOutShow">
            <ul class="submenu-drop-list">
                <li><a href="javascript:void(0)" routerLink="/changepassword">Change Password</a></li>
                <li><a href="javascript:void(0)" (click)="logout()">Logout</a></li>

            </ul>
        </div>
        <!-- <a class="donate-btn-ppl" href="https://www.sandbox.paypal.com/cgi-bin/webscr" target="_top">
            <form action="" method="post" class="donate-btnalign">
                <input type="hidden" name="cmd" value="_s-xclick">
                <input type="hidden" name="hosted_button_id" value="ZZN6HSLLWBY5W">
                <input type="image" class="waves-effect waves-light" src="../../../assets/images/donate-btn.png" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
            </form>
        </a> -->
        <a class="donate-btn-ppl" href="https://www.paypal.com/donate/?hosted_button_id=C2LNWB4RXJD9U" target="_blank">

            <!-- <form action="" class="donate-btnalign" method="post" >
<input type="hidden" name="hosted_button_id" value="C2LNWB4RXJD9U" /> -->
            <!-- <input type="image" class="waves-effect waves-light"  border="0"name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" /> -->
            <!-- </form> -->
            <span class="slider-btn-lg waves-effect waves-light">Donate</span>

            <!-- <img src="../../../assets/images/donate-btn.png"> -->
        </a>
    </div>
    <div class="right hide-on-med-and-down">
        <ul class="nav-menu">
            <li class="home-icon"><a routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><i class="mdi mdi-home"></i></a></li>
            <li><a href="" routerLink="/aboutus" routerLinkActive="active">{{pageData.aboutnavbar}}</a></li>
            <li><a href="" routerLink="/province" routerLinkActive="active">{{pageData.provincenavbar}}</a></li>
            <li><a href="javascript:void(0);" class="dropdown-button" data-activates="partners-submenu">{{pageData.partnersnavbar}}</a></li>
            <li><a href="javascript:void(0);" class="dropdown-button" data-activates="resources-submenu">{{pageData.resourcesnavbar}}</a></li>
            <li><a href="" routerLink="/events" routerLinkActive="active">{{pageData.eventsnavbar}}</a></li>
            <li><a href="" routerLink="/news" routerLinkActive="active">{{pageData.newsnavbar}}</a></li>
            <li><a href="javascript:void(0);" class="dropdown-button" data-activates="registration-submenu">{{pageData.registrationnavbar}}</a></li>
            <li><a href="" routerLink="/contactus" routerLinkActive="active">{{pageData.contactnavbar}}</a></li>
        </ul>
    </div>
</div>

<div id="partners-submenu" class="dropdown-content partner-submenu-drop">
    <ul class="submenu-drop-list">
        <li><a href="" routerLink="/prayerpartners">{{pageData.partnerssubnavbar1}}</a></li>
        <li><a href="" routerLink="/partnerbenefits">{{pageData.partnerssubnavbar2}}</a></li>
        <li><a href="" routerLink="/partnerorganization">Partnering Organization</a></li>
        <!-- <li><a href="" routerLink="/registration">Registration</a></li> -->
    </ul>
</div>
<div id="resources-submenu" class="dropdown-content resources-submenu-drop">
    <ul class="submenu-drop-list">
        <li><a href="" routerLink="/whypray">{{pageData.resourcessubnavbar1}}</a></li>
        <li><a href="" routerLink="/howtopray">{{pageData.resourcessubnavbar2}}</a></li>
        <li><a href="" routerLink="/prayerresource">{{pageData.resourcessubnavbar3}}</a></li>
        <li><a href="" routerLink="/prayerpoints">{{pageData.resourcessubnavbar4}}</a></li>
    </ul>
</div>
<div id="registration-submenu" class="dropdown-content partner-submenu-drop">
    <ul class="submenu-drop-list">
        <li><a href="" routerLink="/individual">{{pageData.registrationsubnavbar1}}</a></li>
        <li><a href="" routerLink="/group">{{pageData.registrationsubnavbar2}}</a></li>
        <li><a href="" routerLink="/prayerwarrior">{{pageData.registrationsubnavbar3}}</a></li>
        <li><a href="" routerLink="/kneelingarmy">Kneeling Army</a></li>
    </ul>
</div>
<div id="share-submenu" class="dropdown-content share-submenu-drop">
    <ul class="submenu-drop-list">
        <li><a href="https://www.facebook.com/riseupcanada.ca" target="_blank" class="txt-1-2rem pad-zero"><i
                        class="mdi mdi-facebook"></i></a></li>
        <li><a href="https://twitter.com/riseupcan" target="_blank" class="txt-1-2rem pad-zero"><i
                        class="mdi mdi-twitter"></i></a></li>
        <li><a href="mailto:admin@riseupcanada.ca" target="_top" class="txt-1-2rem pad-zero"><i
                        class="mdi mdi-email"></i></a></li>
        <li><a href="https://www.youtube.com/channel/UCsvlUcixJEwbjrdQcbY52sA" target="_blank" class="txt-1-4rem pad-zero"><i class="mdi mdi-youtube-play"></i></a></li>
    </ul>
</div>
</div>
<!--Menu-->
<a class="menu-icon button-collapse hide-on-large-only right" data-activates="mobile-demo" *ngIf="checkSection">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
</a>
<div class="mobile_login_user">
    <ng-container *ngIf="!isLogin">
        <a class="top-login-icon log_open waves-effect waves-light" id="loginicon" (click)="toggleShow()"><i
                class="mdi mdi-account-outline"></i></a>
    </ng-container>
    <ng-container *ngIf="isLogin">
        <a class="top-login-icon waves-effect waves-light dropdown-button" data-activates="Logout-submenu" id="logouticon"><span id="loginusername" class="login-title"></span><i class="mdi mdi-logout"></i>
            <div id="Logout-submenu" class="dropdown-content Logout-submenu-drop">
                <ul class="submenu-drop-list">
                    <li><a href="javascript:void(0)" routerLink="/changepassword">Change Password</a></li>
        <li><a href="javascript:void(0)" (click)="logout()">Logout</a></li>

        </ul>
</div>
</a>
</ng-container>
</div>
<div class="mobile-menu" *ngIf="checkSection">
    <div class="menu-panel">
        <ul class="mob-menu-list">
            <li><a href="" routerLink="" class="loginopcls">Home</a></li>
            <li><a href="" routerLink="/aboutus" class="loginopcls">{{pageData.aboutnavbar}}</a></li>
            <li><a href="" routerLink="/province" routerLinkActive="active" class="loginopcls">{{pageData.provincenavbar}}</a></li>
            <li>
                <a class="dropdown-button dropdown-arrow" href="javascript:;">{{pageData.partnersnavbar}}</a>
                <ul id='dropdown1' class='drop-show'>
                    <li><a href="" routerLink="/prayerpartners" class="loginopcls">{{pageData.partnerssubnavbar1}}</a>
                    </li>
                    <li><a href="" routerLink="/partnerbenefits" class="loginopcls">{{pageData.partnerssubnavbar2}}</a>
                    </li>
                </ul>
            </li>
            <li>
                <a class="dropdown-button2 dropdown-arrow" href="javascript:;">{{pageData.resourcesnavbar}}</a>
                <ul id='dropdown1' class='drop-show2' style="display: none;">
                    <li><a href="" routerLink="/whypray" class="loginopcls">{{pageData.resourcessubnavbar1}}</a></li>
                    <li><a href="" routerLink="/howtopray" class="loginopcls">{{pageData.resourcessubnavbar2}}</a></li>
                    <li><a href="" routerLink="/prayerresource" class="loginopcls">{{pageData.resourcessubnavbar3}}</a>
                    </li>
                    <li><a href="" routerLink="/prayerpoints" class="loginopcls">{{pageData.resourcessubnavbar4}}</a>
                    </li>
                </ul>
            </li>
            <li><a href="" routerLink="/events" class="loginopcls">{{pageData.eventsnavbar}}</a></li>
            <li><a href="" routerLink="/news" class="loginopcls">{{pageData.newsnavbar}}</a></li>
            <li>
                <a class="dropdown-button3 dropdown-arrow" href="javascript:;">{{pageData.registrationnavbar}}</a>
                <ul id='dropdown1' class='drop-show3' style="display: none;">
                    <li><a href="" routerLink="/individual" class="loginopcls">{{pageData.registrationsubnavbar1}}</a>
                    </li>
                    <li><a href="" routerLink="/group" class="loginopcls">{{pageData.registrationsubnavbar2}}</a></li>
                    <li><a href="" routerLink="/prayerwarrior" class="loginopcls">{{pageData.registrationsubnavbar3}}</a></li>
                    <li><a href="" routerLink="/kneelingarmy"  class="loginopcls">Kneeling Army</a></li>
                </ul>
            </li>
            <li><a href="" routerLink="/contactus" class="loginopcls">{{pageData.contactnavbar}}</a></li>
            <!-- <li id="loginli"><a class="log_open loginopcls" (click)="toggleShow()">Login</a></li>
            <li id="logoutli" style="display:none"><a href="#home" onclick="logout()">Logout</a></li> -->
        </ul>
    </div>
</div>
<!-- <app-loginmodule></app-loginmodule> -->
<div id="login_panel" class="z-depth-5" *ngIf="show">
    <div class="card mar-zero box-shadow-none">
        <span class="pro-exp-close log_open" (click)="show_out()"><i class="mdi mdi-close"></i></span>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="card-content left">
                <div class="logttl-panel">
                    <p class="login-title">Login into Your Account</p>
                    <p class="log-subtle">We protect your information</p>
                </div>
                <div class="sign-inputs">
                    <input type="text" placeholder="Email Address" id="rucemail" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <span *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <span class="error_msg" *ngIf="f.email.errors.required">Email is required</span>
                    </span>
                </div>
                <div class="sign-inputs alignIcons ">
                    <div class="pwsshow-icons">
                    </div>
                    <input type="password" placeholder="Password" id="rucpassword" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" appPassword />

                    <span *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <span class="error_msg" *ngIf="f.password.errors.required">Password is required</span>
                    </span>
                </div>
                <div class="sign-inputs">
                    <!-- <a class="waves-effect login-btn">Login</a> -->
                    <button [disabled]="loading" class="btn btn-primary waves-effect login-btn p-0">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Login
                    </button>
                </div>
                <a class="activator clr-777 txt-13 right-align cur-pointer">Forgot password?</a>
            </div>
        </form>
        <div class="card-reveal">
            <form [formGroup]="forgotPass" (ngSubmit)="onSend()">
                <p class="card-title mar-t-zero">
                    <span class="login-title">Forgot password?</span><br>
                    <span class="log-subtle">Just enter the email address you registered with. We'll send you an email
                        with
                        password reset instructions</span>
                    <span class="pro-exp-close"><i class="mdi mdi-close"></i></span>
                </p>
                <div class="sign-inputs">
                    <input type="text" formControlName="forgotEmail" placeholder="Email Address" id="forgotInput" [ngClass]="{ 'is-invalid': submitted && fp.forgotEmail.errors }" />
                    <div *ngIf="submitted && fp.forgotEmail.errors" class="invalid-feedback">
                        <div *ngIf="fp.forgotEmail.errors.required">Email is required</div>
                        <div *ngIf="fp.forgotEmail.errors.email">Email is invalid</div>
                    </div>
                </div>
                <div class="sign-inputs">
                    <button type="submit" class="waves-effect login-btn">Send Email</button>
                </div>
            </form>
        </div>
    </div>
</div>
