import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventsListService } from 'src/services/events.service';
import { NavigationEnd, Router } from '@angular/router';
import { PageService } from 'src/services/page.service';
@Component({
  selector: 'app-eventsdetails',
  templateUrl: './eventsdetails.component.html',
  styleUrls: ['./eventsdetails.component.css']
})
export class EventsdetailsComponent implements OnInit {
  bannertitle = "Events"
  id: string;
  count = 0;
  topImage = { city: "", desc: '', thumbnail: '', date: '', endDate: '', title: '', attachment: '', advertisement:'', ad_email:'', province:'' };
  eventList: any = [];
  isShown: boolean;
  public getId;
  public date;
  public endDate;
  public thumbnail;
  public city;
  public desc;
  public topId;
  public attachment;
  public province;
  public advertisement;
  public ad_email;
  displayList: any;
  prevBtn: boolean = false;
  nextBtn: boolean = true;
  bannerDetailsTwo: any;
  pageDataTwo:any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  pageData: any;
  pagetitle: any;
  bannerDetails:any;
  checkSection: boolean = false;
  constructor(private route: ActivatedRoute, private eventService: EventsListService, private router: Router,private pageservice:PageService) {
    this.pageservice.getPage('61af18e4f9c6da0d767243cb').subscribe(res => {
      this.pageData = res.result.templateData;
      this.bannerDetails = [{ bibleQuotes: this.pageData.bannerquote, verses: this.pageData.bannerversus, file: this.pageData.bannerimage ? this.pageData.bannerimage : '../../../assets/images/event-bg.jpg' }]
      this.checkSection = true;
    })
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res =>{
      this.pageDataTwo=res.result.templateData;
      this.bannerDetailsTwo = {slidertitle1:this.pageDataTwo.riseupcanada,slidertitle2:this.pageDataTwo.prayforournation,sliders:this.pageDataTwo.banner}
      this.sectionDetails = {title:this.pageDataTwo.section2title,description:this.pageDataTwo.section2description,audio:this.pageDataTwo.section2audio,pdf:this.pageDataTwo.section2pdf}
      this.checkSection=true;
    })
  }

  eventsdetails = [
    {
      title: "Events",
      description: "",
      slug: "",
      image: "",
      htmlContent: "",
      template: "",
      template_field: [{
        "banner": [
          {
            "bibleQuotes": "So I sought for a man among them who would make a wall, and stand in the gap before Me on behalf of the land (Canada), that I should not destroy it; but I found no one.",
            "verses": "EZE 22:30",
            "file": "../../../assets/images/event-bg.jpg",
          },

        ],

      }]
    }
  ]
  ngOnInit(): void {
    this.id =  this.route.snapshot.paramMap.get('id')
    this.getId = this.id;
    this.getEvent();
  }


  getEvent() {
    this.eventService.getSingleEvent(this.getId).subscribe((data: any) => {
      this.topImage = {
        date: data.result.date,
        endDate: data.result.endDate,
        city: data.result.city,
        province:data.result.province,
        thumbnail: data.result.thumbnail,
        desc: data.result.desc,
        title: data.result.title,
        attachment: data.result.attachment,
        advertisement:data.result.advertisement,
        ad_email: data.result.ad_email
      }

      var y = localStorage.getItem('selectedRegionName');
      var x = y.replace(/"/g, "");
      var z = x.replace(" ", "_");
      this.topId = data.result.id;
      this.eventService.getEvents(z).subscribe((data: any) => {
        this.eventList = data['result'].list;
        this.displayList = this.eventList.filter(data => data.id !== this.topId)
      });
    });

  }
  showPdf(char) {
    // window.open('');
    window.open(char);


  }

  loadPrev() {
    // this.prevBtn = this.count === 0 ? false : true;
    // this.nextBtn = this.count === 8 ? false : true;
    this.count = this.count - 1;
    this.topImage = this.eventList[this.count];
    window.scrollTo(0, 0);
  }

  loadNext() {
    // this.prevBtn = this.count === 0 ? false : true;
    // this.nextBtn = this.count === 8 ? false : true;
    this.count = this.count + 1;
    this.topImage = this.eventList[this.count];
    window.scrollTo(0, 0);


  }
  link(id, index) {
    this.topImage = this.displayList[index]
    this.displayList = this.eventList.filter(data => data.id !== id)
    window.scrollTo(0, 0);
  }

}


