<div class="row mar-zero" *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <!-- <app-logo-layout></app-logo-layout> -->
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="registration-panel" style="background: #653935 url({{pageData.bannerimage}}) center no-repeat;
                    background-size: cover;">
                        <app-bannerbiblequote [biblequotes]="pageData.bannerquote" [verses]="pageData.bannerversus">
                        </app-bannerbiblequote>
                        <div class="static-top-title">
                            <span>{{pagetitle}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-outer" *ngIf="checkSection">
    <!--About Riseup Nation-->
    <div class="static-section-panel" id="registrationpage">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">{{pageData.sidebartitle}}</span>
                    </div>
                    <div class="right">
                        <span class="praylead-section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
                <div class="col s12 pad-zero">
                    <div class="reg-sub-txt">
                        <ul id="step1">
                            <li [innerHTML]="description"></li>
                        </ul>
                        <!-- <ul id="step2" style="display:none">
                            <li>We do not need your legal name but name you prefer</li>
                            <li>We do not need your address</li>
                            <li>Email is essential to send you the prayer points</li>
                            <li>We need your postal code to connect other in your area (If you wish)</li>
                            <li>We like to know about your church and the lead pastor</li>
                            <li>We like to inform any prayer seminar in your area</li>
                            <li>Thank you for joining our team and the army of God</li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="static-section-rpanel">
            <div class="pray-regpanel">
                <div class="row mar-zero">
                    <div class="col s12 pad-zero">
                        <p class="static-more-action-tle">{{pageData.form1title}}</p>
                        <p>{{pageData.formdescription}}</p>
                        <form [formGroup]="createGroupForm" id="my-form">
                            <div class="row">
                                <div class="prayer-stepcont-panel" id="">
                                    <div class="reg-pray-card mt-0">
                                        <div class="row mar-zero">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl">Create Group Name<span
                                                        style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero">
                                                <input type="text" class="persinfo-fld" id="rname" formControlName="groupName" [ngClass]="{ 'is-invalid': submitted && f.groupName.errors }">
                                                <div *ngIf="submitted && f.groupName.errors" class="alert alert-danger">
                                                    <div class="validation-errors" *ngIf="f.groupName.errors.required">
                                                        Group Name is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mar-zero">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl">E-mail of Group Leader<span
                                                        style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero">
                                                <input type="text" class="persinfo-fld" id="remail" formControlName="emailLeader" [ngClass]="{ 'is-invalid': submitted && f.emailLeader.errors }">
                                                <div *ngIf="submitted && f.emailLeader.errors" class="alert alert-danger">
                                                    <div class="validation-errors" *ngIf="f.emailLeader.errors.required"> Group Leader Email is required
                                                    </div>


                                                    <div class="validation-errors" *ngIf="createGroupForm.get('emailLeader').errors.pattern">
                                                        Email is not valid
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mar-zero p-prayer-form">
                                            <div class="col s12 m5 l5 pad-zero ">
                                                <span class="persinfo-lbl">Postal Code<span
                                                        style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero">
                                                <!-- <ng-select [allowClear]="true"
                                                formControlName="postalCode"
                                                [items]="items"
                                                [disabled]="disabled"
                                                (data)="refreshValue($event)"
                                                (selected)="select($event)"
                                                (removed)="removed($event)"
                                                (typed)="typed($event)"
                                                >

                                    </ng-select> -->
                                                <!-- <app-autocomplete></app-autocomplete> -->
                                                <span id="spnauto">
                                                    <input type="text" (click)="showList()" (keyup)="autoComplete($event)" autocomplete="off"
                                                        class="form-control frm  m-2"
                                                        id="userIdFirstWay" list="dynmicUserIds"
                                                        formControlName="postalCode"
                                                        [ngClass]="{ 'is-invalid': submitted && f.postalCode.errors }" />
                                                    <ul class="list-group" id="ulautocomplete" *ngIf="openCountryList">
                                                        <li *ngFor="let item of autoCompletArr" class="list-group-item"
                                                            (click)="selectAddress(item)"> <a
                                                                class="card-link  handcursor">{{item.Next == "Find" ?
                                                                item.Description : item.Text}}</a></li>
                                                    </ul>
                                                    <div *ngIf="submitted && f.postalCode.errors"
                                                        class="alert alert-danger">
                                                        <div class="validation-errors"
                                                            *ngIf="f.postalCode.errors.required">
                                                            Postal Code is required</div>
                                                    </div>
                                                </span>
                                                <!-- <input type="text" class="persinfo-fld" id="rpostalcode" formControlName="postalCode" [ngClass]="{ 'is-invalid': submitted && f.postalCode.errors }">
                                                <div *ngIf="submitted && f.postalCode.errors" class="alert alert-danger">
                                                    <div class="validation-errors" *ngIf="f.postalCode.errors.required">
                                                        Postal Code is required</div>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="row mar-zero">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl">Address<span
                                                        style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero">
                                                <input type="text" class="persinfo-fld" id="raddress" formControlName="address" [readonly]="true" [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
                                                <div *ngIf="submitted && f.address.errors" class="alert alert-danger">
                                                    <div class="validation-errors" *ngIf="f.address.errors.required">
                                                        Address is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mar-zero">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl">City<span style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero">
                                                <input type="text" class="persinfo-fld" id="rcity" formControlName="city" [readonly]="true" [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
                                                <div *ngIf="submitted && f.city.errors" class="alert alert-danger">
                                                    <div class="validation-errors" *ngIf="f.city.errors.required">City is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mar-zero">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl">Province<span
                                                        style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero">
                                                <input type="text" class="persinfo-fld" id="rprovince" formControlName="province" [readonly]="true" [ngClass]="{ 'is-invalid': submitted && f.province.errors }">
                                                <div *ngIf="submitted && f.province.errors" class="alert alert-danger">
                                                    <div class="validation-errors" *ngIf="f.province.errors.required">
                                                        Province is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="row mar-zero">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl">Church Name<span style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero">
                                                <input type="text" class="persinfo-fld" id="rprovince">
                                            </div>
                                        </div>
                                        <div class="row mar-zero">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl">Poster's Name<span style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero">
                                                <input type="text" class="persinfo-fld" id="rprovince">
                                            </div>
                                        </div>
                                        <div class="row mar-zero">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl">Poster's Phone Number<span style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero">
                                                <input type="text" class="persinfo-fld" id="rprovince">
                                            </div>
                                        </div> -->
                                        <div class="row mar-zero p-prayer-form">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl"> Group Type<span
                                                        style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero">
                                                <!-- <select class="dropdownName" formControlName="churchGroup">
                                                    <option disabled>Group Type</option>
                                                    <option *ngFor="let item of this.dataList" value="{{item.name}}">
                                                        {{item.name}}
                                                    </option>
                                                </select> -->
                                                <ng-select [allowClear]="true" formControlName="churchGroup" [disabled]="disabled" (data)="refreshValue($event)" (selected)="select($event)" (typed)="typed($event)">
                                                    <ng-option *ngFor="let item of this.dataList" value="{{item.name}}">
                                                        <div>
                                                            {{item.name}}
                                                        </div>
                                                    </ng-option>
                                                </ng-select>
                                                <div *ngIf="submitted && f.churchGroup.errors" class="alert alert-danger">
                                                    <div class="validation-errors" *ngIf="f.churchGroup.errors.required">Group is required</div>
                                                </div>
                                                <!-- <input type="text" class="persinfo-fld" id="rprovince"
                                                    formControlName="churchGroup"
                                                    [ngClass]="{ 'is-invalid': submitted &amp;&amp;createGroupForm.controls.churchGroup.errors }">
                                                <div *ngIf="createGroupForm.controls['churchGroup'].invalid && (createGroupForm.controls['churchGroup'].dirty || createGroupForm.controls['churchGroup'].touched)"
                                                    class="alert alert-danger">
                                                    <div class="validation-errors"
                                                        *ngIf="createGroupForm.controls['churchGroup'].errors.required">
                                                         Church Group  is required
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="row mar-zero">
                                            <p style="color:red;font-size:11px">* Mandatory Fields</p>
                                        </div>
                                        <div *ngIf="submitForm" id="lock-modal">
                                            <div class="loader"></div>
                                        </div>
                                        <div class="center-align mt-15">
                                            <!-- <button class="btn-reg" type="button" onclick="show_prev('choose_prayer','bar1');"><span class="btn-reg-prearrow"><i class="mdi mdi-arrow-left"></i></span> Back</button> -->
                                            <button class="btn-reg" type="button" (click)="SaveChanges()">SIGN UP<span
                                                    class="btn-reg-nextarrow"><i
                                                        class="mdi mdi-arrow-right"></i></span></button>
                                                        <button class="btn-reg resetBtn" type="button" (click)="reset()">Reset</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="prayer-stepcont-panel" id="final_information" *ngIf="openModal" style="display: block;">
                                    <div class="reg-pray-card">
                                        <div class="pray-success-icon"><i class="mdi mdi-checkbox-marked-outline"></i>
                                        </div>
                                        <p class="step1-success-title">Successfully Registered</p>
                                        <p class="step1-success-subtle">
                                            You are become a prayer partner now!<br>You will receive all the sensitive prayer points <br>and pray for the nation!
                                        </p>
                                        <p class="step1-success-subtle">God Bless!</p>
                                         <div class="center-align">
                                            <button class="btn-reg" type="button" onclick="loadMenu('prayers')">view prayers</button>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </form>
                    </div>
                </div>
                <!-- add new -->
                <!-- add new -->
                <div class="mt-0 ">

                    <div class="card-body prayer-stepcont-panel">
                        <div class="colMapSec">
                            <p class="static-more-action-tle">INVITE MEMBERS</p>
                            <div class="reg-pray-card mt-0">

                                <form [formGroup]="form">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl">Group Name<span
                                                        style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero p-prayer-form">
                                                <!-- <input type="text" class="persinfo-fld" id="rprovince" > -->
                                                <select [(ngModel)]="selected" formControlName="groupId" name="status"
                                                    placeholder="select" (ngModelChange)="onOptionsSelected()"
                                                    class="dropdownName">
                                                    <optgroup>
                                                        <option style="color: #fff;" *ngFor="let List of groupList"
                                                            [ngValue]="List.id">{{List.groupName}}</option>
                                                    </optgroup>
                                                </select>
                                                <!-- <ng-select [(ngModel)]="selected" [allowClear]="true" formControlName="groupId">
                                                    <ng-option *ngFor="let List of groupList" [ngValue]="List.id">
                                                        <div>
                                                            {{List.groupName}}
                                                        </div>
                                                    </ng-option>
                                                </ng-select> -->
                                                <div *ngIf="submittedData && e.groupId.errors" class="alert alert-danger">
                                                    <div class="validation-errors" *ngIf="e.groupId.errors.required">
                                                        Group is required</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div formArrayName="inviteMembers" *ngFor="let item of form.controls['inviteMembers'].controls; let i = index">
                                        <div [formGroupName]="i">
                                            <div class="col addnew-btn">
                                                <button *ngIf="form.controls.inviteMembers.controls.length > 1" class="btn-reg" type="button" (click)="removeItem(i)">Remove</button>&nbsp;
                                                <button type="button" class="btn-reg" (click)="addNext()">Add
                                                    New</button>
                                            </div>
                                            <div class="row mar-zero">
                                                <div>
                                                    <div class="col s12 m5 l5 pad-zero">
                                                        <span class="persinfo-lbl">First Name<span
                                                                style="color:red;">*</span></span>
                                                    </div>
                                                    <div class="col s12 m7 l7 pad-zero">
                                                        <input type="text" class="persinfo-fld" id="rprovince" formControlName="firstName">
                                                        <br />
                                                        <label *ngIf="inviteMembers.controls[i].get('firstName')?.errors?.required && isValidFormSubmitted != null && !isValidFormSubmitted" class="alert alert-danger" style="color:red;">
                                                            First Name is required.
                                                        </label>
                                                    </div>

                                                </div>
                                                <div>
                                                    <div class="col s12 m5 l5 pad-zero">
                                                        <span class="persinfo-lbl">Last Name<span
                                                                style="color:red;">*</span></span>
                                                    </div>
                                                    <div class="col s12 m7 l7 pad-zero">
                                                        <input type="text" class="persinfo-fld" id="rprovince" formControlName="lastName">
                                                        <br />
                                                        <label *ngIf="inviteMembers.controls[i].get('lastName')?.errors?.required && isValidFormSubmitted != null && !isValidFormSubmitted" class="alert alert-danger" style="color:red;">
                                                            Last Name is required.
                                                        </label>
                                                    </div>

                                                </div>
                                                <div>
                                                    <div class="col s12 m5 l5 pad-zero">
                                                        <span class="persinfo-lbl">E-mail<span
                                                                style="color:red;">*</span></span>
                                                    </div>
                                                    <div class="col s12 m7 l7 pad-zero">
                                                        <input type="text" class="persinfo-fld" id="rprovince" formControlName="email">
                                                        <br />
                                                        <label *ngIf="inviteMembers.controls[i].get('email')?.errors?.required && isValidFormSubmitted != null && !isValidFormSubmitted" class="alert alert-danger" style="color:red;">
                                                            Email is required.
                                                        </label>
                                                        <label *ngIf="inviteMembers.controls[i].get('email')?.errors?.pattern" style="color:red;">
                                                            Email is not valid
                                                        </label>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <!-- <div *ngIf="onSubmit" id="lock-modal">
                                                        <div  class="loader"></div>
                                                       </div> -->
                                    <div class="center-align">

                                        <button class="btn-reg" type="button" (click)="submit()">Submit <span
                                                class="btn-reg-nextarrow"><i
                                                    class="mdi mdi-arrow-right"></i></span></button>

                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- <div class="mt-0 ">

                    <div class="card-body prayer-stepcont-panel">
                        <div class="colMapSec">
                            <p class="static-more-action-tle">INVITE MEMBERS</p>
                            <div class="reg-pray-card mt-0">

                                <form [formGroup]="form" (ngSubmit)="submit()">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl">Group Name<span style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero">
                                                <select formControlName="groupName" class="form-control">
                                                    <option>Select Group</option>
                                                    <option *ngFor=""></option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div formArrayName="inviteMembers" *ngFor="let item of form.controls['inviteMembers'].controls; let i = index">
                                        <div [formGroupName]="i">
                                            <div class="col addnew-btn">
                                                <button *ngIf="form.controls.inviteMembers.controls.length > 1" class="btn-reg" type="button" (click)="removeItem(i)">Remove</button>&nbsp;
                                                <button type="button" class="btn-reg" (click)="addNext()">Add New</button>
                                            </div>
                                            <div class="row mar-zero">
                                                <div>
                                                    <div class="col s12 m5 l5 pad-zero">
                                                        <span class="persinfo-lbl">First Name<span style="color:red;">*</span></span>
                                                    </div>
                                                    <div class="col s12 m7 l7 pad-zero">
                                                        <input type="text" class="persinfo-fld" id="rprovince" formControlName="firstName">
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="col s12 m5 l5 pad-zero">
                                                        <span class="persinfo-lbl">Last Name<span style="color:red;">*</span></span>
                                                    </div>
                                                    <div class="col s12 m7 l7 pad-zero">
                                                        <input type="text" class="persinfo-fld" id="rprovince" formControlName="lastName">
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="col s12 m5 l5 pad-zero">
                                                        <span class="persinfo-lbl">E-mail<span style="color:red;">*</span></span>
                                                    </div>
                                                    <div class="col s12 m7 l7 pad-zero">
                                                        <input type="text" class="persinfo-fld" id="rprovince" formControlName="email">
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="center-align">

                                        <button class="btn-reg" type="button" onclick="register();">Submit <span class="btn-reg-nextarrow"><i class="mdi mdi-arrow-right"></i></span></button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div> -->

            </div>
        </div>
        <div>

        </div>
    </div>
</div>
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>
