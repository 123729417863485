import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageService } from 'src/services/page.service';
import { NotificationService } from 'src/services/notification.service';
import { NewsletterService } from 'src/services/newsletter.service';
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  NavigationEnd: any;
  Router: any;
  currentyear: number = new Date().getFullYear();
  pageData: any;
  pagetitle: any;
  newsForm: FormGroup;
  submitted: boolean;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  onActivate(e, outlet) {
    outlet.scrollTop = 0;
  }
  checkSection: boolean = false;
  bannerDetails: any;
  biblequotes: any;
  constructor(private fb: FormBuilder,private notify:NotificationService, private pageservice: PageService, private router: Router,private letterservice:NewsletterService) {

    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res => {
      this.pageData = res.result.templateData;
      this.bannerDetails = { slidertitle1: this.pageData.riseupcanada, slidertitle2: this.pageData.prayforournation, sliders: this.pageData.banner }
      this.sectionDetails = { title: this.pageData.section2title, description: this.pageData.section2description, audio: this.pageData.section2audio, pdf: this.pageData.section2pdf }
      this.checkSection = true;
    })
    this.newsForm = this.fb.group({
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
  }
  get f() { return this.newsForm.controls; }
  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
  sendInbox(){
    this.submitted = true;
    if (this.newsForm.valid) {
      let data = {
        'email': this.newsForm.get('email')?.value,  
      }
      this.letterservice.sendEmail(data).subscribe(data => {
        if (data.status == 200) {
          this.submitted = false;
          this.newsForm.reset();
          this.notify.showSuccess('Newsletter Succesfully Subscribed')
        }
        
      },
      (err) => {
        this.notify.showError(err)
      });
    }
  }

}
