<div class="row mar-zero" *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <!-- <app-headerlogo></app-headerlogo> -->
            <!-- <div class="row mar-zero">
             <div class="spacer-sm"></div>
             <div class="col s12 pad-zero center-align">
                 <img id="reg_province_image" alt="" title="">
             </div>
             <div class="col s12 pad-zero center-align">
                 <div class="static-left-innerttl">
                     <span class="static-left-innerttl-1" id="reg_province_label">Pray for<br>province of</span><br>
                     <span class="static-left-innerttl-2" id="reg_province_name"></span><br>
                     <span class="static-left-innerttl-3" id="reg_province_city"></span>
                 </div>
             </div>
             </div> -->
            <!-- <app-provincemap></app-provincemap> -->
            <!-- <app-logo-layout></app-logo-layout> -->
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="registration-panel" style="background: #653935 url({{pageData.bannerimage}}) center no-repeat;
                    background-size: cover;">
                        <app-bannerbiblequote [biblequotes]="pageData.bannerquote" [verses]="pageData.bannerversus">
                        </app-bannerbiblequote>
                        <div class="static-top-title">
                            <span>{{pagetitle}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-outer" *ngIf="checkSection">
    <!--About Riseup Nation-->
    <div class="static-section-panel" id="registrationpage">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <!-- <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">{{pageData.sidebartitle}}</span>
                    </div>
                    <div class="right">
                        <span class="praylead-section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div> -->
                <div class="col s12 pad-zero">
                    <div class="reg-sub-txt">
                        <ul id="step1">
                            <li [innerHTML]="description"></li>
                        </ul>
                        <!-- <ul id="step2" style="display:none">
                            <li>We do not need your legal name but name you prefer</li>
                            <li>We do not need your address</li>
                            <li>Email is essential to send you the prayer points</li>
                            <li>We need your postal code to connect other in your area (If you wish)</li>
                            <li>We like to know about your church and the lead pastor</li>
                            <li>We like to inform any prayer seminar in your area</li>
                            <li>Thank you for joining our team and the army of God</li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="static-section-rpanel">
            <div class="pray-regpanel">
                <div class="row mar-zero">
                    <div class="col s12 pad-zero">
                        <p class="static-more-action-tle title-align">{{pageData.formtitle}}</p>
                        <form [formGroup]="createKneelingArmyForm">
                            <div class="row">
                                <div class="prayer-stepcont-panel" id="">
                                    <div class="reg-pray-card">
                                        <div class="row mar-zero">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl">First Name<span style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero">
                                                <input type="text" class="persinfo-fld" id="rname" formControlName="firstName" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }">
                                                <div *ngIf="submitted && f.firstName.errors" class="alert alert-danger">
                                                    <div class="validation-errors" *ngIf="f.firstName.errors.required">Name is required
                                                    </div>
                                                    <div class="validation-errors" *ngIf="f.firstName.errors.pattern"> Enter a valid name
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row mar-zero">
                                                <div class="col s12 m5 l5 pad-zero">
                                                    <span class="persinfo-lbl">Last Name<span style="color:red;">*</span></span>
                                                </div>
                                                <div class="col s12 m7 l7 pad-zero">
                                                    <input type="text" class="persinfo-fld" id="rprovince" formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }">
                                                    <div *ngIf="submitted && f.lastName.errors" class="alert alert-danger">
                                                        <div class="validation-errors" *ngIf="f.lastName.errors.required">Last Name is required
                                                        </div>
                                                        <div class="validation-errors" *ngIf="f.lastName.errors.pattern"> Enter a valid name
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mar-zero">
                                                <div class="col s12 m5 l5 pad-zero">
                                                    <span class="persinfo-lbl">Postal Code<span style="color:red;">*</span></span>
                                                </div>
                                                <div class="col s12 m7 l7 pad-zero">
                                                    <span id="spnauto">
                                          <input type="text" (click)="showList()" (keyup)="autoComplete($event)" autocomplete="off"
                                             class="form-control frm  m-2  " id="userIdFirstWay"
                                             list="dynmicUserIds" formControlName="postalCode"
                                             [ngClass]="{ 'is-invalid': submitted && f.postalCode.errors }" />
                                          <ul class="list-group" id="ulautocomplete"  *ngIf="openCountryList">
                                             <li *ngFor="let item of autoCompletArr" class="list-group-item"
                                                (click)="selectAddress(item)"> <a
                                                   class="card-link  handcursor">{{item.Next == "Find" ?
                                                   item.Description : item.Text}}</a></li>
                                          </ul>
                                          <div *ngIf="submitted && f.postalCode.errors" class="alert alert-danger">
                                             <div class="validation-errors" *ngIf="f.postalCode.errors.required">
                                                Postal Code is required</div>
                                          </div>
                                       </span>
                                                </div>
                                            </div>
                                            <div class="row mar-zero">
                                                <div class="col s12 m5 l5 pad-zero">
                                                    <span class="persinfo-lbl">Address<span style="color:red;">*</span></span>
                                                </div>
                                                <div class="col s12 m7 l7 pad-zero">
                                                    <input type="text" class="persinfo-fld" id="raddress" formControlName="address" [readonly]="true" [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
                                                    <div *ngIf="submitted && f.address.errors" class="alert alert-danger">
                                                        <div class="validation-errors" *ngIf="f.address.errors.required">Address is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mar-zero">
                                                <div class="col s12 m5 l5 pad-zero">
                                                    <span class="persinfo-lbl">E-mail<span style="color:red;">*</span></span>
                                                </div>
                                                <div class="col s12 m7 l7 pad-zero">
                                                    <input type="text" class="persinfo-fld" id="remail" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                    <div *ngIf="submitted && f.city.errors" class="alert alert-danger">
                                                        <div class="validation-errors" *ngIf="f.email.errors.required">Email is required
                                                        </div>

                                                        <div class="validation-errors" *ngIf="createKneelingArmyForm.get('email').errors.pattern">
                                                            Email is not valid
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mar-zero">
                                                <div class="col s12 m5 l5 pad-zero">
                                                    <span class="persinfo-lbl">Mobile<span style="color:red;">*</span></span>
                                                </div>
                                                <div class="col s12 m7 l7 pad-zero">
                                                    <input type="text" class="persinfo-fld" id="rprovince" formControlName="mobile" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                                                    <div *ngIf="submitted && f.mobile.errors" class="alert alert-danger">
                                                        <div class="validation-errors" *ngIf="f.mobile.errors.required">Mobile is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mar-zero">
                                                <div class="col s12 m5 l5 pad-zero">
                                                    <span class="persinfo-lbl">Subscription<span style="color:red;">*</span></span>
                                                </div>
                                                <div class="col s12 m7 l7 pad-zero">

                                                    <div class="check_group">
                                                        <div class="mailing_list">
                                                            <input type="checkbox" id="mail" name="mail" formControlName="joinGroup">
                                                            <label for="mail">I Want to be in a group of 12</label>
                                                        </div>

                                                        <div>
                                                            <input type="checkbox" id="sms" name="sms" formControlName="ownGroup">
                                                            <label for="sms">I have my own group of 12</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mar-zero">
                                                <p style="color:red;font-size:11px">* Mandatory Fields</p>
                                            </div>

                                            <div *ngIf="loaderSpin" id="lock-modal">
                                                <div class="loader"></div>
                                            </div>
                                            <div class="center-align mt-15">

                                                <button class="btn-reg" type="button" (click)="savechanges()">{{pageData.formbutton}}<span class="btn-reg-nextarrow"><i
                                             class="mdi mdi-arrow-right"></i></span></button>
                                             <button class="btn-reg resetBtn" type="button" (click)="reset()">Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="prayer-stepcont-panel">
                           <div class="reg-pray-card">
                              <div class="pray-success-icon"><i class="mdi mdi-checkbox-marked-outline"></i></div>
                              <p class="step1-success-title">Successfully Registered</p>
                              <p class="step1-success-subtle">
                                 You are become a prayer partner now!<br>You will receive all the sensitive prayer
                                 points <br>and pray for the nation!
                              </p>
                              <p class="step1-success-subtle">God Bless!</p>
                              <div class="center-align">
                                 <button class="btn-reg" type="button" routerLink="/province">view
                                    prayers</button>
                              </div>
                           </div>
                        </div> -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>