import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsListService {
  data: any;
  sendssg = new Subject<any>();
  constructor(private http: HttpClient) { }

  getEvents(provinceName: string = null) {
    return this.http.get<any>(`${environment.apiUrl}/event?province=${provinceName}`);
  }

  getFilteredData(provinceName, cityName, postalCode) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });
    return this.http.get<any>(`${environment.apiUrl}/event-filter?province=${provinceName}&city=${cityName}&pincode=${postalCode}`, { headers: headers });
  }

  getSearch(provinceName, value) {
    return this.http.get(`${environment.apiUrl}/event?province=${provinceName}&page=` + `${value}`);
  }

  getSingleEvent(id: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });
    return this.http.get<any>(`${environment.apiUrl}/event/` + id, { headers: headers });
  }

  public getmsg(res: any) {
    this.sendssg.next(res);
  }

}
