<div class="prayres-section-rpanel-card" id="pr_audios">
    <div class="row mar-zero">
        <div class="col s12 m6 l6 pad-zero">
            <div class="row mar-zero">
                <div class="col s12 pad-l-zero">
                    <div class="prayres-pdf-card play-activelft hoverable pray-pdf-active">
                        <div class="audio-img-panel"><img src="../../../assets/images/play-audio.png" alt="Audio Thumbnail" class="responsive-img dsp-blk"></div>
                        <!-- <div class="audio-card-vdtle white-text left">Pray for <span id="audiocategory">Health</span></div> -->
                        <div class="audio-time-elapse"></div>
                        <div class="any-audioplayer">
                            <ang-music-player [audioList]=audioLists></ang-music-player>
                        </div>
                        <!-- <div class="row mar-zero">
                            <div class="musicplayer col s12 accent-1">
                                <div class="col s12">
                                    <span class="title"></span>
                                    <span class="right duration"></span>
                                    <input type="range" id="duration" min="0" value="0">
                                    <span class="left current">00:00</span>
                                </div>
                                <div class="col s12 controls">
                                    <i id="shuffle" class="material-icons">shuffle</i>
                                    <i id="repeat" class="material-icons">repeat</i>
                                    <i id="prev" class="material-icons">skip_previous</i>
                                    <i id="play" class="material-icons">play_circle_outline</i>
                                    <i id="next" class="material-icons">skip_next</i>
                                    <i id="volume" class="material-icons">volume_up</i>
                                    <i id="fav" class="material-icons">favorite</i>
                                </div>
                            </div>
                        </div> -->
                        <div class="pr-pdfbtm-panel">
                            <!-- <div class="pray-pdfbtm-rs1" id="audiotitle">Where Is God When My World Seems Out Of Control</div>
                            <div class="pray-pdfbtm-rsdate" id="audiodate">Sat Jul 30 2016</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col s12 m6 l6 pad-zero">
            <div class="row mar-zero">
                <div class="prayres-listscroll">
                    <div id="prayer-audios">
                        <div *ngIf="audioList.length===0" class="data-notfount">
                            <h2 style="text-align: center;">No Records Found</h2>
                        </div>
                        <div id="pr_audios_content" class="col s12 pad-l-zero collection-item" status="stopped" *ngFor="let audioList of audioLists" style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">
                            <div class="prayres-audio-card hoverable">
                                <div class="audio-img-panel"><img src="{{audioList.cover}}"  alt="Audio Thumbnail" width="50px" height="50px" class=""></div>
                                <div class="audio-card-vdtle left">{{audioList.title}}</div>
                                <div class="audio-time-elapse" onclick="setAudioTitle('Health','The peaceful path BY Rev.John Piper',1469889480000)"><i class="material-icons songPlay" data-link="https://www.riseupcanada.org/site/binaries/content/assets/ruc/audios/best-worship-songs---rise-up-and-praise-him.mp3/best-worship-songs---rise-up-and-praise-him.mp3" data-name=""
                                        cover="juicy.jpg" state="stop">play_arrow</i></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>