
 <div class="prayres-section-rpanel-card">
   <div *ngIf="bookList.length===0" class="data-notfount">
      <h2 style="text-align: center;">No Records Found</h2>
  </div>
    <div class="row mar-zero" id="prayer-books">
       <div id="pr_books_content" class="col s12 l3 pad-l-zero" *ngFor="let prayerbooks of bookList">
         <a href="{{prayerbooks.mediaUrl}}"  target="_blank">
            <div class="prayres-book-prayercard hoverable">
               <img src="{{prayerbooks.cover}}" class="responsive-img" alt="Books Thumbnail"> 
               <div class="pr-bookcd-content">
                  <div class="prayres-card-vdtle">{{prayerbooks.title}}</div>
                  <div class="prayres-card-vdtle-subtl">{{prayerbooks.subtitle}}</div>
                  <div class="prayres-card-vdtle-subtldate">ENGLISH</div>
               </div>
            </div>
         </a>
       </div>
      
    </div>
 </div>
