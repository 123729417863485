import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/services/common.service';
import { NotificationService } from 'src/services/notification.service';
import { PageService } from 'src/services/page.service';
import { PrayerwarriorService } from 'src/services/prayerwarrior.service';
import * as $ from 'jquery';
import { CanadaPostalCode } from 'src/services/post-canade.service';
declare var $:any;
@Component({
  selector: 'app-prayerwarrior',
  templateUrl: './prayerwarrior.component.html',
  styleUrls: ['./prayerwarrior.component.css']
})
export class PrayerwarriorComponent implements OnInit {

  pageData: any;
  pagetitle: any;
  bannerDetails: any;
  checkSection: boolean = false;
  description: any;
  submitted: boolean;
  createWarriorForm: FormGroup;
  bannerDetailsTwo: any;
  pageDataTwo: any;
  loaderSpin: boolean = false;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };

  search: string = '';
  selectId: string = '';
  autoCompletArr: any = [];
  openCountryList:boolean=false;
  constructor(private fb: FormBuilder, private commonservice: CommonService, private canadaPostService: CanadaPostalCode, private pageservice: PageService, private pwService: PrayerwarriorService, private notify: NotificationService) {
    this.createWarriorForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      postalCode: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      province: ['', Validators.required],
      pasterMobile: ['', Validators.required],
      pasterName: ['', Validators.required],
      churchName: ['', Validators.required],
      churchEmail: ['', [Validators.required, Validators.pattern(this.emailPattern)]],


    });
    this.pageservice.getPage("60d422d25b57a70068783835").subscribe(res => {

      this.pageData = res.result.templateData;
      this.pagetitle = res.result.title;
      this.description = res.result.description;
      this.checkSection = true;
    })
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res => {
      this.pageDataTwo = res.result.templateData;
      this.bannerDetailsTwo = { slidertitle1: this.pageData.riseupcanada, slidertitle2: this.pageData.prayforournation, sliders: this.pageData.banner }
      this.sectionDetails = { title: this.pageData.section2title, description: this.pageData.section2description, audio: this.pageData.section2audio, pdf: this.pageData.section2pdf }
      this.checkSection = true;
    })
  }

  get f() { return this.createWarriorForm.controls; }

  showList(){
    this.openCountryList=true;
  }
  reset(){
    this.createWarriorForm.reset();
  }
  autoComplete($event) {
    this.search = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
    this.selectId = '';
    this.autoCompleteService();
  }


  autoCompleteService() {
    this.canadaPostService.autoComplete(this.search, this.selectId).subscribe(response => {
      this.autoCompletArr = [];
      this.autoCompletArr = response.Items;
    });
  }
  getRetriveAddress(){
    this.canadaPostService.retriveAddress( this.selectId).subscribe(response => {
      var item = response.Items[0];
       this.createWarriorForm.patchValue({
        address: item.Line1,
        city: item.City,
        province: item.ProvinceName,
        postalCode: item.PostalCode
      });
      this.autoCompletArr = [];
    });
  }
  selectAddress(item) {
    this.selectId = item.Id;
    if (item.Next == 'Find') {
      this.autoCompleteService();
    } else {
      this.getRetriveAddress();
    }
  }


  ngOnInit(): void {
    $(document).on('click', function (event) {
      if (!$(event.target).closest('#spnauto').length) {
        $('#ulautocomplete').hide();
      }
      else {
        $('#ulautocomplete').show();
      }
    });

  }
  SaveChanges() {
    this.submitted = true;
    if (this.createWarriorForm.valid) {
      let data = {

        'name': this.createWarriorForm.get('name')?.value,
        'email': this.createWarriorForm.get('email')?.value,
        'pasterMobile': this.createWarriorForm.get('pasterMobile')?.value,
        'postalCode': this.createWarriorForm.get('postalCode')?.value,
        'address': this.createWarriorForm.get('address')?.value,
        'province': this.createWarriorForm.get('province')?.value,
        'city': this.createWarriorForm.get('city')?.value,
        'pasterName': this.createWarriorForm.get('pasterName')?.value,
        'churchName': this.createWarriorForm.get('churchName')?.value,
        'churchEmail': this.createWarriorForm.get('churchEmail')?.value,

      }
      this.loaderSpin = true;
      this.pwService.addPrayerWarrior(data).subscribe(data => {
        if (data.status == 200) {
          this.loaderSpin = false;
          this.submitted = false;
          this.createWarriorForm.reset();
          this.notify.showSuccess('Registered Succesfully')
        }

      },
        (err) => {
          this.loaderSpin = false;
          this.notify.showError(err.error.message)
        });
    }
  }

}
