<div class="row mar-zero" *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <app-innerbanner [bannerDetails]="bannerDetails" [title]="pagetitle"></app-innerbanner>

        </div>
    </div>
</div>
<div class="container-outer" *ngIf="checkSection">
    <div class="static-section-panel bg-transparent">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">{{pageData.section1title}}</span>
                        <br>
                        <span id="prayers-section-ltitle-sub3" class="prayers-section-ltitle-sub2">{{pageData.section1subtitle}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <!-- <div class="row mar-zero s-prayer-form">
                <h5 class="persinfo-lbl">Category </h5>
                <div class="col s12 m7 l7 pad-zero">
                    <ng-select [allowClear]="true" [disabled]="disabled" (data)="refreshValue($event)" (selected)="select($event)" (typed)="typed($event)">
                        <ng-option *ngFor="let item of this.dataList" value="{{item.name}}">
                            <div>
                                {{item.name}}
                            </div>
                        </ng-option>
                    </ng-select>

                </div>
            </div> -->
            <div class="event-ldmr-panel">
                <div class="prayers-lbox-count-list hide-on-med-and-down">
                    <p class="pray-count-num" id="socprayers_count">{{isCount ? filteredSensitiveCount:sensitiveCount }}</p>
                    <p class="mar-zero pray-count-type st_text">
                        <span class="pray-count-nam">{{pageData.prayercountname}}</span><br>
                        <span class="pray-count-pttxt">{{pageData.prayercounttext}}</span>
                    </p>
                    <div class="mt-15"><a class="waves-effect btn-lg" *ngIf="listPrayer" (click)="loadMore()">{{pageData.loadmore}} </a></div>
                </div>
            </div>
        </div>
        <div>
            <app-strongholdsfilter (filterSensitveData)="getData($event)"></app-strongholdsfilter>
        </div>
        <div class="static-section-rpanel-card add-block-box bg-white pb-0">
            <app-sensitivelist [prayerList]="prayerList"></app-sensitivelist>
            <div class="cen-btn back-province">
                <a class="waves-effect btn-lg" routerLink="/province">BACK TO PROVINCE</a>
            </div>
        </div>
    </div>
</div>
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>
