<a class="modal-trigger" id="trigger-source" href="#myModal" data-backdrop="static" data-keyboard="false"></a>
<div class="home-provincemodal">
    <!-- <div id="modal1" class="modal modal-trigger">
        <div class="modal-content pad-zero">
            <div class="modal-body">
                <div class="choose-province">
                    <div class="province-head">
                        <div class="modal-content pad-zero">
                            <div>
                                <app-headerlogo></app-headerlogo>
                            </div>
                            <div class="modalcls" onclick="$('#modal1').closeModal();loadModal('');"><span><i class="mdi mdi-close"></i></span></div>
                            <div class="modal-body">
                                <div class="select-province">
                                    <div class="provincelist">
                                        <span class="prayer-leader-panel-txt clr-white">choose your Province</span>
                                        <ul>
                                            <li><a href="" class="waves-effect pray-lead-btn">Alberta</a></li>
                                            <li><a href="" class="waves-effect pray-lead-btn">British Columbia</a></li>
                                            <li><a href="" class="waves-effect pray-lead-btn">Manitoba</a></li>
                                            <li><a href="" class="waves-effect pray-lead-btn">New Brunswick</a></li>
                                            <li><a href="" class="waves-effect pray-lead-btn">Newfoundland and Labrador</a></li>
                                            <li><a href="" class="waves-effect pray-lead-btn">Northwest Territories</a></li>
                                            <li><a href="" class="waves-effect pray-lead-btn">Nova Scotia</a></li>
                                            <li><a href="" class="waves-effect pray-lead-btn">Nunavut</a></li>
                                            <li><a href="" class="waves-effect pray-lead-btn">Ontario</a></li>
                                            <li><a href="" class="waves-effect pray-lead-btn">Prince Edward Island</a></li>
                                            <li><a href="" class="waves-effect pray-lead-btn">Quebec</a></li>
                                            <li><a href="" class="waves-effect pray-lead-btn">Saskatchewan</a></li>
                                            <li><a href="" class="waves-effect pray-lead-btn">Yukon</a></li>
                                        </ul>
                                    </div>
                                    <div class="province-popwindow">
                                        <app-mapcontainer></app-mapcontainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Modal -->
    <div class="modal fade modal-vertical-centered" id="myModal" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-body">
                <div class="choose-province">
                    <div class="province-head">
                        <div class="modal-content pad-zero">
                            <div>
                                <app-headerlogo></app-headerlogo>
                            </div>
                            <!-- <div class="modalcls"><span><i class="mdi mdi-close"></i></span></div> -->
                            <div class="modal-body">
                                <div class="select-province">
                                    <div class="provincelist">
                                        <span class="prayer-leader-panel-txt clr-white">choose your Province</span>
                                        <ul>
                                            <li (click)="loadModal()"><a class="waves-effect pray-lead-btn" id="ca-ab">Alberta</a>
                                            </li>
                                            <li (click)="loadModal()"><a class="waves-effect pray-lead-btn" id="ca-bc">British
                                                    Columbia</a></li>
                                            <li (click)="loadModal()"><a class="waves-effect pray-lead-btn" id="ca-mb">Manitoba</a>
                                            </li>
                                            <li (click)="loadModal()"><a class="waves-effect pray-lead-btn" id="ca-nb">New
                                                    Brunswick</a></li>
                                            <li (click)="loadModal()"><a class="waves-effect pray-lead-btn" id="ca-nl">Newfoundland
                                                    and Labrador</a></li>
                                            <li (click)="loadModal()"><a class="waves-effect pray-lead-btn" id="ca-nt">Northwest
                                                    Territories</a></li>
                                            <li (click)="loadModal()"><a class="waves-effect pray-lead-btn" id="ca-ns">Nova
                                                    Scotia</a></li>
                                            <li (click)="loadModal()"><a class="waves-effect pray-lead-btn" id="ca-bc">Nunavut</a>
                                            </li>
                                            <li (click)="loadModal()"><a class="waves-effect pray-lead-btn" id="ca-on">Ontario</a>
                                            </li>
                                            <li (click)="loadModal()"><a class="waves-effect pray-lead-btn" id="ca-pe">Prince
                                                    Edward Island</a></li>
                                            <li (click)="loadModal()"><a class="waves-effect pray-lead-btn" id="ca-qc">Quebec</a>
                                            </li>
                                            <li (click)="loadModal()"><a class="waves-effect pray-lead-btn" id="ca-sk">Saskatchewan</a></li>
                                            <li (click)="loadModal()"><a class="waves-effect pray-lead-btn" id="ca-yt">Yukon</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="province-popwindow" (click)="loadModal()">
                                        <app-mapcontainer></app-mapcontainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
