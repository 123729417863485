import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/services/common.service';
import { IndividualService } from 'src/services/individual.service';
import { NotificationService } from 'src/services/notification.service';
import { PageService } from 'src/services/page.service';
import { CanadaPostalCode } from 'src/services/post-canade.service';
// import * as $ from 'jquery';
declare var $:any;
@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.css']
})
export class IndividualComponent implements OnInit {
  pageData: any;
  pagetitle: any;
  bannerDetails: any;
  checkSection: boolean = false;
  description: any;
  createIndividualForm: FormGroup;
  submitted: boolean;
  loaderSpin: boolean = false;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  bannerDetailsTwo: any;
  pageDataTwo: any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  categoryList:any;
  search: string = '';
  selectId: string = '';
  autoCompletArr: any = [];
  openCountryList:boolean=false;
  constructor(private commonservice: CommonService, private canadaPostService: CanadaPostalCode, private pageservice: PageService, private formBuilder: FormBuilder, private notify: NotificationService, private individual: IndividualService) {
    this.createIndividualForm = this.formBuilder.group({
      preferredName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      postalCode: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      province: ['', Validators.required],
      mobile: ['', Validators.required],
      emailSubscribe: [''],
      smsSubscribe: [''],
    
    });

    this.pageservice.getPage("60d4163ca793750617d184d0").subscribe(res => {
      this.pageData = res.result.templateData;
      this.pagetitle = res.result.title;
      this.description = res.result.description;
      this.checkSection = true;
    })
  
  }

  get f() { return this.createIndividualForm.controls; }
  reset(){
    this.createIndividualForm.reset();
  }
  showList(){
    this.openCountryList=true;
  }
  autoComplete($event) {
    this.search = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
    this.selectId = '';
    this.autoCompleteService();
  }


  autoCompleteService() {
    this.canadaPostService.autoComplete(this.search, this.selectId).subscribe(response => {
      this.autoCompletArr = [];
      this.autoCompletArr = response.Items;
    });
  }

  getRetriveAddress(){
    this.canadaPostService.retriveAddress( this.selectId).subscribe(response => {
      var item = response.Items[0];
       this.createIndividualForm.patchValue({
        address: item.Line1,
        city: item.City,
        province: item.ProvinceName,
        postalCode: item.PostalCode
      });
      this.autoCompletArr = [];
    });
  }

  selectAddress(item) {
    this.selectId = item.Id;
    if (item.Next == 'Find') {
      this.autoCompleteService();
    } else {
      this.getRetriveAddress();
     
    }
  }

  ngOnInit(): void {
    $(document).on('click', function (event) {
      if (!$(event.target).closest('#spnauto').length) {
        $('#ulautocomplete').hide();
      }
      else {
        $('#ulautocomplete').show();
      }
    });
  }
  savechanges() {
    this.submitted = true;
    if (this.createIndividualForm.valid) {
      let data = {

        'preferredName': this.createIndividualForm.get('preferredName')?.value,
        'email': this.createIndividualForm.get('email')?.value,
        'mobile': this.createIndividualForm.get('mobile')?.value,
        'postalCode': this.createIndividualForm.get('postalCode')?.value,
        'address': this.createIndividualForm.get('address')?.value,
        'province': this.createIndividualForm.get('province')?.value,
        'city': this.createIndividualForm.get('city')?.value,
        'emailSubscribe':this.createIndividualForm.get('emailSubscribe')?.value,
        'smsSubscribe':this.createIndividualForm.get('smsSubscribe')?.value,

      }
      this.loaderSpin = true;
      this.individual.addIndividualData(data).subscribe(data => {
        if (data.status == 200) {
          this.submitted = false;
          this.loaderSpin = false;
          this.createIndividualForm.reset();
          this.notify.showSuccess('Registered Succesfully')
        }

      },
        (err) => {
          this.loaderSpin = false;
          this.notify.showError(err.error.message)
        });
    }
  }
}
