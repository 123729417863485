<div class="row mar-zero"  *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <app-innerbanner [bannerDetails]="bannerDetails" [title]="pagetitle"></app-innerbanner>
        </div>
    </div>
</div>
<div class="container-outer"  *ngIf="checkSection">
    <div class="prayerresaudio-section-panel">
        <div class="static-section-lpanel static-lpanel-cust">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <div class="prayres-howpray-txt">
                            <p class="prayres-howpray-smtxt">{{pageData.audiosubtitle}}</p>
                            <p class="prayres-howpray-txt-title">{{pageData.audiosectiotitle}}</p>
                        </div>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div> 
            <div class="mt-15"><a class="waves-effect btn-lg" *ngIf="listAudios" (click)="loadMore()">Load More Audios</a></div>
        </div>
      <div class="audio_player_lg">
        <app-audios *ngIf="checkRes" [audioLists]="audioLists"></app-audios>
      </div>
        <div class="cen-btn">
            <a class="waves-effect btn-lg" routerLink="/prayerresource">BACK TO RESOURCES</a>
        </div>
        <!-- <ang-music-player [audioList]=a udioList></ang-music-player> -->
    </div>
    <app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>