import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/services/common.service';
import { NotificationService } from 'src/services/notification.service';
declare var $: any;
@Component({
  selector: 'app-trentingissues',
  templateUrl: './trentingissues.component.html',
  styleUrls: ['./trentingissues.component.css']
})
export class TrentingissuesComponent implements OnInit {
  id: any;
  count: any;
  isLoad: boolean = false;
  @Input() trendingPrayerList:any;
  data: any;
  hostipAddress: string = '';
  datas: object;
  constructor(private amenService: CommonService, public notify: NotificationService) {


  }

  addAmen(data, i) {
    var uniqueData = JSON.parse(localStorage.getItem('uuid'));
    this.id = i;
     
    var pushId;

    if (localStorage.getItem('session') === null) {
      pushId = [];
    } else {

      pushId = JSON.parse(localStorage.getItem('session'));
    }
    pushId.push(data.id);
    localStorage.setItem('session', JSON.stringify(pushId));

    if (uniqueData === null) {
      this.datas = {
        'postRef': data.id,
      }
    }
    else if (uniqueData !== null) {
      this.datas = {
        'postRef': data.id,
        'uuid': uniqueData
      }
    }

    this.isLoad = true;

    this.amenService.addAmen(this.datas).subscribe(response => {

      if (response.status == 200) {
      
        this.isLoad = false;
        this.hostipAddress = response.body.hostIp;
        localStorage.setItem('uuid', JSON.stringify(this.hostipAddress));
        data.amenCount++;
        data.disabled = true;
        this.notify.showSuccess('Amen Count Added')
      }
    }, (err) => {
      this.notify.showError(err.error.message)
    })

  }


  ngOnInit(): void {
    var idStore = JSON.parse(localStorage.getItem('session'));
    this.trendingPrayerList.forEach((item) => {
      if (idStore.indexOf(item.id) > -1) {

        item.disabled = true
      }
      else {
        item.disabled = false

      }
    })
  }

}
