<div class="prayres-section-rpanel-card">
    <div class="row mar-zero">
        <div class="col s12 m6 l6 pad-zero">
            <div class="row mar-zero">
                <div class="col s12 pad-l-zero">
                    <div class="prayres-pdf-card hoverable pray-pdf-active">
                        <div class="audio-img-panel"><img src="../../../../assets/images/pray-pdf.png"  alt="Pdf Thumbnail" class="responsive-img dsp-blk"></div>
                        <div class="audio-card-vdtle left" id="pdfT"></div>
                        <a href="" id="pdfL" target="_blank" class="pdf-dlicon">
                            <img src="../../../../assets/images/pdf-dlicon.png" alt="Pdf Thumbnail" class="responsive-img dsp-blk">
                        </a>
                        <div class="pdf-active-content">
                            <div class="row">
                                <div class="col s12 pad-zero">
                                    <p class="txt-14 mar-t-zero clr-888" id="pdfLD">
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="pr-pdfbtm-panel">
                            <div class="pray-pdfbtm-rs1" id="pdfC">PDFs</div>
                            <!--div class="pray-pdfbtm-rsdate">26 Feb 2016</div-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col s12 m6 l6 pad-zero">
            <div class="row mar-zero">
                <div class="prayres-listscroll" >
                    <div id="prayer-pdfs" >
                        <div *ngIf="pdfList.length===0" class="data-notfount">
                            <h2 style="text-align: center;">No Records Found</h2>
                        </div>
                        <div id="pr_pdfs_content" class="pad-l-zero" *ngFor="let list of pdfList">
                            <div class="prayres-pdf-card hoverable">
                                <div class="audio-img-panel">
                                    <img src="{{list.cover}}" alt="Pdf Thumbnail" class="responsive-img dsp-blk">
                                </div>
                                <div class="audio-card-vdtle left">{{list.title}}</div>
                                <a href="{{list.url}}" target="_blank" class="pdf-dlicon" download>
                                    <img src="../../../../assets/images/pdf-dlicon.png" alt="Pdf Thumbnail" class="responsive-img dsp-blk"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>