import { Component, Input, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-innerbanner',
  templateUrl: './innerbanner.component.html',
  styleUrls: ['./innerbanner.component.css']
})
export class InnerbannerComponent implements OnInit {

  @Input() bannerDetails: any = [];
  @Input() title: any = '';

  constructor() { }

  ngOnInit(): void {
    $(window).bind("load", function() {  
      $('.bxslider').bxSlider({
        auto: true,
        mode: 'fade',
        autoHover: true,
        pause:1200
      });
    });
  }

}
