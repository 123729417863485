import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliceDescription'
})
export class SliceDescriptionPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) { return ''; }
    var words = value.trim().split(/\s/).slice(0,20);
   
      var joins = words.join(" ");
      var text:string = joins.concat("...");
  
    return text;
  }
}
