import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-chooseprovince',
  templateUrl: './chooseprovince.component.html',
  styleUrls: ['./chooseprovince.component.css']
})
export class ChooseprovinceComponent implements OnInit, AfterViewInit {
  // @ViewChild('modal-trigger', { static: true }) myModal: ElementRef;
  @ViewChild('modal1', { static: true }) modal1: ElementRef;
  modalService: any;
  data: any;
  location: any;

  constructor() { }

  ngOnInit(): void {
    this.loadModal();

    setTimeout(() => {
      if (localStorage.getItem('selectedRegion') == null && localStorage.getItem('selectedRegionName') == null) {
        //this.modal1.nativeElement.click();
        let elem: HTMLElement = document.getElementById('trigger-source') as HTMLElement;
        elem.click();
        // localStorage.setItem('showChooseProvince', 'SHOWN');
      }
    }, 2000);
  }

  loadModal() {
    $('.modal-trigger').leanModal({
     dismissible:false, // Modal can be dismissed by clicking outside of the modal
      opacity: .5, // Opacity of modal background
      in_duration: 300, // Transition in duration
      out_duration: 300, // Transition out duration
      startingTop: '0', // Starting top style attribute
      endingTop: '10%', // Ending top style attribute
      top: 0,
      complete: function () {
        $('.lean-overlay').remove();
      }
    });
  }

  select_Alberta() {
    //   localStorage.setItem('selectedRegion', JSON.stringify("Alberta"));
    //  localStorage.setItem('selectedRegionName', JSON.stringify("ca-ab"));
  }
  select_British() {

  }
  select_Manitoba() {

  }
  select_Brunswick() {

  }
  select_Northwest() {

  }
  select_Nova() {

  }
  select_Nunavut() {

  }
  select_Ontario() {

  }
  select_Prince() {

  }
  select_Quebec() {

  }
  select_Saskatchewan() {

  }
  select_Yukon() {

  }
  ngAfterViewInit() {

  }

}
