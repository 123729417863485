import { BrowserModule,  Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxPaginationModule } from 'ngx-pagination';

import { ISlimScrollOptions, NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';
import { AngMusicPlayerModule } from 'ang-music-player';
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Router, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HomepageComponent } from './Templates/homepage/homepage.component';
import { AboutComponent } from './Templates/about/about.component';
import { HeaderComponent } from './Templates/header/header.component';
import { FooterComponent } from './Templates/footer/footer.component';
import { ProvinceComponent } from './Templates/province/province.component';
import { PrayerpartnersComponent } from './Templates/Partners/prayerpartners/prayerpartners.component';
import { PartnerbenefitsComponent } from './Templates/Partners/partnerbenefits/partnerbenefits.component';
import { WhyprayComponent } from './Templates/Resources/whypray/whypray.component';
import { HowtoprayComponent } from './Templates/Resources/howtopray/howtopray.component';
import { PrayerresourceComponent } from './Templates/Resources/prayerresource/prayerresource.component';
import { PrayerpointsceComponent } from './Templates/Resources/prayerpointsce/prayerpointsce.component';
import { EventsComponent } from './Templates/events/events.component';
import { ContactusComponent } from './Templates/contactus/contactus.component';
import { PrayfornationComponent } from './Component/prayfornation/prayfornation.component';
import { HeaderlogoComponent } from './Component/headerlogo/headerlogo.component';
import { ScrollToTopComponent } from './Component/scroll-to-top/scroll-to-top.component';
import { PrimarybuttonComponent } from './Component/primarybutton/primarybutton.component';
import { BannerbiblequoteComponent } from './Component/bannerbiblequote/bannerbiblequote.component';
import { FollowusComponent } from './Component/followus/followus.component';
import { LoginmoduleComponent } from './Component/loginmodule/loginmodule.component';
import { ProvincemapComponent } from './Component/provincemap/provincemap.component';
import { VideosComponent } from './Component/videos/videos.component';
import { BooksComponent } from './Component/books/books.component';
import { AudiosComponent } from './Component/audios/audios.component';
import { PdfsComponent } from './Component/pdfs/pdfs.component';
import { LeadersprofileComponent } from './Component/leadersprofile/leadersprofile.component';
import { PrayforleadersComponent } from './Templates/prayforleaders/prayforleaders.component';
import { SocialprayerComponent } from './Templates/socialprayer/socialprayer.component';
import { SocialprayerlistComponent } from './Component/socialprayerlist/socialprayerlist.component';
import { NewsComponent } from './Templates/news/news.component';
import { IndividualComponent } from './Templates/regester/individual/individual.component';
import { GroupComponent } from './Templates/regester/group/group.component';
import { PrayerwarriorComponent } from './Templates/regester/prayerwarrior/prayerwarrior.component';
import { EventlistComponent } from './Component/eventlist/eventlist.component';
import { TrentingissuesComponent } from './Component/trentingissues/trentingissues.component';
import { EventsdetailsComponent } from './Templates/events/eventsdetails/eventsdetails.component';
import { SelectdropdownComponent } from './Component/selectdropdown/selectdropdown.component';
import { NewslistComponent } from './Component/newslist/newslist.component';
import { NewsdetailsComponent } from './Templates/news/newsdetails/newsdetails.component';
import { PersonalprayerlistComponent } from './Component/personalprayerlist/personalprayerlist.component';
import { BreakingnewsComponent } from './Component/breakingnews/breakingnews.component';
import { ChristianleadersComponent } from './Templates/christianleaders/christianleaders.component';
import { VideodetailsComponent } from './Templates/videodetails/videodetails.component';
import { AudiosdetailsComponent } from './Templates/audiosdetails/audiosdetails.component';
import { BooksdetailsComponent } from './Templates/booksdetails/booksdetails.component';
import { PdfsdetailsComponent } from './Templates/pdfsdetails/pdfsdetails.component';
import { PrayerselectboxComponent } from './Component/prayerselectbox/prayerselectbox.component';
import { ChooseprovinceComponent } from './Component/chooseprovince/chooseprovince.component';
import { FooterprayerpointsComponent } from './Component/footerprayerpoints/footerprayerpoints.component';
import { SensitiveprayerComponent } from './Templates/sensitiveprayer/sensitiveprayer.component';
import { SensitivelistComponent } from './Component/sensitivelist/sensitivelist.component';
import { PersonalprayerComponent } from './Templates/personalprayer/personalprayer.component';
import { LeadersdropdownComponent } from './Component/leadersdropdown/leadersdropdown.component';
import { GooglerechaptchaComponent } from './Component/googlerechaptcha/googlerechaptcha.component';
import { BlogcardComponent } from './Component/blogcard/blogcard.component';
import { LogoLayoutComponent } from './Component/sidebar/logo-layout/logo-layout.component';
import { InnerbannerComponent } from './Component/innerbanner/innerbanner.component';
import { HomebannerComponent } from './Component/homebanner/homebanner.component';
import { BiblequoteblogComponent } from './Component/biblequoteblog/biblequoteblog.component';
import { EventsListService } from 'src/services/events.service';
import { NewsService } from 'src/services/news.service';
import { ChristianLeaderService } from 'src/services/christianleader.service';
import { SensitivePrayerService } from 'src/services/sensitiveprayer.service';
import { SocialPrayerService } from 'src/services/socialprayer.service';
import { CommonService } from 'src/services/common.service';
import { GeoService } from 'src/services/geo.service';
import { PageService } from 'src/services/page.service';
import { MapcontainerComponent } from './Component/mapcontainer/mapcontainer.component';
import { SidebarmapComponent } from './Component/sidebarmap/sidebarmap.component';
// import { PrayerPointsService } from 'src/services/prayerpoints.service';
import { ChristianleaderComponent } from './Component/christianleader/christianleader.component';
import { LeaderService } from 'src/services/leader.service';
import { SlicePipe } from './Pipes/slice.pipe';
import { StripHtmlPipe } from './Pipes/stripe-html.pipe';
import { SliceDescriptionPipe } from './Pipes/slice-description.pipe';
import { EnquiryService } from 'src/services/enquiry.service';
import { PersonalprayerService } from 'src/services/personalprayer.service';
import { NotificationService } from 'src/services/notification.service';
import { ToastrModule } from 'ngx-toastr';
import { PartnerorganizationComponent } from './Templates/Partners/partnerorganization/partnerorganization.component';
import { IndividualService } from 'src/services/individual.service';
import { PrayerwarriorService } from 'src/services/prayerwarrior.service';
import { GroupregisterService } from 'src/services/groupregister.service';
import { ChangepasswordComponent } from './Templates/changepassword/changepassword.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SuccessmesageComponent } from './Component/successmesage/successmesage.component';
import { ReplacePipe } from 'src/helper/replace-pipe';
import { AppPasswordDirective } from './app-password.directive';
import { ModalModule } from 'angular-custom-modal/modal.module';
import { SafePipePipe } from './Pipes/safe-pipe.pipe';
import { KneelingarmyComponent } from './Templates/regester/kneelingarmy/kneelingarmy.component';
import { StrongholdsfilterComponent } from './Component/strongholdsfilter/strongholdsfilter.component';
import { KneelingarmyService } from 'src/services/kneelingarmy.service';
import { PartnerorgService } from 'src/services/partneringorg.service';
const routes: Routes = [

];
export interface ISlimScrollEvent {
  type: 'scrollToBottom' | 'scrollToTop' | 'scrollToPercent' | 'scrollTo' | 'recalculate';
  y?: number;
  duration?: number;
  easing?: 'linear' | 'inQuad' | 'outQuad' | 'inOutQuad' | 'inCubic' | 'outCubic' | 'inOutCubic' |
  'inQuart' | 'outQuart' | 'inOutQuart' | 'inQuint' | 'outQuint' | 'inOutQuint';
}
@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    HeaderComponent,
    FooterComponent,
    PrayfornationComponent,
    HomepageComponent,
    HeaderlogoComponent,
    ScrollToTopComponent,
    ProvinceComponent,
    PrayerpartnersComponent,
    PartnerbenefitsComponent,
    PrimarybuttonComponent,
    BannerbiblequoteComponent,
    EventsComponent,
    WhyprayComponent,
    HowtoprayComponent,
    PrayerresourceComponent,
    PrayerpointsceComponent,
    ContactusComponent,
    FollowusComponent,
    LoginmoduleComponent,
    ProvincemapComponent,
    VideosComponent,
    BooksComponent,
    AudiosComponent,
    PdfsComponent,
    PrayforleadersComponent,
    LeadersprofileComponent,
    SocialprayerComponent,
    SocialprayerlistComponent,
    NewsComponent,
    IndividualComponent,
    GroupComponent,
    PrayerwarriorComponent,
    EventlistComponent,
    TrentingissuesComponent,
    EventsdetailsComponent,
    SelectdropdownComponent,
    NewslistComponent,
    NewsdetailsComponent,
    PersonalprayerlistComponent,
    BreakingnewsComponent,
    ChristianleadersComponent,
    VideodetailsComponent,
    AudiosdetailsComponent,
    BooksdetailsComponent,
    PdfsdetailsComponent,
    PrayerselectboxComponent,
    ChooseprovinceComponent,
    FooterprayerpointsComponent,
    SensitiveprayerComponent,
    SensitivelistComponent,
    PersonalprayerComponent,
    LeadersdropdownComponent,
    GooglerechaptchaComponent,
    BlogcardComponent,
    LogoLayoutComponent,
    InnerbannerComponent,
    HomebannerComponent,
    BiblequoteblogComponent,
    MapcontainerComponent,
    SidebarmapComponent,
    ChristianleaderComponent,
    SlicePipe,
    SliceDescriptionPipe,
    PartnerorganizationComponent,
    ChangepasswordComponent,
    SuccessmesageComponent,
    ReplacePipe,
    AppPasswordDirective,
    SafePipePipe,
    KneelingarmyComponent,
    StrongholdsfilterComponent,
    StripHtmlPipe

  ],
  imports: [

    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule.forRoot(routes, { useHash: true }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxCaptchaModule,
    NgSlimScrollModule,
    AngMusicPlayerModule,
    NgSelectModule,
    ToastrModule.forRoot(),
    SlickCarouselModule,

  ],
  providers: [

    {
      provide: SLIMSCROLL_DEFAULTS,
      useValue: {
        alwaysVisible: false
      } as ISlimScrollOptions
    },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // { provide: ToastrService, useValue: ToastrService },
    EventsListService,
    NewsService,
    ChristianLeaderService,
    SensitivePrayerService,
    SocialPrayerService,
    CommonService,
    GeoService,
    PageService,
    // PrayerPointsService,
    LeaderService,
    EnquiryService,
    PersonalprayerService,
    NotificationService,
    IndividualService,
    GroupregisterService,
    PrayerwarriorService,
    Title,
    KneelingarmyService,
    PartnerorgService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
