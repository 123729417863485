<div class="row mar-zero">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <!-- <app-innerbanner [bannerDetails]="christianleaders[0]['template_field'][0]['banner']" [title]="christianleaders[0]['title']"></app-innerbanner> -->
        </div>
    </div>
</div>
<div class="container-outer">
    <!--Christian Leaders-->
    <div class="static-section-panel">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">Christian Leaders</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="static-section-rpanel">
            <div class="row mar-zero">
                <app-leadersprofile></app-leadersprofile>
            </div>
            <div class="row mar-zero">
                <div class="col s12 pad-zero" id="content2"></div>
            </div>
            <!--a class="waves-effect btn-lg" onclick="loadMenu('registration')">Become a Pray Partner</a-->
        </div>
    </div>
    <!--Christian Leaders-->


</div>