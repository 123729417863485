import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-biblequoteblog',
  templateUrl: './biblequoteblog.component.html',
  styleUrls: ['./biblequoteblog.component.css']
})
export class BiblequoteblogComponent implements OnInit {
  @Input() bodyDetails: any=[]
  @Input() texthead: any = [];
  @Input() quotesnum: any = [];
  @Input() thumbnail: any = [];
  @Input() description: any = [];
  constructor() { }

  ngOnInit(): void {

  }

}
