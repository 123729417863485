import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KneelingarmyService } from 'src/services/kneelingarmy.service';
import { NotificationService } from 'src/services/notification.service';
import { PageService } from 'src/services/page.service';
import { CanadaPostalCode } from 'src/services/post-canade.service';
declare var $:any;
@Component({
  selector: 'app-kneelingarmy',
  templateUrl: './kneelingarmy.component.html',
  styleUrls: ['./kneelingarmy.component.css']
})
export class KneelingarmyComponent implements OnInit {
  pageData: any;
  pagetitle: any;
  bannerDetails: any;
  checkSection: boolean = false;
  description: any;
  createKneelingArmyForm: FormGroup;
  submitted: boolean;
  loaderSpin: boolean = false;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  bannerDetailsTwo: any;
  pageDataTwo: any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  categoryList:any;
  search: string = '';
  selectId: string = '';
  autoCompletArr: any = [];
  openCountryList:boolean=false;
  armyList: any;
  totalItems: any;
  currentPage: number = 0;
  currentData:number= 0;
  totalPages: any;
  loading: boolean = true;
  constructor(private canadaPostService: CanadaPostalCode,private armyservice:KneelingarmyService, private pageservice: PageService, private formBuilder: FormBuilder, private notify: NotificationService) {
    this.createKneelingArmyForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      postalCode: ['', Validators.required],
      address: ['', Validators.required],
      mobile: ['', Validators.required],
      joinGroup: [''],
      ownGroup: [''],
    
    });

    this.pageservice.getPage("61f3ea88cccd192c9ee205e5").subscribe(res => {
      this.pageData = res.result.templateData;
      this.pagetitle = res.result.title;
      this.description = res.result.description;
      this.checkSection = true;
    })
  
  }
  get f() { return this.createKneelingArmyForm.controls; }

  showList(){
    this.openCountryList=true;
  }
  autoComplete($event) {
    this.search = (<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
    this.selectId = '';
    this.autoCompleteService();
  }
  reset(){
    this.createKneelingArmyForm.reset();
  }

  autoCompleteService() {
    this.canadaPostService.autoComplete(this.search, this.selectId).subscribe(response => {
      this.autoCompletArr = [];
      this.autoCompletArr = response.Items;
    });
  }

  selectAddress(item) {
    if (item.Next == 'Find') {
      this.selectId = item.Id;
      this.autoCompleteService();
    } else {
      this.createKneelingArmyForm.patchValue({
        address: item.Text,
        city: item.Description.split(',')[0],
        province: item.Description.split(',')[1],
        postalCode: item.Description.split(',')[2]
      });
      this.autoCompletArr = [];
    }
  }

  ngOnInit(): void {
    $(document).on('click', function (event) {
      if (!$(event.target).closest('#spnauto').length) {
        $('#ulautocomplete').hide();
      }
      else {
        $('#ulautocomplete').show();
      }
    });
  }
  savechanges() {

    this.submitted = true;
    if (this.createKneelingArmyForm.valid) {
      let data = {

        'firstName': this.createKneelingArmyForm.get('firstName')?.value,
        'lastName': this.createKneelingArmyForm.get('lastName')?.value,
        'email': this.createKneelingArmyForm.get('email')?.value,
        'postalCode': this.createKneelingArmyForm.get('postalCode')?.value,
        'address': this.createKneelingArmyForm.get('address')?.value,
        'mobile': this.createKneelingArmyForm.get('mobile')?.value,
        'joinGroup':this.createKneelingArmyForm.get('joinGroup')?.value,
        'ownGroup':this.createKneelingArmyForm.get('ownGroup')?.value,

      }
      this.loaderSpin = true;
      this.armyservice.addKneelingArmy(data).subscribe(data => {
        if (data.status == 200) {
          this.submitted = false;
          this.loaderSpin = false;
          this.createKneelingArmyForm.reset();
          this.notify.showSuccess('Registered Succesfully')
        }

      },
        (err) => {
          this.loaderSpin = false;
          this.notify.showError(err.error.message)
        });
    }
  }

}
