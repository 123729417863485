import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-footerprayerpoints',
  templateUrl: './footerprayerpoints.component.html',
  styleUrls: ['./footerprayerpoints.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class FooterprayerpointsComponent implements OnInit {
  @Input() details:any; 

  constructor() {}

  ngOnInit(): void {
  }

}
