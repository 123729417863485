import { Component, Input, OnInit } from '@angular/core';
import { NewsService } from 'src/services/news.service';
declare var $: any;

@Component({
  selector: 'app-breakingnews',
  templateUrl: './breakingnews.component.html',
  styleUrls: ['./breakingnews.component.css']
})
export class BreakingnewsComponent implements OnInit {

  @Input() newslist :any;
  newslistCopy: any = [];
  data: any;
  constructor(public newsService: NewsService) { }
    
  ngOnInit(): void {
    
  }
  ngAfterViewInit() {
    setTimeout(function () {
      $(".owl-carousel").owlCarousel({
        items: 1,
        singleItem: true,
        itemsScaleUp: true,
        slideSpeed: 500,
        autoPlay: 5000,
        stopOnHover: true,
        autoplay: true,
        nav: false,
        dots: false,
      });
          var selector = $('.owl-carousel');
          $('.owl-cus-next').click(function() {
            selector.trigger('next.owl.carousel');
          });
  
          $('.owl-cus-prev').click(function() {
            selector.trigger('prev.owl.carousel');
          });
      
   
},2000);
}
  breaknews() {
    
  }
}
