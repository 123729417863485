<div class="row mar-zero"  *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <app-innerbanner [bannerDetails]="bannerDetails" [title]="pagetitle"></app-innerbanner>
        </div>
    </div>
</div>
<div class="container-outer" *ngIf="checkSection">
    <div class="static-section-panel bg-transparent">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">{{pageData.section1title}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <div class="event-ldmr-panel"><a class="waves-effect btn-lg"  *ngIf="listNews" (click)="loadMore()">{{pageData.loadmore}}</a></div>
        </div>
        <div>
            <app-selectdropdown (filterDropdown)="getData($event)"></app-selectdropdown>
        </div>
        <div class="static-section-rpanel-card add-block-box bg-white short-newsdec pb-0">
            <app-newslist (newEvent)='getNews($event)'  [newslist]="newslist"></app-newslist>
            <!-- <app-biblequoteblog ></app-biblequoteblog> -->
        </div>
    </div>

</div>
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>