import { Component,Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventsListService } from 'src/services/events.service';





@Component({
  selector: 'app-eventlist',
  templateUrl: './eventlist.component.html',
  styleUrls: ['./eventlist.component.css']
})
export class EventlistComponent implements OnInit {
  @Input() eventList :any;
  @Input() data:any;
  constructor(private router: Router, public eventsList: EventsListService,) {
    this.eventsList.sendssg.subscribe((res:any) => {
      this.eventList = res;
    })
   }

  ngOnInit(): void {

  }


  link(id: any) {
  
    this.router.navigate(['/eventsdetails', id])
  }

}