<div class="row mar-zero">
    <div class="col s12 pad-zero">
        <ul class="bxslider m-0">
            <li *ngFor="let bannerDetail of bannerDetails; index as i;">
                <div class="about-panel" style="background: #653935 url({{bannerDetail.file}}) center no-repeat;background-size: cover;">
                    <app-bannerbiblequote [biblequotes]="bannerDetail.bibleQuotes" [verses]="bannerDetail.verses"></app-bannerbiblequote>
                    <div class="static-top-title">
                        <span>{{title}}</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>