<div class="row mar-zero"  *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <app-headerlogo></app-headerlogo>
              <app-provincemap></app-provincemap> 
        </div>
        <div class="static-topright">
            <app-innerbanner [bannerDetails]="bannerDetails" [title]="pagetitle"></app-innerbanner>
        </div>
    </div>
</div>
<!-- Content Area Start -->
<div class="container-outer"  *ngIf="checkSection">
    <div class="static-section-panel bb-0">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">{{pageData.section1title}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="static-section-rpanel-card add-block-box mobile-left">
            <div class="floating_image">
                <img class="responsive-img" id="aboutimage" src="{{pageData.section1image}}">
            </div>
            <div class="pad-l-zero">
                <div class="">
                    <div class="static-bible-quote" id="biblecontent">{{pageData.section1biblequote}}</div>
                    <div class="static-bible-quote-num" id="biblequote">{{pageData.section1bibleversus}}</div>
                </div>
                <div class="" id="content2">
                    <p class="txt-14 clr-888" [innerHTML]="description"></p>
                </div>
            </div>
            <div class="row mar-zero">
                <div class="col s12 pad-zero" id="content2"></div>
            </div>
            <!--a class="waves-effect btn-lg" onclick="loadMenu('registration')">Become a Pray Partner</a-->
        </div>
    </div>
    <div class="static-section-panel">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <!-- <div class="col s12 pad-zero">
                <div class="left">
                   <span class="static-section-ltitle">Prayer Points</span>
                </div>
                <div class="right">
                   <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                </div>
             </div> -->
            </div>
        </div>
        <div class="static-section-rpanel-card add-block-box ml-0">
            <!-- <div class="row mar-zero">
                <app-prayerselectbox></app-prayerselectbox>
            </div> -->
        </div>
    </div>
</div>
<!-- Content Area End -->
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>