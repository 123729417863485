import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GroupregisterService {

  constructor(private http: HttpClient) { }
    addGroupData(data:any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });
    let body = data;
    return this.http.post<any>(`${environment.apiUrl}/group`,body,
      {
        headers: headers,
        observe: 'response',
      });
  }
  viewGroupData() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
  });
 
  return this.http.get<any>(`${environment.apiUrl}/group`,{ headers: headers });
  }
  addInviteMember(data) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });
    let body = data;
    return this.http.post<any>(`${environment.apiUrl}/invitemember`,body,
      {
        headers: headers,
        observe: 'response',
      });
  }
}
