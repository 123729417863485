<div class="row mar-zero">
    <div class="spacer-sm"></div>
    <div class="col s12 pad-zero center-align">
        <img src= "assets\images\province\{{province_code +'.png'}}" id="aprovince_image" alt="" title="">
    </div>
    <div class="col s12 pad-zero center-align">
        <div class="static-left-innerttl">
            <span class="static-left-innerttl-1" id="aprovince_label">Pray for<br>province of</span><br>
            <span class="static-left-innerttl-2" id="aprovince_name">{{division_name}}</span><br>
            <span class="static-left-innerttl-3" id="acity"></span>
        </div>
    </div>
</div>
