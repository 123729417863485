import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from 'src/services/page.service';
import { PrayerresourceService } from 'src/services/prayerresource.service';
declare var $: any;
@Component({
  selector: 'app-prayerresource',
  templateUrl: './prayerresource.component.html',
  styleUrls: ['./prayerresource.component.css']
})
export class PrayerresourceComponent implements OnInit {
  bannertitle = "Prayer Resources";
  murlpath: string;
  params: any;
  pageData:any;
  template:any;
  pagetitle:any;
  checkSection:boolean=false;
  bannerDetails: any;
  biblequotes: any;
  bannerDetailsTwo: any;
  pageDataTwo:any;
  checkRes:boolean=false;
  videoUrl:string="Videos";
  pageCount:number=0;
  audioUrl:string="Audio"
  pdfUrl:string="Pdf"
  bookUrl:string="Books"
  videoList:any;
  pdfList:any;
  audioLists:any;
  bookList:any;
  noOfVideos:any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  constructor(private route: ActivatedRoute,private pageservice:PageService ,private resourceService:PrayerresourceService ) {
    this.pageservice.getPage('60d1db16b9e57b5357ac1f69').subscribe(res =>{
      this.pageData=res.result.templateData;
      this.pagetitle=res.result.title;
      this.bannerDetails = [{bibleQuotes:this.pageData.bannerquote,verses:this.pageData.bannerversus,file:this.pageData.bannerimage?this.pageData.bannerimage:'../../../assets/images/pray-res-bg.jpg'}]
      this.checkSection=true;
    })
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res =>{
      this.pageDataTwo=res.result.templateData;
      this.bannerDetailsTwo = {slidertitle1:this.pageDataTwo.riseupcanada,slidertitle2:this.pageDataTwo.prayforournation,sliders:this.pageDataTwo.banner}
      this.sectionDetails = {title:this.pageData.section2title,description:this.pageData.section2description,audio:this.pageData.section2audio,pdf:this.pageData.section2pdf}
      this.checkSection=true;
    })
   }
  getAllVideos() {
    this.resourceService.getAllResources(this.videoUrl,this.pageCount).subscribe((data: any) => {
      this.checkRes = true;
      this.videoList =data.result.resource.splice(0, 8);

    });
  }
  getAllAudio(){
    this.resourceService.getAllResources(this.audioUrl,this.pageCount).subscribe((data: any) => {
      this.checkRes = true;
      this.audioLists =data.result.resource.splice(0, 5);

    });
  }
  getAllPdf(){
    this.resourceService.getAllResources(this.pdfUrl,this.pageCount).subscribe((data: any) => {
      this.checkRes = true;
      this.pdfList =data.result.resource.splice(0, 5);;
    });
  }
  getAllBooks(){
    this.resourceService.getAllResources(this.bookUrl,this.pageCount).subscribe((data: any) => {
      this.checkRes = true;
      this.bookList =data.result.resource.splice(0, 8);
    });
  }
  ngOnInit(): void {
   this.getAllVideos();
   this.getAllAudio();
   this.getAllBooks();
   this.getAllPdf();
      this.route.fragment.subscribe((res)=>{
        this.switchTo(res);
      })
    }
    switchTo(section){
      setTimeout(()=>{
        document.getElementById(section).scrollIntoView({behavior:"smooth"});
      },1000);

    }
   
  
}
