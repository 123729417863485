<div class="row mar-zero" *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <!-- <app-headerlogo></app-headerlogo> -->
            <!-- <div class="row mar-zero">
             <div class="spacer-sm"></div>
             <div class="col s12 pad-zero center-align">
                 <img id="reg_province_image" alt="" title="">
             </div>
             <div class="col s12 pad-zero center-align">
                 <div class="static-left-innerttl">
                     <span class="static-left-innerttl-1" id="reg_province_label">Pray for<br>province of</span><br>
                     <span class="static-left-innerttl-2" id="reg_province_name"></span><br>
                     <span class="static-left-innerttl-3" id="reg_province_city"></span>
                 </div>
             </div>
             </div> -->
            <!-- <app-provincemap></app-provincemap> -->
            <!-- <app-logo-layout></app-logo-layout> -->
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="registration-panel" style="background: #653935 url({{pageData.bannerimage}}) center no-repeat;
                    background-size: cover;">
                        <app-bannerbiblequote [biblequotes]="pageData.bannerquote" [verses]="pageData.bannerversus">
                        </app-bannerbiblequote>
                        <div class="static-top-title">
                            <span>{{pagetitle}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-outer" *ngIf="checkSection">
    <!--About Riseup Nation-->
    <div class="static-section-panel" id="registrationpage">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">{{pageData.sidebartitle}}</span>
                    </div>
                    <div class="right">
                        <span class="praylead-section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
                <div class="col s12 pad-zero">
                    <div class="reg-sub-txt">
                        <ul id="step1">
                            <li [innerHTML]="description"></li>
                        </ul>
                        <!-- <ul id="step2" style="display:none">
                            <li>We do not need your legal name but name you prefer</li>
                            <li>We do not need your address</li>
                            <li>Email is essential to send you the prayer points</li>
                            <li>We need your postal code to connect other in your area (If you wish)</li>
                            <li>We like to know about your church and the lead pastor</li>
                            <li>We like to inform any prayer seminar in your area</li>
                            <li>Thank you for joining our team and the army of God</li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="static-section-rpanel">
            <div class="pray-regpanel">
                <div class="row mar-zero">
                    <div class="col s12 pad-zero">
                        <p class="static-more-action-tle">{{pagetitle}}</p>
                        <form [formGroup]="createOrganizationForm">
                            <div class="row">
                                <div class="prayer-stepcont-panel" id="">
                                    <div class="reg-pray-card">
                                        <div class="row mar-zero">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl">Organization Name<span style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero">
                                                <input type="text" class="persinfo-fld" id="rname" formControlName="organizationName" [ngClass]="{ 'is-invalid': submitted && f.organizationName.errors }">
                                                <div *ngIf="submitted && f.organizationName.errors" class="alert alert-danger">
                                                    <div class="validation-errors" *ngIf="f.organizationName.errors.required">Organization Name is required
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row mar-zero">
                                                <div class="col s12 m5 l5 pad-zero">
                                                    <span class="persinfo-lbl">Name<span style="color:red;">*</span></span>
                                                </div>
                                                <div class="col s12 m7 l7 pad-zero">
                                                    <input type="text" class="persinfo-fld"  id="rprovince" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                                    <div *ngIf="submitted && f.name.errors" class="alert alert-danger">
                                                        <div class="validation-errors" *ngIf="f.name.errors.required">Name is required
                                                        </div>
                                                        <div class="validation-errors" *ngIf="f.name.errors.pattern"> Enter a valid name
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mar-zero">
                                                <div class="col s12 m5 l5 pad-zero">
                                                    <span class="persinfo-lbl">E-mail<span style="color:red;">*</span></span>
                                                </div>
                                                <div class="col s12 m7 l7 pad-zero">
                                                    <input type="text" class="persinfo-fld" id="remail" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                    <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                                                        <div class="validation-errors" *ngIf="f.email.errors.required">Email is required
                                                        </div>

                                                        <div class="validation-errors" *ngIf="createOrganizationForm.get('email').errors.pattern">
                                                            Email is not valid
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mar-zero">
                                                <div class="col s12 m5 l5 pad-zero">
                                                    <span class="persinfo-lbl">Mobile<span style="color:red;">*</span></span>
                                                </div>
                                                <div class="col s12 m7 l7 pad-zero">
                                                    <input type="text" class="persinfo-fld" id="rprovince" formControlName="mobile" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                                                    <div *ngIf="submitted && f.mobile.errors" class="alert alert-danger">
                                                        <div class="validation-errors" *ngIf="f.mobile.errors.required">Mobile is required
                                                        </div>
                                                        <div class="validation-errors" *ngIf="f.mobile.errors.pattern">Please, Enter 10 digit Mobile Number.</div>  
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mar-zero">
                                                <p style="color:red;font-size:11px">* Mandatory Fields</p>
                                            </div>
                                            <div class="row mar-zero">
                                                <p>{{pageData.formdescription}}</p>
                                            </div>
                                            <div *ngIf="loaderSpin" id="lock-modal">
                                                <div class="loader"></div>
                                            </div>
                                            <div class="center-align mt-15">

                                                <button class="btn-reg" type="button" (click)="savechanges()">{{pageData.formbutton}}<span class="btn-reg-nextarrow"><i
                                             class="mdi mdi-arrow-right"></i></span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col s12 pad-zero">
                        <div class="table-list table-responsive">
                            <table class="table caption-top table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" class="reg_head">Id</th>
                                        <th scope="col" class="title_head">Organization Name</th>
                                        <th scope="col" class="title_head">Name</th>
                                        <th scope="col" class="title_head">Email</th>
                                        <th scope="col" class="title_head">Mobile</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr  *ngFor="let list of orgList| paginate: { itemsPerPage: 10, currentPage:currentPage,totalItems:totalItems }; let i = index">
                                        <td>{{currentData*10+i+1}}</td>
                                        <td>{{list.organizationName}}</td>
                                        <td>{{list.name}}</td>
                                        <td>{{list.email }}</td>
                                        <td>{{list.mobile}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="alignPagination">
                        <pagination-controls (pageChange)="handlePageChange($event)"></pagination-controls>
                     </div>
                </div>
            </div>
        </div>
    </div>

</div>
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>