import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.css']
})
export class ScrollToTopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 500) {
        $('.scrollToTop').fadeIn();
      } else {
        $('.scrollToTop').fadeOut();
      }
    });
    $('.scrollToTop, .loadtop').click(function () {
      $('html, body').animate({
        scrollTop: 0
      }, 800);
      return false;
    });
  }

}
