import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/services/authentication.service';
import { CommonService } from 'src/services/common.service';
import { NotificationService } from 'src/services/notification.service';
import { PageService } from 'src/services/page.service';
import { PrayerwarriorService } from 'src/services/prayerwarrior.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {

  pageData: any;
  pagetitle: any;
  bannerDetails: any;
  checkSection: boolean = false;
  description: any;
  cPassForm: FormGroup;
  submitted = false;
  constructor(private fb: FormBuilder, private commonservice: CommonService, private pageservice: PageService, private auth: AuthenticationService, private notify: NotificationService, private pwService: PrayerwarriorService,) {

    this.pageservice.getPage("60d422d25b57a70068783835").subscribe(res => {
      this.pageData = res.result.templateData;
      this.pagetitle = res.result.title;
      this.description = res.result.template[0].description;
      this.checkSection = true;
    })
  }

  ngOnInit(): void {
    this.cPassForm = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    });
  }

  get fval() {
    return this.cPassForm.controls;
  }

  signup() {
    this.submitted = true;
    const currentUser = this.auth.currentUserValue;
    if (this.cPassForm.invalid) {
      return;
    }
    if (this.cPassForm.valid) {
      var data = {
        'emailId': currentUser['email'],
        'currentPassword': this.cPassForm.get('currentPassword')?.value,
        'newPassword': this.cPassForm.get('newPassword')?.value,
        'confirmPassword': this.cPassForm.get('confirmPassword')?.value,
      }
      this.pwService.changePassword(data).subscribe(data => {
        if (data.status == 200) {
          this.submitted=false;
          this.notify.showSuccess('Password Changed Succesfully')
          this.cPassForm.reset();
        }
      },
        (err) => {
          this.notify.showError(err.error.message)
        });
    }
  }

}
