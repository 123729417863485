import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GeoService } from 'src/services/geo.service';
import { NotificationService } from 'src/services/notification.service';
import { PageService } from 'src/services/page.service';
import { PersonalprayerService } from 'src/services/personalprayer.service';
import { PrayerPointsService } from 'src/services/prayerpoints.service';
declare var $: any;

@Component({
  selector: 'app-personalprayer',
  templateUrl: './personalprayer.component.html',
  styleUrls: ['./personalprayer.component.css']
})
export class PersonalprayerComponent implements OnInit {
  // bannertitle = "Personal Prayer";
  approvedList: any = [];
  howToPrayList: any = [];
  prayerPdfUrl: any = [];
  Validators: any;
  createForm: FormGroup;
  pageData: any;
  pagetitle: any;
  template: any;
  checkSection: boolean = false;
  bannerDetails: any;
  biblequotes: any;
  prayerlist: any = [];
  Province: any;
  category: any;
  subcategory: any
  edit = false;
  data: any;
  audiourl: any;
  x: any;
  pdfurl: any;
  id: string;
  maxChars = 50;
  submitted: boolean;
  isAddMode: boolean;
  province: any = [];
  city: any = [];
  totalPrayer: any = [];
  currentPage: number = 0;
  status: string = 'approved';
  totalPages: any;
  isLoad: boolean=false;
  showSubCatagory: boolean = true;
  sendCatagory: boolean = false;
  checkRes: boolean = false;
  bannerDetailsTwo: any;
  pageDataTwo: any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  constructor(private formBuilder: FormBuilder, private pageservice: PageService, private prayerService: PersonalprayerService,
    private prayerPoint: PrayerPointsService, private geoService: GeoService, private notify: NotificationService) {
    this.pageservice.getPage('60e40e24e13d575543c74b12').subscribe(res => {
      this.pageData = res.result.templateData;
      this.pagetitle = res.result.title;
      this.bannerDetails = [{ bibleQuotes: this.pageData.bannerquote, verses: this.pageData.bannerversus, file: this.pageData.bannerimage ? this.pageData.bannerimage : '../../../assets/images/event-bg.jpg' }]
      this.checkSection = true;
    }, (err) => {
      this.notify.showError(err)
    });
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res => {

      this.pageDataTwo = res.result.templateData;
      this.bannerDetailsTwo = { slidertitle1: this.pageDataTwo.riseupcanada, slidertitle2: this.pageDataTwo.prayforournation, sliders: this.pageDataTwo.banner }
      this.sectionDetails = { title: this.pageData.section2title, description: this.pageData.section2description, audio: this.pageData.section2audio, pdf: this.pageData.section2pdf }

      this.checkSection = true;
    }, (err) => {
      this.notify.showError(err)
    });
    this.createForm = this.formBuilder.group({
      name: ['', Validators.required],
      prayer: ['', Validators.required],
      prayerCat: ['', Validators.required],
      prayerSubcat: [''],

    });
  }
  //Get All Approved Data
  getList(status, currentPage) {
    this.prayerService.getApprovedData(status, currentPage).subscribe((data: any) => {
      this.approvedList = data.result.list;
      this.totalPrayer = data.result.info.totalItems;
      this.totalPages = data.result.info.totalPages;
      this.isLoad = this.totalPages > 1 ? true : false;
    }, (err) => {
      this.notify.showError(err)
    });
  }
  // Append Data when user clicks Load More Button
  loadMore() {
    this.currentPage = this.currentPage + 1;
    if (this.totalPages > this.currentPage) {
      this.prayerService.getApprovedData(this.status, this.currentPage).subscribe((data: any) => {
        var approvedList = data['result'].list;
        approvedList.map((o) => {
          this.approvedList.push(o);
        });
        this.checkRes = true;
        this.isLoad = this.totalPages > this.currentPage + 1 ? true : false;
      });
    } else {
      this.isLoad = false;
    }
  }
  //Get Sub Category
  getValue(list) {
    this.id = list.target.value;
    if (list.target.value == "612762ce4bf46070dc3ebfa2") {
      this.showSubCatagory = false;
      this.sendCatagory = false;
    } else {
      this.showSubCatagory = true;
    }
    this.subcategory = this.x.filter((o: any) => { return this.id === o.parent ? ({ ...o }) : '' });
  }
 //Get Category from prayerpoints
  getPrayer() {
    this.prayerPoint.getPrayerList().subscribe((data: any) => {
      this.x =data['result'].list;
      var a = data['result'].list.map(o => o.id)
      this.category = this.x.filter(o => o.parent === '');
    }, (err) => {
      this.notify.showError(err)
    });
  }

  getPrayers(list) {
    if (list) {
      this.edit = true;
      this.sendCatagory = true;
    }
  }
  ngOnInit(): void {
    this.myselectbox01();
    this.getList(this.status, this.currentPage);
    this.getPrayer();
  }

  get f() { return this.createForm.controls; }

  submitPrayer() {
    this.submitted = true;
    if (this.createForm.invalid) {
      return;
    }
    if (this.createForm.valid) {
    
      var categoryName = this.category.filter((p) => {
        return p.id === this.createForm.get('prayerCat').value ? p.title : '';
      })
      var subcategoryName = this.subcategory.filter((o) => {
        return o.id === this.createForm.get('prayerSubcat').value ? o.title : '';
      })
      let data = {
        'name': this.createForm.get('name')?.value,
        'prayer': this.createForm.get('prayer')?.value,
        'prayerCat': categoryName[0].title,
        'prayerSubcat': this.sendCatagory ? subcategoryName[0].title : null
      }
      this.prayerService.createPrayer(data).subscribe(response => {
        if (response.status == 200) {
          this.createForm.reset();
          this.submitted = false;
          this.notify.showSuccess('Prayer Submitted')
        }
      }, (err) => {
        this.notify.showError(err)
      });
    }
  }

  myselectbox01() {
    /*Dropdown Menu*/

    $('.dropdown').click(function () {
      $(this).attr('tabindex', 1).focus();
      $(this).toggleClass('active');
      $(this).find('.dropdown-menu').slideToggle(500);
    });
    $('.dropdown').focusout(function () {
      $(this).removeClass('active');
      $(this).find('.dropdown-menu').slideUp(500);
    });
    $('.dropdown .dropdown-menu li').click(function () {
      $(this).parents('.dropdown').find('span').text($(this).text());
      $(this).parents('.dropdown').find('input').attr('value', $(this).attr('id'));
    });
    /*End Dropdown Menu*/
  }
}
