<div class="row mar-zero" *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <!-- <app-headerlogo></app-headerlogo> -->
            <!-- <div class="row mar-zero">
             <div class="spacer-sm"></div>
             <div class="col s12 pad-zero center-align">
                 <img id="reg_province_image" alt="" title="">
             </div>
             <div class="col s12 pad-zero center-align">
                 <div class="static-left-innerttl">
                     <span class="static-left-innerttl-1" id="reg_province_label">Pray for<br>province of</span><br>
                     <span class="static-left-innerttl-2" id="reg_province_name"></span><br>
                     <span class="static-left-innerttl-3" id="reg_province_city"></span>
                 </div>
             </div>
             </div> -->
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="registration-panel">
                        <app-bannerbiblequote [biblequotes]="pageData.bannerquote" [verses]="pageData.bannerversus">
                        </app-bannerbiblequote>
                        <div class="static-top-title">
                            <!-- <span>{{pagetitle}}</span> -->
                            <span>Change Password</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-outer" *ngIf="checkSection">
    <!--About Riseup Nation-->
    <div class="static-section-panel" id="registrationpage">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">{{pageData.sidebartitle}}</span>
                    </div>
                    <div class="right">
                        <span class="praylead-section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
                <div class="col s12 pad-zero">
                    <div class="reg-sub-txt">
                        <ul id="step1">
                            <li [innerHTML]="description"></li>
                        </ul>
                        <ul id="step2" style="display:none">
                            <li>We do not need your legal name but name you prefer</li>
                            <li>We do not need your address</li>
                            <li>Email is essential to send you the prayer points</li>
                            <li>We need your postal code to connect other in your area (If you wish)</li>
                            <li>We like to know about your church and the lead pastor</li>
                            <li>We like to inform any prayer seminar in your area</li>
                            <li>Thank you for joining our team and the army of God</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="static-section-rpanel">
            <div class="pray-regpanel">
                <div class="row mar-zero">
                    <div class="col s12 pad-zero">
                        <p class="static-more-action-tle">Strongholds Visible to the users </p>
                        <form [formGroup]="cPassForm" (ngSubmit)="signup();">
                            <div class="row">
                                <div class="prayer-stepcont-panel" id="">
                                    <div class="reg-pray-card mt-0">
                                        <div class="row mar-zero">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl">Old Password<span
                                                        style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero password-group alignIcons">
                                                <div class="psw-show">
                                                    <!-- <button class="p-0  border-0"> <img (click)="togglePassword()"
                                                            [src]="show ? '../../../assets/images/eye.svg' : '../../../assets/images/eye-off.svg'"
                                                            alt="eye"></button> -->
                                                </div>
                                                <input type="password" class="password-box"
                                                    formControlName="currentPassword" class="persinfo-fld" id="rname"
                                                    [ngClass]="{ 'is-invalid': submitted && fval.currentPassword.errors }" appPassword/>
                                                <div *ngIf="submitted && fval.currentPassword.errors"
                                                    class="alert alert-danger">
                                                    <div *ngIf="fval.currentPassword.errors.required"
                                                        style="color: red;">
                                                        Old Password is required
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mar-zero">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl">New Password<span
                                                        style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero password-group alignIcons">
                                                <div class="psw-show">
                                                    <!-- <button class="p-0  border-0"> <img (click)="togglePassword()"
                                                            [src]="show ? '../../../assets/images/eye.svg' : '../../../assets/images/eye-off.svg'"
                                                            alt="eye"></button> -->
                                                </div>
                                                <input type="password" class="password-box"
                                                    formControlName="newPassword" class="persinfo-fld" id="remail"
                                                    [ngClass]="{ 'is-invalid': submitted && fval.newPassword.errors }" appPassword />
                                                <div *ngIf="submitted && fval.newPassword.errors"
                                                    class="invalid-feedback">
                                                    <div ngClass="fval.newPassword.errors.required" style="color: red;">
                                                        Password is required.
                                                    </div>
                                                    <div *ngIf="fval.newPassword.errors.minlength" style="color: red;">
                                                        Password must be at
                                                        least 6 characters</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mar-zero">
                                            <div class="col s12 m5 l5 pad-zero">
                                                <span class="persinfo-lbl">Confirm Password<span
                                                        style="color:red;">*</span></span>
                                            </div>
                                            <div class="col s12 m7 l7 pad-zero password-group alignIcons">
                                                <div class="pwsshow-icons">
                                                    <!-- <button class="p-0  border-0"> <img (click)="togglePassword()"
                                                            [src]="show ? '../../../assets/images/eye.svg' : '../../../assets/images/eye-off.svg'"
                                                            alt="eye"></button> -->
                                                </div>
                                                <input type="password" class="password-box"
                                                    formControlName="confirmPassword" class="persinfo-fld"
                                                    id="rpostalcode"
                                                    [ngClass]="{ 'is-invalid': submitted && fval.confirmPassword.errors }" appPassword />
                                                <div *ngIf="submitted && fval.confirmPassword.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="fval.confirmPassword.errors.required"
                                                        style="color: red;">Confirm password
                                                        is required</div>
                                                    <div *ngIf="fval.newPassword.errors?.notEqual" style="color: red;">
                                                        Password Mismatch
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mar-zero">
                                            <p style="color:red;font-size:11px">* Mandatory Fields</p>
                                        </div>

                                        <div class="center-align mt-15">
                                            <!-- <button class="btn-reg" type="button" onclick="show_prev('choose_prayer','bar1');"><span class="btn-reg-prearrow"><i class="mdi mdi-arrow-left"></i></span> Back</button> -->
                                            <button class="btn-reg" type="submit">Submit<span
                                                    class="btn-reg-nextarrow"><i
                                                        class="mdi mdi-arrow-right"></i></span></button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>