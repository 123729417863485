
<div class="row mar-zero">
    <div class="col s12 m3 l3 pad-l-zero">
       <div class="breaking-news-panel">
          <span class="breaking-news-title">Breaking news</span>
          <div class="owl-arrows">
             <p class="owl-cus-prev"><i class="mdi mdi-arrow-left"></i></p>
             <p class="owl-cus-next"><i class="mdi mdi-arrow-right"></i></p>
          </div>
       </div>
    </div>
    <div class="col s12 m9 l9 pad-zero white" id="news" >
      <!-- <li class="item">{{list.title}}</li> -->
        <ul class="owl-carousel owl-theme" >
            <li class="item" *ngFor="let list of newslist">{{list.title}}</li>
            <!-- <li class="item">Canadians, like Satan, are too cozy with death, Reform pastor tells participants in March for Life</li>
            <li class="item">Terrifying for all Canadians': Travis Vader gets life sentence for killing elderly Alberta couple</li> -->
        </ul>
    </div>
 </div>