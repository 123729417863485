<div class="row mar-zero" *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <!-- <app-headerlogo></app-headerlogo> -->
            <!-- <div class="row mar-zero">
             <div class="spacer-sm"></div>
             <div class="col s12 pad-zero center-align">
                <img src="" id="wprovince_image" alt="" title="">
             </div>
             <div class="col s12 pad-zero center-align">
                <div class="static-left-innerttl">
                   <span class="static-left-innerttl-1" id="wprovince_label">Pray for<br>province of</span><br>
                   <span class="static-left-innerttl-2" id="wprovince_name"></span><br>
                   <span class="static-left-innerttl-3" id="wcity"></span>
                </div>
             </div>
          </div> -->
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <app-innerbanner [bannerDetails]="bannerDetails" [title]="pagetitle"></app-innerbanner>
        </div>
    </div>
</div>
<div class="container-outer" *ngIf="checkSection">
    <!--About Riseup Nation-->
    <div class="static-section-panel">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">{{pageData.section1title}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="static-section-rpanel">
            <div class="row mar-zero">
                <div class="floating_image">
                    <img class="responsive-img" id="aboutimage" src="{{pageData.section1image}}">
                </div>

                <div class="pad-l-zero">
                    <div class="">
                        <div class="static-bible-quote" id="biblecontent">
                            {{pageData.section1biblequote}}
                        </div>
                        <div class="static-bible-quote-num" id="biblequote">{{pageData.section1bibleversus}}</div>
                    </div>
                    <div class="txt-14 mar-t-zero clr-888" id="content1" [innerHTML]="description">
                        <p>{{description}}
                        </p>
                    </div>

                </div>
                <!-- <div class="col s12 m5 l5">
                    <img class="responsive-img" id="aboutimage" src="../../../../assets/images/023.jpg">
                </div> -->
            </div>
            <!-- <div class="row mar-zero">
                <div class="col s12 pad-zero" id="content2">
                    <div class="txt-14 clr-888">
                        <p>God has given us laws for our blessings and He expects us to follow them but he never forces anyone to follow these laws.</p>
                        <p>Adam and Eve were given the freedom of choice; they had the freedom to obey or disobey God’s instruction regarding eating the forbidden fruit. The foundation of his creation is freewill. His children are given absolute freedom
                            to choose. They can even reject God. God will never interfere in our decisions out of his absolute respect and love for us. So God restricts himself to do anything without us requesting him to intervene in our lives. Prayer
                            is therefore letting Him know that we need His help, giving him the opportunity to intervene in our lives. Therefore, the person who prays, requests at his or her own free will, for God to intervene, with the confidence that
                            he will listen and is able to help, for he is not only omniscient but omnipotent and all loving.</p>
                    </div>
                </div>
            </div> -->
            <!--a class="waves-effect btn-lg" onclick="loadMenu('registration')">Become a Pray Partner</a-->
        </div>
    </div>
    <!--About Riseup Nation-->
    <!--How we support our Nation-->
    <div class="static-section-panel">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="static-section-ltitle">{{pageData.section2title}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="static-section-rpanel-card">
            <div class="row mar-zero">
                <div class="col s12 m6 l6 pad-zero" onclick="loadMenu('prayer-resource')">
                    <div class="pray-trendcol">
                        <div class="praytrend-image">
                            <img src="{{pageData.section2image1}}" class="responsive-img" alt="">
                        </div>
                        <p class="static-more-action-tle">{{pageData.section2subtitle1}}</p>
                        <p class="txt-14 mar-t-zero clr-888 short_dec">{{pageData.section2description1}}
                        </p>
                        <app-primarybutton buttonName={{pageData.section2button1}} routerLink="/prayerresource"></app-primarybutton>
                    </div>
                </div>
                <div class="col s12 m6 l6 pad-zero" onclick="loadMenu('prayer-points')">
                    <div class="pray-trendcol">
                        <div class="praytrend-image">
                            <img src="{{pageData.section2image2}}" class="responsive-img" alt="">
                        </div>
                        <p class="static-more-action-tle">{{pageData.section2subtitle2}}</p>
                        <p class="txt-14 mar-t-zero clr-888 short_dec">{{pageData.section2description2}}
                        </p>
                        <!-- <p><a class="waves-effect btn-lg"></a></p> -->
                        <app-primarybutton buttonName={{pageData.section2button2}} routerLink="/prayerpoints"></app-primarybutton>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--How we support our Nation-->
</div>
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>
