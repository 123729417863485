import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GeoService } from 'src/services/geo.service';
declare var $: any;
@Component({
  selector: 'app-leadersdropdown',
  templateUrl: './leadersdropdown.component.html',
  styleUrls: ['./leadersdropdown.component.css']
})
export class LeadersdropdownComponent implements OnInit {
  @Output() filterDropdown = new EventEmitter;

  filterData = { province: '', city: '', name: '' }
  city: any = [];

  constructor(public geoService: GeoService) { }

  getCity(id) {
    this.city = [];
    this.filterData.city = '';

    this.geoService.getCity(id).subscribe(res => {
      if (res.status == 200) {
        this.city = res.body['result'];
      }
    });
  }
  getName(event: any) {
    this.filterData.city = event.name;


  }
  ngOnInit(): void {
    this.leaderselectbox();
    this.getCity('6043bc059bfa2c1b29ae3497')
    // $(".cselect").each(function () {

    //   var $input = $(this).find("input");
    //   var $dropDown = $(this).find("ul");

    //   $(this).on("click", function () {
    //     $dropDown.slideToggle();
    //   });

    //   $dropDown.on("click", "li", function () {
    //     $input.val($(this).text());
    //   });

    // });
  }

  onSubmit() {
    this.filterDropdown.emit(this.filterData);

  }
  leaderselectbox() {
    /*Dropdown Menu*/
    $('.dropdown').click(function () {
      $(this).attr('tabindex', 1).focus();
      $(this).toggleClass('active');
      $(this).find('.dropdown-menu').slideToggle(500);
    });
    $('.dropdown').focusout(function () {
      $(this).removeClass('active');
      $(this).find('.dropdown-menu').slideUp(500);
    });
    $('.dropdown .dropdown-menu li').click(function () {
      $(this).parents('.dropdown').find('span').text($(this).text());
      $(this).parents('.dropdown').find('input').attr('value', $(this).attr('id'));
    });

  }
  leadersname: string;
  selectleaders(l_name) {
    this.leadersname = l_name;
  }
}
