<div class="row mar-zero">
    <div class="col s12 m6 l6 pad-zero">
        <div class="pray-trendcol home-blog ht-auto">
            <p class="mar-t-zero why-pray-title">Social Prayers</p>
            <div class="praytrend-image">
                <img src="../../../../assets/images/013.jpg" class="responsive-img" alt="">
            </div>

            <p class="txt-14 mar-t-zero clr-888">Prayer at it’s simplest is a conversation between you and the God who created Solar Systems and Stem Cells. It’s a holy waste of time that helps us get to know who God is, what He’s like and express our thanks, hopes, hurts and needs. Prayer
                at it’s simplest is a conversation between you and the God who created Solar Systems and Stem Cells. It’s a holy waste of time that helps us. </p>
            <p><a class="waves-effect btn-lg" routerLink="/socialprayer">View Prayer wall</a></p>
        </div>
    </div>
    <div class="col s12 m6 l6 pad-zero">
        <div class="pray-trendcol home-blog bg-white ht-auto">
            <p class="mar-t-zero why-pray-title">personal Prayers</p>
            <div class="praytrend-image">
                <img src="../../../../assets/images/014.jpg" class="responsive-img" alt="">
            </div>

            <p class="txt-14 mar-t-zero clr-888">Prayer at it’s simplest is a conversation between you and the God who created Solar Systems and Stem Cells. It’s a holy waste of time that helps us get to know who God is, what He’s like and express our thanks, hopes, hurts and needs. Prayer
                at it’s simplest is a conversation between you and the God who created Solar Systems and Stem Cells. It’s a holy waste of time that helps us.</p>
            <p><a class="waves-effect btn-lg" routerLink="/personalprayer">View Prayer wall</a></p>
        </div>
    </div>
</div>