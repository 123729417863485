import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/services/authentication.service';
import { NotificationService } from 'src/services/notification.service';
import { PageService } from 'src/services/page.service';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  loginForm: FormGroup;
  forgotPass: FormGroup;
  pageData: any;
  template: any;
  checkSection: boolean = false;
  bannerDetails: any;
  biblequotes: any;
  loading = false;
  submitted = false;
  pagetitle: any;
  show: boolean = false;
  error: string;
  isLogin: boolean = false;
  logOutShow:boolean=false;
  constructor(private pageservice: PageService, private formBuilder: FormBuilder, private router: Router, private authenticationService: AuthenticationService, private notify: NotificationService) {
    if (this.authenticationService.currentUserValue) {
      this.isLogin = true;
    }
    this.pageservice.getPage('60da0980827df34126ea6b43').subscribe(res => {
      this.pageData = res.result.templateData;
      this.checkSection = true;
    })
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.forgotPass = this.formBuilder.group({
      forgotEmail: ['', [Validators.required, Validators.email]]
    });
  }
  getDonate(){
    var Url='https://www.paypal.com/donate/?hosted_button_id=C2LNWB4RXJD9U';
  }
  get f() { return this.loginForm.controls; }
  get fp() { return this.forgotPass.controls; }
  PasswordshowHidden() {
    $(document).on('click', '.toggle-password', () => {
      $(".toggle-password").toggleClass("show-password");
      var input = $("#pass_log_id");
      input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
  });
  }
  showLogout(){
  this.logOutShow= !this.logOutShow;
  }
  togglePassword() {
    this.show = !this.show;
  }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate(['/']);
          window.location.reload();
        },
        error => {
          this.notify.showError(error.error.message)
          this.loading = false;
        });
  }

  onSend() {
    this.submitted = true;
    if (this.forgotPass.invalid) {
      return;
    }
    // this.loading = true;
    this.authenticationService.forgotPassword(this.fp.forgotEmail.value)
      .subscribe(
        data => {
          this.notify.showSuccess('Forgot Password send your Email Address')
          window.location.reload();
        },
        err => {
          this.notify.showError(err.error.message)
        });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['']);
    window.location.reload();
  }

  ngAfterViewInit(): void {

    setTimeout(function () {
      $('.dropdown-button').dropdown({
        inDuration: 300,
        outDuration: 225,
        constrain_width: false, // Does not change width of dropdown to that of the activator
        hover: true, // Activate on hover
        gutter: 0, // Spacing from edge
        belowOrigin: false, // Displays dropdown below the button
        alignment: 'left' // Displays dropdown with edge aligned to the left of button
      });

      $(".drop-show").hide();

      $('.loginopcls').click(function () {
        $('.mobile-menu').hide();
        $('.menu-icon').toggleClass('open');
      })
      $('.mob-menu-list li a.dropdown-button').click(function () {
        $(this).parent("li").find(".drop-show").slideToggle()
      });
      $('.mob-menu-list li a.dropdown-button2').click(function () {
        $(this).parent("li").find(".drop-show2").slideToggle();
      });
      $('.mob-menu-list li a.dropdown-button3').click(function () {
        $(this).parent("li").find(".drop-show3").slideToggle();
      });

      $('.menu-icon').click(function () {
        var $menu = $('.mobile-menu');
        if ($menu.is(':visible')) {
          // Slide away
          $menu.animate({
            right: -($menu.outerWidth() + 100)
          }, function () {
            $menu.hide();
          });
        } else {
          // Slide in
          $menu.show().css("right", -($menu.outerWidth() + 1000)).animate({
            right: 0
          });
        }
      });
      $('.menu-icon').click(function () {
        $(this).toggleClass('open');
      });


    }, 2500);

    setTimeout(function () {
      $(document).ready(function () {
        $('.log_open').click(function () {
          var $menu = $('#login_panel');
          if ($menu.is(this)) {
            // Slide away
            $menu.animate({
              right: -($menu.outerWidth() + 100)
            }, function () {
              $menu.hide();
            });
          } else {
            // Slide in
            $menu.show().css("right", -($menu.outerWidth() + 1000)).animate({
              right: 100
            });
          }
        });
      });
    }, 2000)


  }
  toggleShow() {
    this.show = !this.show;
  }
  show_out() {
    this.loginForm.reset();
    this.show = false;
  }
  ngOnDestroy() {
    window.onbeforeunload = () => {
      localStorage.removeItem('showChooseProvince');
    }
  }
}
