<div class="row mar-zero" *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <app-innerbanner [bannerDetails]="bannerDetails" [title]="pagetitle"></app-innerbanner>
        </div>
    </div>
</div>
<div class="container-outer" *ngIf="checkSection">
    <!--How we support our Nation-->
    <div class="static-section-panel">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="praylead-vm-ltitle">{{pageData.section1title}}</span><br>
                        <span class="praylead-vm-ltitle-sub">{{pageData.section1subtitle}}</span>
                    </div>
                    <div class="right">
                        <span class="praylead-section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <div class="event-ldmr-panel"><a class="waves-effect btn-lg" *ngIf="listLeader" (click)="loadMore()">{{pageData.loadmore}}</a></div>
        </div>
        <app-leadersdropdown (filterDropdown)="getData($event)"></app-leadersdropdown>
        <div class="static-section-rpanel-card border-none" id="p_leaders">
            <div class="row mar-zero">
                <app-leadersprofile (newEvent)='getLeaders($event)' [leaderList]="leaderList"></app-leadersprofile>
            </div>
        </div>
        <div class="cen-btn">
            <a class="waves-effect btn-lg" routerLink="/province">BACK TO PROVINCE</a>
        </div>
    </div>
</div>
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>