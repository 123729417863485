import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(private http: HttpClient) { }
  sendEmail(data:any) {
    console.log("Check",data)
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });
    let body = data;
    return this.http.post<any>(`${environment.apiUrl}/newsletter-signup`,body,
      {
        headers: headers,
        observe: 'response',
      });
  }
}
