import { Component, Input, OnInit } from '@angular/core';
import { PrayerresourceService } from 'src/services/prayerresource.service';

@Component({
  selector: 'app-audios',
  templateUrl: './audios.component.html',
  styleUrls: ['./audios.component.css']
})
export class AudiosComponent implements OnInit {
  @Input() audioLists:any
  template:any;
  constructor(private resourceService:PrayerresourceService) { }
  audioList = [
    {
      url: "https://riseupcanada.com/wp-content/uploads/2019/05/PRAYER-FOR-HEALING-WITH-PROPHET-TB-JOSHUA.mp3",
      title: "Prayer for Healing",
      cover: "https://riseupcanada.com/wp-content/uploads/2019/05/Screen-Shot-2019-05-16-at-4.06.45-PM.png"
    }
    // {
    //   url: "https://riseupcanada.com/wp-content/uploads/2019/05/Getting-Started-with-Prayer-English-Christian-message-by-Pastor-Mike-Glenn.mp3",
    //   title: "Getting Started with Prayer",
    //   cover: "https://riseupcanada.com/wp-content/uploads/2019/05/Screen-Shot-2019-05-16-at-5.50.59-PM.png"
    // },
    // {
    //   url: "https://riseupcanada.com/wp-content/uploads/2019/05/Zac-Poonen-How-Do-You-Pray-in-the-Spirit-.mp3",
    //   title: "How Do You Pray In the Spirit",
    //   cover: "https://riseupcanada.com/wp-content/uploads/2019/05/Screen-Shot-2019-05-16-at-5.54.02-PM.png"
    // }
  ];

  ngOnInit(): void {;
   
   
  }
}
