import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class CanadaPostalCode {

    constructor(private http: HttpClient) { }

    autoComplete(searchTerm: string = '', lastId: string = '') {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        });
        return this.http.get<any>(`${environment.canadaPostAPI}&searchTerm=` + `${searchTerm}&lastId=${lastId}`, { headers: headers });
    }

    retriveAddress( id: string ) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        });
        return this.http.get<any>(`${environment.canadaPostRetriveAPI}&Id=${id}`, { headers: headers });
    }

}
