
<div class="prayres-section-rpanel-card">
    <div *ngIf="videoList.length===0" class="data-notfount">
        <h2 style="text-align: center;">No Records Found</h2>
    </div>
    <div class="row mar-zero" id="prayer-videos">
        <div class="col s12 l3 pad-l-zero"  *ngFor="let list of videoList">
            <div class="prayres-card hoverable">
                <div class="pr-card-image">
                    <a data-popup-open="popup-1" (click)="OpenVideoPopup(list.mediaUrl)">
                        <img src="{{list.cover}}" class="responsive-img pticard-imght" alt="Video Thumbnail">
                        <span class="prayres-vd-play"><img src="../../../../assets/images/video-btn.svg" /></span></a>
                </div>
                <div class="prayres-card-content">
                    <div class="prayres-card-vdtle">{{list.title}}</div>
                    <div class="prayres-card-vdtle-subtl">{{list.subtitle}}</div>
                    <div class="prayres-card-vdtle-subtldate">{{list.createdDate | date}}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- video popup Start -->
<div class="popup" data-popup="popup-1" *ngIf="showpopup">
    <div class="popup-inner">
        <iframe width="100%" height="480" [src]="urlSafe | safePipe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <a class="popup-close" data-popup-close="popup-1" (click)="closePopup()">x</a>
    </div>
</div>

<!-- Video popup End  -->