<div class="container-outer">

    <form>
        <div class="curent-province leader-drop row">
            <div class="col s12 m6 l3">
                <div class="leader-drop">
                    <span>Pray for our leader in canada</span>
                </div>
            </div>
            <div class="col s12 m6 l3">
                <div class="selectBtn">
                    <input type="text" class="my-country" placeholder="Canada" />
                </div>
            </div>
            <div class="col s12 m6 l3">
                <div class="dropdown">
                    <div class="select">
                        <span *ngIf="!leadersname">Choose Your Province</span>
                        <span *ngIf="leadersname">{{leadersname| replace : '_' : ' '}}</span>
                    </div>
                    <input type="hidden" name="gender">
                    <ul class="dropdown-menu">
                        <li (click)="getName(list)" *ngFor="let list of city" (click)="selectleaders(list.name)">
                            {{list.name| replace : '_' : ' '}}
                        </li>
                    </ul>
                </div>
                <!-- <div class="cselect">
                    <input class="clickCity" type="text" disabled placeholder="Choose your Province">
                    <ul select-options slim-scroll>
                        
                        
                        <li  (click)="getName(list)"  *ngFor="let list of city" >{{list.name}}</li>
                    </ul>
                 
                
                </div>  -->
            </div>

            <div class="col s12 m6 l3">
                <div class="data-added">
                    <a class="newsletter-btn" (click)="onSubmit()">submit</a>
                </div>
            </div>
        </div>
    </form>
</div>