import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonService } from 'src/services/common.service';
import { EnquiryService } from 'src/services/enquiry.service';
import { NotificationService } from 'src/services/notification.service';
import { PageService } from 'src/services/page.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  bannertitle = "Contact Us";
  protected contactForm: FormGroup;

  pageData: any;
  pagetitle: any;
  bannerDetails:any;
  checkSection: boolean = false;
  submitted: boolean;
  bannerDetailsTwo: any;
  pageDataTwo:any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  constructor(private fb: FormBuilder,private commonservice:CommonService,private pageservice:PageService, 
    public enquirylist:EnquiryService, private notify:NotificationService) {
     
    this.pageservice.getPage("60d1c283b9e57b5357ac1f64").subscribe(res =>{
      this.pageData=res.result.templateData;
      this.pagetitle=res.result.title;
      this.bannerDetails = [{bibleQuotes:this.pageData.bannerquote,verses:this.pageData.bannerversus,file:this.pageData.bannerimage?this.pageData.bannerimage:'../../../assets/images/contact-bg.jpg'}]
      this.checkSection=true;
    })
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res =>{
      this.pageDataTwo=res.result.templateData;
      this.bannerDetailsTwo = {slidertitle1:this.pageData.riseupcanada,slidertitle2:this.pageData.prayforournation,sliders:this.pageData.banner}
      this.sectionDetails = {title:this.pageData.section2title,description:this.pageData.section2description,audio:this.pageData.section2audio,pdf:this.pageData.section2pdf}
      this.checkSection=true;
    })
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      mobile: ['', Validators.required],
      comments:['', Validators.required],
      recaptcha: ['', Validators.required]
    });
   }
   get f() { return this.contactForm.controls; }
  contactuspage = [
    {
      title: "Contact Us",
      description: "",
      slug: "",
      image: "",
      htmlContent: "",
      template: "",
      template_field: [{
        "banner": [
          {
            "bibleQuotes": "So I sought for a man among them who would make a wall, and stand in the gap before Me on behalf of the land (Canada), that I should not destroy it; but I found no one.",
            "verses": "EZE 22:30",
            "file": "https://ruc-frontend-webapp-dev-knjyght5ka-uc.a.run.app/contact-bg.jpg",
          },
         
        ],
        // "contentofbody": [
        //   {
        //     "texthead":"I looked for someone among them Who would build up the wall and stand before me In the gap On behalf of the land So I would not have to destroy it But I found no one",
        //     "quotesnum": "Ezekiel 22:30",
        //     "description":"Looking through the historical lens of our nation, it is evident that Canada was given to early Catholic and Christian forefathers who have toiled hard to build up this Nation. Their prayers and strong biblical principles in mind have heavily influenced Canada. These honest, hardworking, men and women of God believed in the principle and teaching of Christ and were blessed.We live in a time where even the Canadian national anthem is being questioned and scrutinized for mentioning 'God'.Canada is desperately in need for great prayer. The problem is many Christians do not know how to pray. As believers, we should rise up, and make sure our anthem becomes a truth.God keep our land, glorious and free. Oh Canada, we stand on guard for thee",
        //     "thumbnail":"https://ruc-frontend-webapp-dev-knjyght5ka-uc.a.run.app/assets/images/about-us.jpg"
        //   }
        // ]
      }]
    }
  ]
  ngOnInit(): void {
    
    
  }
  siteKey: string = "6Ld0QJ0aAAAAAMulMckxDqazbXatQpS6ukKAsZgD";
  savechanges() {
    this.submitted = true;
    if (this.contactForm.valid) {
      let data = {

        'name': this.contactForm.get('name')?.value,
        'email': this.contactForm.get('email')?.value,
        'mobile': this.contactForm.get('mobile')?.value,
        'comments': this.contactForm.get('comments')?.value,
        
      }
      this.enquirylist.Addsiteenquiry(data).subscribe(data => {
        if (data.status == 200) {
          this.submitted = false;
          this.contactForm.reset();
          this.notify.showSuccess('Prayer Submitted')
        }
        
      },
      (err) => {
        console.error();
        this.notify.showError(err)
      });
    }
  }
}

