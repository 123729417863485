
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SocialPrayerService {
    sendssg = new Subject<any>();
    constructor(private http: HttpClient) { }

  
    // Get All News Lists
    getAllPrayer(provinceName,isTrending:boolean=false) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        });
        return this.http.get<any>(`${environment.apiUrl}/socialprayer?province=${provinceName}&trending=${isTrending}`, { headers: headers });
    }
    getPage(provinceName,value,isTrending:boolean=null) {
        let headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
      });
        
        return this.http.get(`${environment.apiUrl}/socialprayer?province=${provinceName}&trending=${isTrending}&page=` + `${value}`);
    
      }
      getTrendingPrayer(provinceName,currentPage: number=0, isTrending:boolean=true){
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        });
        return this.http.get<any>(`${environment.apiUrl}/socialprayer?province=${provinceName}&trending=${isTrending}&page=` + `${currentPage}`, { headers: headers });
    }
      getFilteredData(provinceName,cityName,postalCode) {
      
    
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        });
      
        return this.http.get<any>(`${environment.apiUrl}/socialprayer-filter?province=${provinceName}&city=${cityName}&pincode=${postalCode}`,{ headers: headers });
    }
    public getmsg(res:any) {
      
        this.sendssg.next(res);
      }
    

}
