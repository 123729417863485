<div class="province-wiseevent">
    <div class="container-outer">

        <form [formGroup]="createAddressForm">
            <div class="curent-province row ">
                <div class="col s12 m6 l3">
                    <div class="selectBtn">
                        <input formControlName="pincode" #pin type="text" (click)="showList()"
                            (keyup)="autoComplete($event)" autocomplete="off" placeholder="Enter postal Code" [(ngModel)]="disableBtn"
                            class="postalcode form-control frm  m-2  " id="userIdFirstWay" list="dynmicUserIds" />
                        <ul class="list-group" id="ulautocomplete" *ngIf="openCountryList">
                            <li *ngFor="let item of autoCompletArr" class="list-group-item"
                                (click)="selectAddress(item)"> <a class="card-link  handcursor">{{item.Next == "Find" ?
                                    item.Description : item.Text}}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col s12 m6 l3">
                    <div class="selectBtn">
                        <input type="text" class="postalcode" formControlName="province" 
                            placeholder="Province">
                    </div>
                </div>
                <div class="col s12 m6 l3">
                    <div class="selectBtn">
                        <input type="text" class="postalcode" formControlName="city" 
                            placeholder="City">
                    </div>
                </div>

                <div class="col s12 m6 l3">
                    <div class="data-added submitResetBtn">
                        <button  class="newsletter-btn" [disabled]="createAddressForm.invalid" (click)="onSubmit(pin)">submit</button>
                        <!-- <a class="newsletter-btn" (click)="reset()">Reset</a> -->
                    </div>



                </div>
                <!-- <div class="col s12 m6 l3">
                    <div class="data-added">
                        <a class="newsletter-btn"(click)="onReset()">Reset</a>
                    </div>
                </div> -->
            </div>


        </form>
    </div>
</div>