
<div class="christian-leaders-info">
    <div *ngIf="leaderList.length===0" class="data-notfount">
        <h2 style="text-align: center;color: white;opacity: 1;">No Records Found</h2>
    </div>
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let list of leaderList; let i = index ">
            <div class="col s12 l3 ">
                <div class="pray-leadcol z-depth-1">
                    <div class="pray-leadcol-image">
                        <img src="{{list.profile}}" class="responsive-img" alt="Christian_Leaders_Profile">
                    </div>
                    <div class="pray-leadcol-content">
                        <div class="praylead-titleswipe">
                            <p class="pray-leadcol-title mar-zero">{{list.name}}</p>
                            <p class="mar-zero center-align txt-13 clr-777">{{list.info}}</p>

                        </div>
                        <div class="pp-btn">
                            <button class="newsletter-btn"  [disabled]="list.disabled" [ngClass]="list.disabled ? 'disable':'enable'"    (click)="addAmen(list,i)">
                                <div class="row">
                                    <span>Amen</span>
                                    <!-- <a  [disabled]="id===i"  *ngIf="!isLoad">Amen</a> -->
                                    <div *ngIf="isLoad && id===i " class="loaderSpin">
            
                                        <div id="loading">
            
                                        </div>
                                    </div>
                                </div>
            
                            </button>
                            <p class="total-count">{{list.amenCount}} Amens</p>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </ngx-slick-carousel>
    <!-- <ul class="owl-carousel owl-theme">
        <li class="items" *ngFor="let list of leaderList">
            <div class="">
                <div class="pray-leadcol z-depth-1">
                    <div class="pray-leadcol-image">
                        <img src="{{list.profile}}" class="responsive-img" alt="Christian_Leaders_Profile">
                    </div>
                    <div class="pray-leadcol-content">
                        <div class="praylead-titleswipe">
                            <p class="pray-leadcol-title mar-zero">{{list.name}}</p>
                            <p class="mar-zero center-align txt-13 clr-777">{{list.info}}</p>

                        </div>
                        <div class="pp-btn">
                            <a class="newsletter-btn">Amen</a>
                            <p class="total-count">20 AMENS</p>
                        </div>

                    </div>

                </div>
            </div>
        </li>
    </ul> -->
    <!-- <div class="owl-arrows leaders-slider">
        <p class="owl-cus-prev"><i class="mdi mdi-arrow-left"></i></p>
        <p class="owl-cus-next"><i class="mdi mdi-arrow-right"></i></p>
    </div> -->
</div>