<div *ngIf="approvedList.length===0" class="data-notfount">
    <h2 style="text-align: center;">No Records Found</h2>
</div>
<div class="personal-home" *ngFor="let list of approvedList; let i = index">
    <div class="items-personal">
        <div class="p-prayer">
            <div aria-label="breadcrumbs">
                <ul class="breadcrumbs">
                  <li class="breadcrumb-item">{{list.approvedDate | date}} &nbsp;&nbsp;|</li>
                  <li class="breadcrumb-item">{{list.prayerCat}} &nbsp;&nbsp;|</li>
                  <li class="breadcrumb-item">{{list.name}}</li>
                </ul>
            </div>
            <div class="prayer-point">
                <p>{{list.prayer}}</p>
            </div>
            <div class="pp-btn" >
                <div style="display: inline-block;" *ngIf="list.prayerCat !=='Others'">
                    <a class="waves-effect btn-lg how-pray" target="blank" [attr.href]="list.prayerPoints[0]['pdf_url']">How to Pray?</a>
                </div>
           
                <button class="newsletter-btn"  [disabled]="list.disabled" [ngClass]="list.disabled ? 'disable':'enable'"  (click)="addAmen(list,i)"> <div class="row">
                    <span>Amen</span>
                    <!-- <a  [disabled]="id===i"  *ngIf="!isLoad">Amen</a> -->
                    <div *ngIf="isLoad && id===i " class="loaderSpin">

                        <div id="loading">

                        </div>
                    </div>
                </div></button><a class="total-amens">{{list.amenCount}} Amens</a>
            </div>
        </div>
    </div>
    </div> 