
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ChristianLeaderService {
   

    constructor(private http: HttpClient) { }


    getAllChristianLeader(provinceName:string=null) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        });
        return this.http.get<any>(`${environment.apiUrl}/christianleader?province=${provinceName}&size=100`, { headers: headers });
    }

  
}
