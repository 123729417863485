import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.css']
})
export class BooksComponent implements OnInit {
  @Input() bookList:any
  data: any;
  prayerbooks = [
    {
      'imgpath': "https://media.geeksforgeeks.org/wp-content/uploads/20200602220313/Screenshot-19111.png",
      'bookname': "PRAYERS THAT ROUT DEMONS",
      'bksubtitle': "Prayer",
      'bklan': "English"

    },
    {
      'imgpath': "https://media.geeksforgeeks.org/wp-content/uploads/20200602220313/Screenshot-19111.png",
      'bookname': "THE VALLEY OF VISION",
      'bksubtitle': "Prayer",
      'bklan': "English"

    },
    {
      'imgpath': "https://media.geeksforgeeks.org/wp-content/uploads/20200602220313/Screenshot-19111.png",
      'bookname': "PRAYERS THAT ROUT DEMONS",
      'bksubtitle': "Prayer",
      'bklan': "English"

    },
    {
      'imgpath': "https://media.geeksforgeeks.org/wp-content/uploads/20200602220313/Screenshot-19111.png",
      'bookname': "PRAYERS THAT ROUT DEMONS",
      'bksubtitle': "Prayer",
      'bklan': "English"

    },
    {
      'imgpath': "https://media.geeksforgeeks.org/wp-content/uploads/20200602220313/Screenshot-19111.png",
      'bookname': "THE VALLEY OF VISION",
      'bksubtitle': "Prayer",
      'bklan': "English"

    },
    {
      'imgpath': "https://media.geeksforgeeks.org/wp-content/uploads/20200602220313/Screenshot-19111.png",
      'bookname': "PRAYERS THAT ROUT DEMONS",
      'bksubtitle': "Prayer",
      'bklan': "English"

    }
  ]
  constructor() { }

  ngOnInit(): void {
 
  }

}
