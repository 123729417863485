import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/services/authentication.service';
import { EventsListService } from 'src/services/events.service';
import { LeaderService } from 'src/services/leader.service';
import { NewsService } from 'src/services/news.service';
import { NotificationService } from 'src/services/notification.service';
import { PageService } from 'src/services/page.service';
import { PersonalprayerService } from 'src/services/personalprayer.service';
import { PrayerPointsService } from 'src/services/prayerpoints.service';
import { SensitivePrayerService } from 'src/services/sensitiveprayer.service';
import { SocialPrayerService } from 'src/services/socialprayer.service';
import { StorageService } from 'src/services/storage.service';

declare var $: any;
@Component({
  selector: 'app-province',
  templateUrl: './province.component.html',
  styleUrls: ['./province.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProvinceComponent implements OnInit {
  protected provinceForm: FormGroup;
  listPrayer: boolean = true;
  listSocial: boolean = true;
  pageData: any;
  socialPrayerCount: any = [];
  pagetitle: any;
  bannerDetails: any;
  checkSection: boolean = false;
  newslist: any = [];
  newslistCopy: any = [];
  news = [];
  checkRes: boolean = false;
  prayerList: any = [];
  division_name: any;
  prayer = [];
  count = 0;
  totalPages: any;
  eventList: any = [];
  eventListCopy: any = [];
  events = [];
  socialprayerList: any = [];
  sensitivePrayerList: any = [];
  leaderList: any = [];
  approvedList: any = [];
  category: any;
  data: any;
  categoryList: any;
  province: string='';
  id: string;
  subcategory: any
  showSubCatagory: boolean = true;
  sendCatagory: boolean = false;
  submitted: boolean = false;
  prayerPdfUrl: any = [];
  maxChars = 50;
  sensitivePrayerCount: any;
  personalPrayerCount: any;
  bannerDetailsTwo: any;
  pdfData:any;
  pageDataTwo: any;
  isLogin: boolean = false;
  currentPage: number = 0;
  status:string='approved';
  provincePdfUrl:any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  currentPageName: string = 'Province';

  constructor(private formBuilder: FormBuilder, private pageservice: PageService,
    private prayerService: SocialPrayerService, public newsService: NewsService, public storageService: StorageService, private prayerservice: SensitivePrayerService, public leaderService: LeaderService,
    private personalPrayer: PersonalprayerService, private authenticationService: AuthenticationService, private prayerPointService: PrayerPointsService, private notify: NotificationService, public eventsList: EventsListService) {
    if (this.authenticationService.currentUserValue) {
      this.isLogin = true;
    }
    this.pageservice.getPage("61924175a8dcec12b4893af5").subscribe(res => {
      this.pdfData = res.result.templateData;
      this.checkSection = true;
    }, (err) => {
      this.notify.showError(err)
    });
    this.pageservice.getPage("60d1d041b9e57b5357ac1f67").subscribe(res => {
      this.pageData = res.result.templateData;
      this.pagetitle = res.result.title;
      this.bannerDetails = [{ bibleQuotes: this.pageData.bannerquote, verses: this.pageData.bannerversus, file: "azar" }]
      this.checkSection = true;
    }, (err) => {
      this.notify.showError(err)
    });

    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res => {
      this.pageDataTwo = res?.result?.templateData;
      this.sectionDetails = { title: res?.result?.templateData?.section2title, description: res?.result?.templateData?.section2description, audio: this.pageDataTwo?.section2audio, pdf: this.pageDataTwo?.section2pdf }
      this.bannerDetailsTwo = { slidertitle1: this.pageDataTwo?.riseupcanada, slidertitle2: this.pageDataTwo?.prayforournation, sliders: this.pageData?.banner }
      this.checkSection = true;
    }, (err) => {
      this.notify.showError(err)
    });

    this.provinceForm = this.formBuilder.group({
      name: ['', Validators.required],
      prayer: ['', Validators.required],
      prayerCat: ['', Validators.required],
      prayerSubcat: ['', Validators.required],
    });

    this.currentPageName;

  }

  ngOnInit(): void {
  
    
    this.jsScript();
    this.division_name = JSON.parse(localStorage.getItem('selectedRegionName'));
    this.province = localStorage.getItem('selectedRegionName') == null ? null : localStorage.getItem('selectedRegionName').includes("Qu") ? 'Quebec' : localStorage.getItem('selectedRegionName').replace(/"/g, "").replace(/ /g, "_");
    this.getSocialPrayer(this.province);
    this.getNews(this.province);
    this.getSensitivePrayer(this.province);
    this.getLeader(this.province);
    this.getList(this.status,this.currentPage);
    this.getEvents(this.province);
    this.getPrayerForm();
    this.popupOpen();
  
  }
  getKeyByValue(object, value) {
    return Object.values(object).find(key => object[value] === key);
  }
  getPrayerForm() {
    this.prayerPointService.getPrayerList().subscribe((res) => {
      this.categoryList = res.result.list;
      this.category = res.result.list.filter(o => o.parent === '');
    }, (err) => {
      this.notify.showError(err)
    });
  }

  getSocialPrayer(province) {
    this.prayerService.getAllPrayer(province).subscribe((data: any) => {
      this.socialprayerList =  data['result'].list.splice(0, 8);
      this.checkRes = true;
      this.socialPrayerCount =  data.result.info.totalItems;
    }, (err) => {
      this.notify.showError(err)
    });
  }

  getValue(list) {
    this.id = list.target.value;
    if (list.target.value == "612762ce4bf46070dc3ebfa2") {
      this.provinceForm.controls["prayerSubcat"].clearValidators();
      this.showSubCatagory = false;
    } else {
      this.showSubCatagory = true;
      this.provinceForm.controls["prayerSubcat"].setValidators([
        Validators.required
      ]);
    }
    this.provinceForm.get("prayerSubcat").updateValueAndValidity();

    this.subcategory = this.categoryList.filter((o: any) => { return this.id === o.parent ? ({ ...o }) : '' });
  }
  loadAP(){
    this.provincePdfUrl= Object.keys(this.pdfData).includes(this.province)?this.getKeyByValue(this.pdfData,this.province): 'https://riseupcanada.com/wp-content/uploads/2019/05/Church-Cults.pdf';
      window.open(this.provincePdfUrl);
  }  
  
  getControlValidation(key: string): boolean {
    const { invalid, touched, dirty } = this.provinceForm.get(key);
    return invalid && (touched || dirty);
  }
 
 
  getSensitivePrayer(province) {
    this.prayerservice.getAllPrayer(province).subscribe((data: any) => {
      this.sensitivePrayerList = data['result'].list.splice(0, 8);
      this.sensitivePrayerList.map(o => { this.prayerList.push(o) })
      this.sensitivePrayerCount =  data.result.info.totalItems;
    }, (err) => {
      this.notify.showError(err)
    });
  }
  popupOpen(){
    $('.modal-trigger').leanModal({
      dismissible:false, // Modal can be dismissed by clicking outside of the modal
       opacity: .5, // Opacity of modal background
       in_duration: 300, // Transition in duration
       out_duration: 300, // Transition out duration
       startingTop: '0', // Starting top style attribute
       endingTop: '10%', // Ending top style attribute
       top: 0,
       complete: function () {
         $('.lean-overlay').remove();
       }
     });
  }
  getLeader(province) {
    this.leaderService.getAllLeader(province).subscribe((data: any) => {
      this.leaderList =  data['result'].list.splice(0, 4);
      this.totalPages =  data.result.info.totalPages;
    }, (err) => {
      this.notify.showError(err)
    });
  }

  getNews(province) {
    this.newsService.getNews(province).subscribe((data: any) => {
      this.newslist = data['result'].list;
      this.newslistCopy = this.newslist.splice(0, 4);
    }, (err) => {
      this.notify.showError(err)
    });
  }

  //approve list
  getList(status,currentPage) {
    this.personalPrayer.getApprovedData(status,currentPage).subscribe((data: any) => {
      this.approvedList = data.result.list;
      this.personalPrayerCount = data.result.info.totalItems;
    }, (err) => {
      this.notify.showError(err)
    });
  }

  getEvents(province) {
    this.checkRes = false;
    this.eventsList.getEvents(province).subscribe((data: any) => {
      this.eventList = data['result'].list;
      this.eventListCopy = this.eventList.splice(0, 4);
    }, (err) => {
      this.notify.showError(err)
    });
  }

  jsScript() {

    $('select').each(function () {
      var $this = $(this), numberOfOptions = $(this).children('option').length;
      $this.addClass('select-hidden');
      $this.wrap('<div class="select"></div>');
      $this.after('<div class="select-styled"></div>');
      var $styledSelect = $this.next('div.select-styled');
      $styledSelect.text($this.children('option').eq(0).text());

      var $list = $('<ul />', {
        'class': 'select-options'
      }).insertAfter($styledSelect);

      for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
          text: $this.children('option').eq(i).text(),
          rel: $this.children('option').eq(i).val()
        }).appendTo($list);
      }

      var $listItems = $list.children('li');

      $styledSelect.click(function (e) {
        e.stopPropagation();
        $('div.select-styled.active').not(this).each(function () {
          $(this).removeClass('active').next('ul.select-options').hide();
        });
        $(this).toggleClass('active').next('ul.select-options').toggle();
      });

      $listItems.click(function (e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass('active');
        $this.val($(this).attr('rel'));
        $list.hide();
      });

      $(document).click(function () {
        $styledSelect.removeClass('active');
        $list.hide();
      });

    });
  }

  get f() { return this.provinceForm.controls; }

  submitPrayer() {
    this.submitted = true;
    if (this.provinceForm.invalid) {
      return;
    }
    if (this.provinceForm.valid) {
      var categoryName = this.category.filter((p) => {
        return p.id === this.provinceForm.get('prayerCat').value ? p.title : '';
      })
      var subCategoryName = this.subcategory.filter((o) => {
        return o.id === this.provinceForm.get('prayerSubcat').value ? o.title : '';
      })
      let data = {
        'name': this.provinceForm.get('name')?.value,
        'prayer': this.provinceForm.get('prayer')?.value,
        'prayerCat': categoryName[0].title,
        'prayerSubcat': subCategoryName[0].title == "Others" ? null : subCategoryName[0].title
      }
      this.personalPrayer.createPrayer(data).subscribe(response => {
        if (response.status == 200) {
          this.notify.showSuccess('Prayer Submitted')
          this.submitted = false;
          this.provinceForm.reset();
        }
      }, (err) => {
        this.notify.showError(err)
      });
    }
  }


}
