<div class="row mar-zero" *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <app-innerbanner [bannerDetails]="bannerDetails" [title]="pagetitle"></app-innerbanner>
        </div>
    </div>
</div>
<div class="container-outer" *ngIf="checkSection">
    <!--social prayers-->
    <div class="prayers-section-panel">
        <div class="static-section-lpanel">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <span class="prayers-section-ltitle">{{pageData.section1title}}</span><br>
                        <span class="prayers-section-ltitle-sub">{{pageData.section1subtitle}}</span>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <div class="event-ldmr-panel">
                <div class="prayers-lbox-count-list hide-on-med-and-down">
                    <p class="pray-count-num" id="socprayers_count">{{isCount ? filteredSocialCount: socialPrayerCount}}</p>
                    <p class="mar-zero pray-count-type">
                        <span class="pray-count-nam">{{pageData.prayercountname}}</span><br>
                        <span class="pray-count-pttxt">{{pageData.prayercounttext}}</span>
                    </p>
                </div>
                <div class="">
                    <a class="waves-effect btn-lg" *ngIf="listPrayer" (click)="loadMore()">{{pageData.loadmore}}</a>
                </div>

            </div>
        </div>
        <div class="select-social">
            <app-selectdropdown  (filterDropdown)="getData($event)"></app-selectdropdown>
        </div>
        <div class="prayers-section-rpanel">
            <div class="row mar-zero">
                <app-socialprayerlist [socialprayerList]="socialprayerList"></app-socialprayerlist>
            </div>
            <div class="cen-btn">
                <a class="waves-effect btn-lg" routerLink="/province">BACK TO PROVINCE</a>
            </div>
        </div>
    </div>
</div>
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>