<div *ngIf="leaderList.length===0" class="data-notfount">
    <h2 style="text-align: center;">No Records Found</h2>
</div>
<div class="col s12 l3"  *ngFor="let list of leaderList; let i = index ">
    <div class="pray-leadcol z-depth-1">
        <div class="pray-leadcol-image">
            <img src="{{list.profile}}" class="responsive-img" alt="">
        </div>
        <div class="pray-leadcol-content">
            <div class="praylead-titleswipe">
                <p class="pray-leadcol-title mar-zero">{{list.name}}</p>
                <p class="mar-zero center-align txt-13 clr-777">{{list.info}}</p>
                
            </div>
            <div class="pp-btn"  >
                <button class="newsletter-btn"  [disabled]="list.disabled" [ngClass]="list.disabled ? 'disable':'enable'"  (click)="addAmen(list,i)">
                    <div class="row">
                        <span>Amen</span>
                        <!-- <a  [disabled]="id===i"  *ngIf="!isLoad">Amen</a> -->
                        <div *ngIf="isLoad && id===i " class="loaderSpin">

                            <div id="loading">

                            </div>
                        </div>
                    </div>

                </button>

                <p class="total-count">{{list.amenCount}} Amens</p>
            </div>
          
        </div>
    </div>
</div>
<!-- <div class="col s12 l3">
    <div class="pray-leadcol z-depth-1">
        <div class="pray-leadcol-image">
            <img src="https://riseupcanada.com/wp-content/uploads/elementor/thumbs/REV.-DAVID-WELLS-oxlxqmbtmoqvymdap4mp8zs40izkbu1oy84am14rb8.jpg" class="responsive-img" alt="">
        </div>
        <div class="pray-leadcol-content">
            <div class="praylead-titleswipe">
                <p class="pray-leadcol-title mar-zero">HIS EXCELLENCY MS. RACHEL NOTLEY</p>
                <p class="mar-zero center-align txt-13 clr-777">Premier of Quebec</p>
            </div>
            <div class="pp-btn">
                <a class="newsletter-btn">Amen</a>
                <p class="total-count">20 AMENS</p>
            </div>
        </div>
    </div>
</div>
<div class="col s12 l3">
    <div class="pray-leadcol z-depth-1">
        <div class="pray-leadcol-image">
            <img src="https://riseupcanada.com/wp-content/uploads/elementor/thumbs/REV.-MARK-JOHNSON-e1551815475231-oxlxqn9ntis6a8bxjn1bthjklwuxjj5facrs3b3d50.jpg" class="responsive-img" alt="">
        </div>
        <div class="pray-leadcol-content">
            <div class="praylead-titleswipe">
                <p class="pray-leadcol-title mar-zero">HIS EXCELLENCY MS. RACHEL NOTLEY</p>
                <p class="mar-zero center-align txt-13 clr-777">Premier of Quebec</p>
            </div>
            <div class="pp-btn">
                <a class="newsletter-btn">Amen</a>
                <p class="total-count">20 AMENS</p>
            </div>
        </div>
    </div>
</div>
<div class="col s12 l3">
    <div class="pray-leadcol z-depth-1">
        <div class="pray-leadcol-image">
            <img src="https://riseupcanada.com/wp-content/uploads/elementor/thumbs/REV.-MARK-JOHNSON-e1551815475231-oxlxqn9ntis6a8bxjn1bthjklwuxjj5facrs3b3d50.jpg" class="responsive-img" alt="">
        </div>
        <div class="pray-leadcol-content">
            <div class="praylead-titleswipe">
                <p class="pray-leadcol-title mar-zero">HIS EXCELLENCY MS. RACHEL NOTLEY</p>
                <p class="mar-zero center-align txt-13 clr-777">Premier of Quebec</p>
            </div>
            <div class="pp-btn">
                <a class="newsletter-btn">Amen</a>
                <p class="total-count">20 AMENS</p>
            </div>
        </div>
    </div>
</div> -->