import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/services/authentication.service';
import { NotificationService } from 'src/services/notification.service';
import { PageService } from 'src/services/page.service';
@Component({
  selector: 'app-prayerpartners',
  templateUrl: './prayerpartners.component.html',
  styleUrls: ['./prayerpartners.component.css']
})
export class PrayerpartnersComponent implements OnInit {
  bannertitle = "Prayer Partners"
  pageData:any;
  template:any;
  pagetitle:any;
  checkSection:boolean=false;
  bannerDetails: any;
  biblequotes: any;
  description:any;
  bannerDetailsTwo: any;
  pageDataTwo:any;
  isLogin: boolean = false;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  constructor(private pageservice:PageService,private authenticationService: AuthenticationService,private notify: NotificationService) { 
    if (this.authenticationService.currentUserValue) {
      this.isLogin = true;
    }
    this.pageservice.getPage('60d35508f59d567b23ad8627').subscribe(res =>{
      this.pageData=res.result.templateData;
      this.pagetitle=res.result.title;
      this.description=res.result.description;
      this.bannerDetails = [{bibleQuotes:this.pageData.bannerquote,verses:this.pageData.bannerversus,file:this.pageData.bannerimage?this.pageData.bannerimage:'../../../assets/images/prayer-partner-bg.jpg'}]
      this.biblequotes = [{texthead:this.pageData.section1biblequote,quotesnum:this.pageData.section1bibleversus,description:this.pageData.section1description,thumbnail:''}]
      this.checkSection=true;
    },(err) => {
      this.notify.showError(err)
    });
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res =>{
      this.pageDataTwo=res.result.templateData;
      this.bannerDetailsTwo = {slidertitle1:this.pageDataTwo.riseupcanada,slidertitle2:this.pageDataTwo.prayforournation,sliders:this.pageDataTwo.banner}
      this.sectionDetails = {title:this.pageData.section2title,description:this.pageData.section2description,audio:this.pageData.section2audio,pdf:this.pageData.section2pdf}
      this.checkSection=true;
    },(err) => {
      this.notify.showError(err)
    });

  }
  praypartners = [
    {
      title: "Prayer Partners",
      description: "",
      slug: "",
      image: "",
      htmlContent: "",
      template: "",
      template_field: [{
        "banner": [
          {
            "bibleQuotes": "So I sought for a man among them who would make a wall, and stand in the gap before Me on behalf of the land (Canada), that I should not destroy it; but I found no one.",
            "verses": "EZE 22:30",
            "file": "https://ruc-frontend-webapp-dev-knjyght5ka-uc.a.run.app/prayer-partner-bg.jpg",
          },
         
        ],
        "contentofbody": [
          {
            "texthead":"Therefore I exhort first of all that supplications, prayers, intercessions, and giving of thanks be made for all men, 2 for kings and all who are in authority, that we may lead a quiet and peaceable life in all godliness and reverence. 3 For this is good and acceptable in the sight of God our Savior.",
            "quotesnum": "1 Tim 2:1-3",
            "description":"The Bible says the Lord Jesus Christ intercedes on behalf of us. This means, Christians who pray for others become the prayer partners of ourLord Jesus Christ. Jesus used the parable of a widow to explain the importance of remaining persistent in prayer. Prayer should not be ceased or limited because God hears every prayer and He rewards persistence! [Jesus] said, watch therefore, and pray always that you may be counted worthy[a]to escape all these things that will come to pass, and to stand before the Son of Man.” (Luke 21:36)",
            "thumbnail":"https://ruc-frontend-webapp-dev-knjyght5ka-uc.a.run.app/assets/images/prayer-guide-bg.jpg",
          }
        ]
      }]
    }
  ]
  ngOnInit(): void {
  }

}
