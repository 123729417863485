import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/services/page.service';

@Component({
  selector: 'app-whypray',
  templateUrl: './whypray.component.html',
  styleUrls: ['./whypray.component.css']
})
export class WhyprayComponent implements OnInit {
  // bannertitle = "Why pray?"
  pageData:any;
  template:any;
  pagetitle:any;
  checkSection:boolean=false;
  bannerDetails: any;
  description: any;
  // biblequotes: any;
  bannerDetailsTwo: any;
  pageDataTwo:any;
  sectionDetails: { title: any; description: any; audio: any; pdf: any; };
  constructor(private pageservice:PageService) { 
    this.pageservice.getPage('60d33991d6a8760869a598ae').subscribe(res =>{
  
      this.pageData=res.result.templateData;
      this.pagetitle=res.result.title;
      this.description=res.result.description;
      this.bannerDetails = [{bibleQuotes:this.pageData.bannerquote,verses:this.pageData.bannerversus,file:this.pageData.bannerimage?this.pageData.bannerimage:'../../../assets/images/whypray-bg.jpg'}]
      // this.biblequotes = [{texthead:this.pageData.bannerquote2,quotesnum:this.pageData.bannerversus2,verses:this.pageData.bannerversus2,description:this.pageData.section1description,thumbnail:''}]
      this.checkSection=true;
    })
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res =>{
      this.pageDataTwo=res.result.templateData;
      this.bannerDetailsTwo = {slidertitle1:this.pageData.riseupcanada,slidertitle2:this.pageData.prayforournation,sliders:this.pageData.banner}
      this.sectionDetails = {title:this.pageData.section2title,description:this.pageData.section2description,audio:this.pageData.section2audio,pdf:this.pageData.section2pdf}
      this.checkSection=true;
    })
  }
  whypraypage = [
    {
      title: "Why pray?",
      lefttitle: "More in this section",
      description: "",
      slug: "",
      image: "",
      htmlContent: "",
      template: "",
      template_field: [{
        "banner": [
          {
            "bibleQuotes": "So I sought for a man among them who would make a wall, and stand in the gap before Me on behalf of the land (Canada), that I should not destroy it; but I found no one.",
            "verses": "EZE 22:30",
            "file": "https://ruc-frontend-webapp-dev-knjyght5ka-uc.a.run.app/whypray-bg.jpg",
          },
         
        ],
       
        "contentofbody": [
          {
            "texthead":"“I could ask the darkness to hide me and the light around me to become night— but even in darkness I cannot hide from you. To you the night shines as bright as day.“",
            "quotesnum": "Psalm 139:11-12",
            "description":"The claim that God is Omniscient means that he is an all-knowing. God is all-knowing in the sense that he is aware of our past, present, and future. Nothing takes him by surprise. His knowledge is total. So, if he knows everything about us why, should we pray? God created man in His won image; in the image of God He created him; male and female (Gen 1:27). We are in fact created like Him. We are His offspring. We have been allowed to live freed from any forceful interference of Him. One may ask the question, where was God when Adam and Eve sinned? Why did he not come and stop them?",
            "thumbnail":"https://ruc-frontend-webapp-dev-knjyght5ka-uc.a.run.app/assets/images/023.jpg"
          }
        ]
      }]
    }
  ]
  ngOnInit(): void {
  }

}
