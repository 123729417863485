import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-homebanner',
  templateUrl: './homebanner.component.html',
  styleUrls: ['./homebanner.component.css']
})
export class HomebannerComponent implements OnInit {
  @Input() homesliders: any = [];
  @Input() title: any = '';
  @Input() btntext: any = '';


  constructor() { }

  ngOnInit(): void {

  }
  ngAfterViewInit() {
    $('.bxslider').bxSlider({
      auto: true,
      mode: 'fade',
      autoHover: true,
      infiniteLoop: true,
      speed: 800,
      pause: 3000
    });
  }

}
