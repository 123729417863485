import { Component, Input, OnInit,AfterViewInit, ViewEncapsulation } from '@angular/core';
import { PageService } from 'src/services/page.service';
declare var $: any;

@Component({
  selector: 'app-prayfornation',
  templateUrl: './prayfornation.component.html',
  styleUrls: ['./prayfornation.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PrayfornationComponent implements OnInit {
// @Input() banner:any;

@Input() currentPageName:string;
pageData:any;
template:any;
pagetitle:any;
checkSection:boolean=false;
bannerDetails: any;
biblequotes: any;
sectionDetails: { title: any; description: any; audio: any; pdf: any; };
description: any;
  constructor(private pageservice:PageService) {
    this.pageservice.getPage("60db66cbc5c4955342c96283").subscribe(res =>{
      this.pageData=res.result.templateData;
      this.bannerDetails = {slidertitle1:this.pageData.riseupcanada,slidertitle2:this.pageData.prayforournation,sliders:this.pageData.banner}
      this.sectionDetails = {title:this.pageData.section2title,description:this.pageData.section2description,audio:this.pageData.section2audio,pdf:this.pageData.section2pdf}
      this.checkSection=true;
    })
  }

  ngOnInit(): void {
    
  }
  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    autoplay: true,
    dots: true,
    fade: true,
    autoplaySpeed: 2500,
    // infinite:false,

  };
 }
