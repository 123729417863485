<div class="row mar-zero" *ngIf="checkSection">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <!-- <div class="logo">
             <app-headerlogo></app-headerlogo>
          </div>-->
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <app-innerbanner [bannerDetails]="bannerDetails" [title]="pagetitle"></app-innerbanner>
        </div>
    </div>
</div>
<div class="container-outer" *ngIf="checkSection">
    <!--video pray-->
    <div class="prayerresvideo-section-panel" id="redirectvideo">
        <div class="static-section-lpanel static-lpanel-cust">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <div class="prayres-howpray-txt">
                            <p class="prayres-howpray-smtxt">{{pageData.howtopray}}</p>
                            <p class="prayres-howpray-txt-title">{{pageData.section1title}}</p>
                        </div>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <div class="event-ldmr-panel">
                <!-- <a class="waves-effect btn-lg">View more videos</a> -->
                <app-primarybutton buttonName={{pageData.section1button}} routerLink="/videos"></app-primarybutton>
            </div>
        </div>
        <app-videos *ngIf="checkRes" [videoList]="videoList"></app-videos>
    </div>
    <!--video pray-->
    <div class="prayerresaudio-section-panel" id="redirectaudio">
        <div class="static-section-lpanel static-lpanel-cust">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <div class="prayres-howpray-txt">
                            <p class="prayres-howpray-smtxt">{{pageData.howtopray}}</p>
                            <p class="prayres-howpray-txt-title">{{pageData.section2title}}</p>
                        </div>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <div class="event-ldmr-panel">
                <!-- <a class="waves-effect btn-lg"></a> -->
                <app-primarybutton buttonName={{pageData.section2button}} routerLink="/audios"></app-primarybutton>
            </div>
        </div>
        <app-audios  *ngIf="checkRes" [audioLists]="audioLists"></app-audios>
    </div>
    <!--book pray-->
    <div class="prayerresvideo-section-panel" id="redirectbook">
        <div class="static-section-lpanel static-lpanel-cust">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <div class="prayres-howpray-txt">
                            <p class="prayres-howpray-smtxt">{{pageData.howtopray}}</p>
                            <p class="prayres-howpray-txt-title">{{pageData.section3title}}</p>
                        </div>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <div class="event-ldmr-panel">
                <app-primarybutton buttonName={{pageData.section3button}} routerLink="/books"></app-primarybutton>
            </div>
        </div>
        <app-books *ngIf="checkRes" [bookList]="bookList"></app-books>
    </div>
    <!--book pray-->
    <!--pdf pray-->
    <div class="prayerres-pdf-section-panel" id="redirectpdf">
        <div class="static-section-lpanel static-lpanel-cust">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <div class="prayres-howpray-txt">
                            <p class="prayres-howpray-smtxt">{{pageData.howtopray}}</p>
                            <p class="prayres-howpray-txt-title">{{pageData.section4title}}</p>
                        </div>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <div class="event-ldmr-panel">
                <!-- <a class="waves-effect btn-lg">view more pdf&rsquo;s</a> -->
                <app-primarybutton buttonName={{pageData.section4button}} routerLink="/pdfs"></app-primarybutton>
            </div>
        </div>
        <app-pdfs *ngIf="checkRes" [pdfList]="pdfList"></app-pdfs>
    </div>
    <!--pdf pray-->
</div>
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>
