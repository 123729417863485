import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EnquiryService {

    constructor(private http: HttpClient) { }
    

    Addsiteenquiry(data:any) {
        let headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        });
        let body = data;
        return this.http.post<any>(`${environment.apiUrl}/enquiry`,body,
          {
            headers: headers,
            observe: 'response',
          });
      }
}
