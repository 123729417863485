<div class="row mar-zero">
    <div class="col s12 pad-zero">
        <div class="static-topleft">
            <app-headerlogo></app-headerlogo>
            <app-provincemap></app-provincemap>
        </div>
        <div class="static-topright">
            <app-innerbanner [bannerDetails]="bannerDetails" [title]="pagetitle"></app-innerbanner>
        </div>
    </div>
</div>
<div class="container-outer">
    <div class="prayerresvideo-section-panel">
        <div class="static-section-lpanel static-lpanel-cust">
            <div class="row mar-zero">
                <div class="col s12 pad-zero">
                    <div class="left">
                        <div class="prayres-howpray-txt">
                            <p class="prayres-howpray-smtxt">{{pageData.booksubtitle}}</p>
                            <p class="prayres-howpray-txt-title">{{pageData.booksectiontitle}}</p>
                        </div>
                    </div>
                    <div class="right">
                        <span class="section-arw"><i class="mdi mdi-arrow-right"></i></span>
                    </div>
                </div>
            </div>
            <div class="mt-15"><a class="waves-effect btn-lg" *ngIf="listBooks" (click)="loadMore()">Load More Books</a></div>
        </div>
        <app-books *ngIf="checkRes" [bookList]="bookList"></app-books>
        <div class="cen-btn">
            <a class="waves-effect btn-lg" routerLink="/prayerresource">BACK TO RESOURCES</a>
        </div>
    </div>
</div>
<app-prayfornation *ngIf="checkSection" [banner]="bannerDetailsTwo"></app-prayfornation>